import Navigation from "../Navigation";
import {
    GovBreadcrumbs,
    GovButton,
    GovDropdown,
    GovFormCheckbox,
    GovFormControl,
    GovFormGroup,
    GovFormInput,
    GovFormLabel,
    GovFormSelect,
    GovIcon,
    GovMessage,
    GovModal,
    GovPrompt,
    GovSkeleton,
    GovSpacer, GovTile, GovTiles, GovToast
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {MenuList} from "./MenuList";
import '../css/manage-users.css';
import {
    approvalStateToText,
    convertIsoToDateFormat,
    convertIsoToLocalDate,
    convertIsoToTime, fuelTypeToText, mapDateFormat,
    mapTimeSheetType,
    parseIsoToYearMonth,
    parseJwt
} from "../utils/utils";
import Select, {components} from "react-select";
import {json} from "react-router-dom";

export function MonthReportManagementContent() {

    const jwt = localStorage.getItem("jwt")
    let [timesheets, setTimesheets] = useState(null)


    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");




    const allRegionalCenters = [
        "Moravskoslezský kraj",
        "Středočeský kraj a Praha",
        "Ústecký a Karlovarský kraj",
        "Královehradecký a Liberecký kraj",
        "Pardubický kraj a Kraj Vysočina",
        "Jihočeský a Plzeňský kraj",
        "Zlínský a Jihomoravský kraj",
        "Olomoucký kraj"
    ];


    const rolesOfExternalPersons = [
        "Statutární zástupce",
        "Zmocněnec",
        "Nahlížející osoba",
        "Kontaktní osoba",
    ]

    const rolesOfEmployees = [
        "Specialista RC",
        "Manažer RC",
        "Centrála"
    ]

    const allInstitutions = [
        "CTU FEE",
        "SFPI"
    ]
    const [request, setRequest] = useState(() => {
        const userRole = parseJwt(localStorage.getItem("jwt")).role[0].authority;
        return userRole === "RC_LEADER" ? `?rcId=${localStorage.getItem("RCid")}` : '';
    });
    const [userType, setUserType] = useState("Regionální centra");


    function getRegionalCenterByRegionName(regionName) {
        if (regionName === "Hlavní město Praha") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Moravskoslezský kraj") {
            return "Moravskoslezský kraj"
        } else if (regionName === "Olomoucký kraj") {
            return "Olomoucký kraj"
        } else if (regionName === "Pardubický kraj") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Kraj Vysočina") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Zlínský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Královéhradecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Ústecký kraj") {
            return "Ústecký a Karlovarský kraj"
        } else if (regionName === "Plzeňský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Liberecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Jihomoravský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Jihočeský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Středočeský kraj") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Karlovarský kraj") {
            return "Ústecký a Karlovarský kraj"
        }
    }


    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [currentTimesheetId, setCurrentTimesheetId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(null);
    const [modalTimesheetOpen, setModalTimesheetOpen] = useState(false);
    const [editModalExternalPerson, setEditModalExternalPerson] = useState(false);
    const [createTimesheet, setCreateTimesheet] = useState(false);

    const [projects, setProjects] = useState([])
    const [villages, setVillages] = useState([])


    function openModalTimesheet() {
        setModalTimesheetOpen(true)
    }


    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;

    const filterItems = () => {
        if (timesheets) {
            if (!dateFromFilter && !dateToFilter) {
                setFilteredItems([])
                return
            }
            let filtered = timesheets;

            // Filter by date range
            if (dateFromFilter || dateToFilter) {
                const fromDate = new Date(dateFromFilter);
                fromDate.setHours(0, 0, 0, 0);
                let toDate = new Date(dateToFilter);
                toDate.setHours(23, 59, 59, 999);

                if (dateFromFilter && dateToFilter) {
                    filtered = timesheets.filter(item => {
                        const startDate = new Date(item.start);
                        return startDate >= fromDate && startDate <= toDate;
                    });
                } else if (dateFromFilter) {
                    filtered = timesheets.filter(item => {
                        const startDate = new Date(item.start);
                        return startDate >= fromDate;
                    });
                }
            }

            // Filter by type
            filtered = filtered.filter(item => filtersByTimesheetType[item.type.toString()]);

            setFilteredItems(filtered);
        }
    };

    const openEditTimesheetModal = (event) => {
        setCurrentTimesheetId(event.target.value)
        let timesheet;
        fetch(process.env.REACT_APP_API_URL + `/workReports/${event.target.value}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            console.log(jsonData)
            timesheet = jsonData;

            setTimesheetDate(convertIsoToLocalDate(timesheet.start))
            setTimesheetStartTime(convertIsoToTime(timesheet.start))
            setTimesheetEndTime(convertIsoToTime(timesheet.end))
            setTimesheetHoursWorked(calculateHoursWorkedForEdit(timesheet.start, timesheet.end))
            setTimesheetType(timesheet.type)
            console.log(timesheet.type)
            setTimesheetDescription(timesheet.description)

            timesheet.project ? setSelectedProjectId(timesheet.project.id) : setSelectedProjectId('')
            timesheet.town ? setSelectedVillageId(timesheet.town.id) : setSelectedVillageId('')
            timesheet.town ? console.log(timesheet.town.id) : console.log("null")


            setModalTimesheetOpen(true)
            setSelectedProjectIdInvalid(false)
            setSelectedVillageIdInvalid(false)
            setTimesheetDateInvalid(false)
            setTimesheetStartTimeInvalid(false)
            setTimesheetEndTimeInvalid(false)
            setTimesheetDescriptionInvalid(false)
            setTimesheetTimesRangeInvalid(false)
            setTimesheetValid(true)

        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const handlePageChange = (event) => {
        setCurrentPage(event.detail.pagination.currentPage);
    };
    const closeEditModal = (event) => {
        setTimesheetDate("")
        setTimesheetStartTime("")
        setTimesheetEndTime("")
        setTimesheetType("1")
        setTimesheetDescription("")
        setTimesheetHoursWorked(null)

        setSelectedProjectId("")
        setSelectedVillageId("")
        setCreateTimesheet(false)
        setModalTimesheetOpen(false);
        setSelectedProjectIdInvalid(true)
        setSelectedVillageIdInvalid(true)
        setTimesheetStartTimeInvalid(true)
        setTimesheetEndTimeInvalid(true)
        setTimesheetDescriptionInvalid(true)
        setTimesheetTimesRangeInvalid(true)
    }



    const [monthReports, setMonthReports] = useState([])

    function fetchMonthReports(currentRequest) {
        fetch(process.env.REACT_APP_API_URL + `/monthReports/workReports${currentRequest ? currentRequest : "" }`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            console.log(jsonData)
            setMonthReports(jsonData)
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const [monthRoadCompensations, setMonthRoadCompensations] = useState([])
    function fetchMonthRoadCompensations(currentRequest) {
        fetch(process.env.REACT_APP_API_URL + `/monthReports/tripsReports${currentRequest ? currentRequest : ""}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            console.log(jsonData)
            setMonthRoadCompensations(jsonData)
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }





    const [timesheetType, setTimesheetType] = useState("1");
    const [selectedProjectId, setSelectedProjectId] = useState('')
    const [selectedTypes, setSelectedTypes] = useState([]);


    const [selectedVillageId, setSelectedVillageId] = useState('')
    const [timesheetDate, setTimesheetDate] = useState('')
    const [dateFromFilter, setDateFromFilter] = useState(new Date())
    const [dateToFilter, setDateToFilter] = useState(new Date())
    const [timesheetStartTime, setTimesheetStartTime] = useState('')
    const [timesheetEndTime, setTimesheetEndTime] = useState('')
    const [timesheetHoursWorked, setTimesheetHoursWorked] = useState(null)
    const [timesheetDescription, setTimesheetDescription] = useState('')

    const [timesheetTypeInvalid, setTimesheetTypeInvalid] = useState(false)
    const [selectedProjectIdInvalid, setSelectedProjectIdInvalid] = useState(true)
    const [selectedVillageIdInvalid, setSelectedVillageIdInvalid] = useState(true)
    const [timesheetDateInvalid, setTimesheetDateInvalid] = useState(true)
    const [timesheetStartTimeInvalid, setTimesheetStartTimeInvalid] = useState(true)
    const [timesheetEndTimeInvalid, setTimesheetEndTimeInvalid] = useState(true)
    const [timesheetDescriptionInvalid, setTimesheetDescriptionInvalid] = useState(true)
    const [timesheetTimesRangeInvalid, setTimesheetTimesRangeInvalid] = useState(true)

    const [timesheetValid, setTimesheetValid] = useState(false)


    const handleTimesheetTypeChange = (event) => {
        if (event.target.value === "") {
            setTimesheetTypeInvalid(true)
        } else {
            setTimesheetTypeInvalid(false)
        }
        setTimesheetType(event.target.value);
    }

    const handleVillageIdChange = (event) => {
        if (event.target === "") {
            setSelectedVillageIdInvalid(true)
        } else {
            setSelectedVillageIdInvalid(false)
        }
        setSelectedVillageId(event.value);
    };

    const handleDateChange = (event) => {
        if (event.target.value === "") {
            setTimesheetDateInvalid(true)
        } else {
            setTimesheetDateInvalid(false)
        }
        setTimesheetDate(event.target.value);
        setTimesheetHoursWorked(calculateHoursWorked(timesheetStartTime, timesheetEndTime));
    };

    const handleDateFromFilterChange = (event) => {
        const date = new Date(event.target.value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        let newRequest
        setDateFromFilter(event.target.value);
        // if (request.includes('toDate')) {
        //     setRequest(`?fromDate=${formattedDate}&toDate=${formattedDate}`);
        //     newRequest = `?fromDate=${formattedDate}&toDate=${formattedDate}`
        // } else {
        //     setRequest(`?fromDate=${formattedDate}`);
        //     newRequest = `?fromDate=${formattedDate}`
        // }
        const urlParams = new URLSearchParams(request);
        if(formattedDate !== "NaN-NaN-NaN") {
            urlParams.set("fromDate", formattedDate);
            urlParams.set("toDate", formattedDate);
        }
        else {
            urlParams.delete("fromDate");
            urlParams.delete("toDate");
        }
        setRequest(`?${urlParams.toString()}`);
        newRequest = `?${urlParams.toString()}`;
        fetchMonthReports(newRequest)
        fetchMonthRoadCompensations(newRequest)
    }
    //commented date range because of no usage
    // const handleDateToFilterChange = (event) => {
    //     const date = new Date(event.target.value);
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const formattedDate = `${year}-${month}-${day}`;
    //     let newRequest
    //     setDateToFilter(event.target.value);
    //     if (request.includes('fromDate')) {
    //         setRequest(`?fromDate=${dateFromFilter+"-01"}&toDate=${formattedDate}`);
    //         newRequest = `?fromDate=${dateFromFilter+"-01"}&toDate=${formattedDate}`
    //     } else {
    //         setRequest(`?toDate=${formattedDate}`);
    //         newRequest = `?toDate=${formattedDate}`
    //     }
    //     fetchMonthReports(newRequest);
    //     fetchMonthRoadCompensations(newRequest);
    // };

    const handleStartTimeChange = (event) => {
        if (event.target.value === "") {
            setTimesheetStartTimeInvalid(true)
        } else {
            setTimesheetStartTimeInvalid(false)
        }
        setTimesheetStartTime(event.target.value);
        setTimesheetHoursWorked(calculateHoursWorked(event.target.value, timesheetEndTime));
    }

    const handleEndTimeChange = (event) => {
        if (event.target.value === "") {
            setTimesheetEndTimeInvalid(true)
        } else {
            setTimesheetEndTimeInvalid(false)
        }
        setTimesheetEndTime(event.target.value);
        setTimesheetHoursWorked(calculateHoursWorked(timesheetStartTime, event.target.value));
    }

    const handleDescriptionChange = (event) => {
        if (event.target.value === "") {
            setTimesheetDescriptionInvalid(true)
        } else {
            setTimesheetDescriptionInvalid(false)
        }
        setTimesheetDescription(event.target.value);
    }

    const handleProjectIdChange = (event) => {
        if (event.value === "") {
            setSelectedProjectIdInvalid(true)
        } else {
            setSelectedProjectIdInvalid(false)
        }
        setSelectedProjectId(event.value);
    };

    function calculateHoursWorkedForEdit(startTime, endTime) {
        const start = new Date(`${startTime}`);
        const end = new Date(`${endTime}`);
        const result = (end - start) / (1000 * 60 * 60)
        if (isNaN(result) || result <= 0) {
            setTimesheetTimesRangeInvalid(true)
            return 0
        } else {
            setTimesheetTimesRangeInvalid(false)
            return result
        }
    }

    function calculateHoursWorked(startTime, endTime) {
        const start = new Date(`${timesheetDate}T${startTime}:00`);
        const end = new Date(`${timesheetDate}T${endTime}:00`);
        const result = (end - start) / (1000 * 60 * 60)

        if (isNaN(result) || result <= 0) {
            setTimesheetTimesRangeInvalid(true)
            return 0
        } else {
            setTimesheetTimesRangeInvalid(false)
            return result
        }
    }

    const calculateHoursWorkedFromIso = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const differenceInMilliseconds = endDate - startDate;
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
        return differenceInHours.toFixed(2); // Returns the difference in hours, rounded to 2 decimal places
    };


    const [dateRange, setDateRange] = useState('');
    const [labels, setLabels] = useState({
        today: '',
        yesterday: '',
        thisWeek: '',
        lastWeek: '',
        thisMonth: '',
        lastMonth: '',
        custom: 'Volný výběr'
    });
    const generateLabels = () => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const thisWeekStart = new Date(today);
        thisWeekStart.setDate(today.getDate() - today.getDay() + 1);

        const lastWeekStart = new Date(thisWeekStart);
        lastWeekStart.setDate(thisWeekStart.getDate() - 7);

        const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        const formatDate = (date) => date.toLocaleDateString('cs-CZ', {day: 'numeric', month: 'long', year: 'numeric'});

        const newLabels = {
            today: `Dnes (${formatDate(today)})`,
            yesterday: `Včera (${formatDate(yesterday)})`,
            thisWeek: `Tento týden (${formatDate(thisWeekStart)} - ${formatDate(today)})`,
            lastWeek: `Minulý týden (${formatDate(lastWeekStart)} - ${formatDate(thisWeekStart)})`,
            thisMonth: `Tento měsíc (${formatDate(thisMonthStart)} - ${formatDate(today)})`,
            lastMonth: `Minulý měsíc (${formatDate(lastMonthStart)} - ${formatDate(lastMonthEnd)})`,
            custom: 'Volný výběr'
        };

        setLabels(newLabels);
        setDateRange(newLabels.today);
    };
    useEffect(() => {
        fetchMonthReports(request)
        fetchMonthRoadCompensations(request)
    }, []);
    const DropdownIndicator = (
        props
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <GovIcon type="basic" name="chevron-down"></GovIcon>
            </components.DropdownIndicator>
        );
    };
    const customStyles = {
        dropdownIndicator: (styles, state) => ({
            ...styles,
            width: "28px",
            marginRight: "5px",
            "&:hover": {
                color: "#686868"
            },
            color: state.isFocused ? "#686868" : "#686868",
            fill: "red"
        }),
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            cursor: 'pointer',
            borderRadius: '3px',
            "&:hover": {
                borderColor: "#2362a2"
            },
            borderColor: '#686868',
            minHeight: '32px',
            height: '32px',
            boxShadow: state.isFocused ? null : null,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            color: '#686868',
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            fontSize: '14px',
            padding: '0 8px',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            color: '#686868',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#686868',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '32px',
        }),
    };


    const [sortDateOrder, setSortDateOrder] = useState('asc');

    const sortTimesheetsByDate = () => {
        if (timesheets) {
            let sortedTimesheets = [...filteredItems];
            sortedTimesheets.sort((a, b) => {
                const dateA = new Date(a.start);
                const dateB = new Date(b.start);
                return sortDateOrder === 'asc' ? dateA - dateB : dateB - dateA;
            });
            setFilteredItems(sortedTimesheets);
        }
    };


    function checkErrorsTimesheet() {
        let ready = !timesheetTypeInvalid && !timesheetDateInvalid
            && !timesheetStartTimeInvalid && !timesheetEndTimeInvalid && !timesheetDescriptionInvalid
            && !timesheetTimesRangeInvalid
        if (timesheetType === "2") {
            ready = ready && !selectedVillageIdInvalid
        } else if (timesheetType === "3") {
            ready = ready && !selectedProjectIdInvalid
        }
        setTimesheetValid(ready)
    }

    const [filtersByTimesheetType, setFiltersByTimesheetType] = useState({
        "1": true,
        "2": true,
        "3": true,
        "4": true,
        "5": true,
        "6": true,
    });

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    function openDeleteModal() {
        setDeleteModalOpen(true)
    }

    function closeDeleteModal() {
        setDeleteModalOpen(false)
    }


    const handleCheckboxChange = (event) => {
        const {value, checked} = event.target;
        setFiltersByTimesheetType(prevFilters => ({
            ...prevFilters,
            [value]: checked
        }));
    };

    const calculateTotalWorkHours = () => {
        if (currentItems) {
            return currentItems.reduce((total, item) => {
                const hoursWorked = calculateHoursWorkedFromIso(item.start, item.end);
                return total + parseFloat(hoursWorked);
            }, 0).toFixed(2);
        }
        return 0;
    };

    const calculateUtilization = () => {
        if (!filteredItems) return 0;

        const type2And3Items = filteredItems.filter(item => item.type === 2 || item.type === 3);

        const totalHoursType2And3 = type2And3Items.reduce((total, item) => {
            const hoursWorked = calculateHoursWorkedFromIso(item.start, item.end);
            return total + parseFloat(hoursWorked);
        }, 0);

        const totalHoursAllActivities = filteredItems.reduce((total, item) => {
            const hoursWorked = calculateHoursWorkedFromIso(item.start, item.end);
            return total + parseFloat(hoursWorked);
        }, 0);

        const utilization = totalHoursAllActivities ? (totalHoursType2And3 / totalHoursAllActivities) * 100 : 0;

        return utilization.toFixed(2);
    };

    useEffect(() => {
        checkErrorsTimesheet()
    }, [timesheetType, timesheetDate, timesheetStartTime, timesheetEndTime, timesheetDescription, selectedProjectId, selectedVillageId])


    useEffect(() => {
        sortTimesheetsByDate();
        // eslint-disable-next-line no-use-before-define
    }, [sortDateOrder]);


    // useEffect(() => {
    //     filterItemsByDateRange()
    // }, [timesheets,dateFromFilter, dateToFilter]);
    //
    // useEffect(() => {
    //     filterItemsByType();
    // }, [filtersByTimesheetType, timesheets]);

    const filterItemsByType = () => {
        if (timesheets) {
            const filtered = timesheets.filter(item => filtersByTimesheetType[item.type.toString()]);

            setFilteredItems(filtered);
        }
    };

    useEffect(() => {
        filterItems();
    }, [timesheets, dateFromFilter, dateToFilter, filtersByTimesheetType]);

    const [chosenCar, setChosenCar] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    let [cars, setCarsArray] = useState([])

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setChosenCar("");
        setIsModalOpen(false);
    };

    function setCurrentCar(carId) {
        fetchPrivateCar(carId)
        openModal()
    }


    function fetchPrivateCar(carId) {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/private/${carId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    setChosenCar(jsonData)
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const [typeOfReport, setTypeOfReport] = useState("1");
    const [selectedRegionalCenter, setSelectedRegionalCenter] = useState(() => {
        return (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") ? "0" : RCid;
    });

    const handleSelectChange = (event) => {
        setTypeOfReport(event.target.value);
        if (event.target.value === "2") {
            if (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") {
                const filteredReports = monthReports.filter(report =>
                    report.state === "APPROVED_BY_SUPERVISOR"
                );
                const filteredMonthTripReports = monthRoadCompensations.filter(report =>
                    report.state === "APPROVED_BY_SUPERVISOR"
                )
                setMonthReports(filteredReports);
                setMonthRoadCompensations(filteredMonthTripReports);
            } else if (authority === "RC_LEADER") {
                const filteredReports = monthReports.filter(report =>
                    report.state === "CREATED" || report.state === "REJECTED_BY_HQ"
                );
                const filteredMonthTripReports = monthRoadCompensations.filter(report =>
                    report.state === "CREATED" || report.state === "REJECTED_BY_HQ"
                )
                setMonthReports(filteredReports);
                setMonthRoadCompensations(filteredMonthTripReports);
            }
        } else {
            fetchMonthReports(request);
            fetchMonthRoadCompensations(request)
        }
    };

    const handleSelectRegionalCenterChange = (event) => {
        setSelectedRegionalCenter(event.target.value);
        const urlParams = new URLSearchParams(request);

        if (event.target.value !== "0") {
            urlParams.set("rcId", event.target.value);
        } else {
            urlParams.delete("rcId");
        }

        setRequest(`?${urlParams.toString()}`);
        fetchMonthReports(`?${urlParams.toString()}`);
        fetchMonthRoadCompensations(`?${urlParams.toString()}`);
    };


    const currentMonth = parseIsoToYearMonth(new Date().toISOString());

    const [sendSuccessful, setSendSuccessful] = useState(false);

    const informHeadquarters = (event) => {
        fetch(process.env.REACT_APP_API_URL + `/month-report-readiness/${localStorage.getItem("userId")}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "date": currentMonth
            })
        })
            .then(data => {
                setSendSuccessful(true)
                fetchHeadquartersInformation()
            })
            .catch(error => console.error('Error:', error));
    }
    const [headquartersMonthsInformed, setHeadquartersMonthsInformed] = useState([]);
    const fetchHeadquartersInformation = (event) => {
        fetch(process.env.REACT_APP_API_URL + `/month-report-readiness/${localStorage.getItem("userId")}?date=${currentMonth}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
                    setHeadquartersMonthsInformed(jsonData)
                }
            )
            .catch(error => console.error('Error:', error));
    }

    useEffect(() => {
        fetchHeadquartersInformation()
    }, []);

    return (
        <div className="">
            <Navigation active={"report-management"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>

                        {<li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Správa výkaznictví</strong>
                        </li>
                        }
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container vh-80">
                    <div className="gov-container__content">
                        <div className="gov-title--beta">Správa
                            výkaznictví: {(authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") ? '' : localStorage.getItem("RCname")}</div>
                        <div className="gov-flexbox gov-flexbox--justify-start">
                            {(authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") &&
                                <GovFormControl>
                                    <GovFormSelect size="m" variant="secondary"
                                                   value={selectedRegionalCenter}
                                                   onGov-change={handleSelectRegionalCenterChange}>
                                        <option disabled="disabled" value="">Regionální centrum</option>
                                        <option value="0">Všechna regionální centra</option>
                                        <option value="1">Moravskoslezský kraj</option>
                                        <option value="2">Středočeský kraj a Praha</option>
                                        <option value="3">Ústecký a Karlovarský kraj</option>
                                        <option value="4">Královehradecký a Liberecký kraj</option>
                                        <option value="5">Pardubický kraj a kraj Vysočina</option>
                                        <option value="6">Jihočeský kraj a Plzeňský kraj</option>
                                        <option value="7">Zlínský a Jihomoravský kraj</option>
                                        <option value="8">Olomoucký kraj</option>
                                    </GovFormSelect>
                                    <GovFormLabel slot="top" size="m">Regionální centrum záznamy</GovFormLabel>
                                </GovFormControl>
                            }

                            <GovFormControl className="ml-5">
                                <GovFormSelect size="m" variant="secondary"
                                               value={typeOfReport}
                                               onGov-change={handleSelectChange}>
                                    <option value="1">Všechny záznamy</option>
                                    <option value="2">Záznamy k vyřízení</option>
                                </GovFormSelect>
                                <GovFormLabel slot="top" size="m">Zobrazené záznamy</GovFormLabel>
                            </GovFormControl>
                            <GovFormControl className="ml-5">
                                <GovFormLabel slot="top" size="s">Měsíc</GovFormLabel>
                                <GovFormInput
                                    size="m" variant="secondary"
                                    input-type="month"
                                    min="2024-01"
                                    max={new Date().toISOString().slice(0, 7)}
                                    value={dateFromFilter}
                                    onGov-input={handleDateFromFilterChange}>
                                </GovFormInput>
                            </GovFormControl>
                            {/*<GovFormControl className="ml-5">*/}
                            {/*    <GovFormLabel slot="top" size="s">Období do</GovFormLabel>*/}
                            {/*    <GovFormInput*/}
                            {/*        size="m" variant="secondary"*/}
                            {/*        input-type="month"*/}
                            {/*        value={dateToFilter}*/}
                            {/*        max={new Date().toISOString().slice(0, 7)}*/}
                            {/*        onGov-input={handleDateToFilterChange}>*/}
                            {/*    </GovFormInput>*/}
                            {/*</GovFormControl>*/}
                        </div>
                        <div>
                            <div>
                                {authority === 'RC_LEADER' &&
                                <>
                                {(headquartersMonthsInformed.length===0) ?
                                <div>
                                    <GovButton className="mb-20" variant="primary" size="m" type="solid"
                                               wcag-label="Open dialog box for more information"
                                               onGov-click={informHeadquarters}>
                                        Informovat centrálu o uzavření výkaznictví za {mapDateFormat(currentMonth)}
                                    </GovButton>
                                </div>
                                    :
                                    <div className="mb-10" style={{color: 'green'}}>Centrála byla informována
                                        o uzavření výkaznictví za {mapDateFormat(currentMonth)}
                                    </div>
                                }
                                </>
                                }
                                <h3>Timesheety</h3>
                                <table className="gov-table">
                                    <thead>
                                    <tr>
                                        <th>Měsíc</th>
                                        <th>Zaměstnanec</th>
                                        <th>Stav</th>
                                        <th>Datum poslední změny</th>
                                        <th>Poznámka</th>
                                        <th>Detail</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {monthReports.map((report, index) => (
                                        <tr key={index}>
                                            <td>{mapDateFormat(report.date)}</td>
                                            <td>
                                                <a href={`/user-management/month-reports/` + report.user.id}>{report.user.firstname + " " + report.user.lastname}</a>
                                            </td>
                                            <td style={{color: report.state.includes('APPROVED') ? 'green' : report.state.includes('REJECTED') && 'red'}}>
                                                {approvalStateToText(report.state)}
                                            </td>
                                            <td>
                                                {report.state.includes('APPROVED_BY_HQ') ? convertIsoToDateFormat(report.hqChangeOn ? report.hqChangeOn : report.supervisorChangeOn) :
                                                    report.state.includes('APPROVED_BY_SUPERVISOR') ? convertIsoToDateFormat(report.supervisorChangeOn) :
                                                        report.state.includes('REJECTED_BY_HQ') ? convertIsoToDateFormat(report.hqChangeOn) :
                                                            report.state.includes('REJECTED_BY_SUPERVISOR') ? convertIsoToDateFormat(report.supervisorChangeOn) :
                                                                ""}
                                            </td>
                                            <td>{report.state !== "CREATED" && report.note}</td>
                                            <td><a href={`/timesheet/month-report/${report.id}`}>Zobrazit podrobnosti</a></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <h3>Cestovní náhrady</h3>
                                <table className="gov-table">
                                    <thead>
                                    <tr>
                                        <th>Měsíc</th>
                                        <th>Zaměstnanec</th>
                                        <th>Stav</th>
                                        <th>Datum poslední změny</th>
                                        <th>Poznámka</th>
                                        <th>Detail</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {monthRoadCompensations.map((roadCompensationReport, index) => (
                                        <tr key={index}>
                                            <td>{mapDateFormat(roadCompensationReport.date)}</td>
                                            <td>
                                                <a href={`/user-management/month-reports/` + roadCompensationReport.user.id}>{roadCompensationReport.user.firstname + " " + roadCompensationReport.user.lastname}</a>
                                            </td>
                                            <td style={{color: roadCompensationReport.state.includes('APPROVED') ? 'green' : roadCompensationReport.state.includes('REJECTED') && 'red'}}>
                                            {approvalStateToText(roadCompensationReport.state)}
                                            </td>
                                            <td>
                                                {roadCompensationReport.state.includes('APPROVED_BY_HQ') ? convertIsoToDateFormat(roadCompensationReport.hqChangeOn ? roadCompensationReport.hqChangeOn : roadCompensationReport.supervisorChangeOn) :
                                                    roadCompensationReport.state.includes('APPROVED_BY_SUPERVISOR') ? convertIsoToDateFormat(roadCompensationReport.supervisorChangeOn) :
                                                        roadCompensationReport.state.includes('REJECTED_BY_HQ') ? convertIsoToDateFormat(roadCompensationReport.hqChangeOn) :
                                                            roadCompensationReport.state.includes('REJECTED_BY_SUPERVISOR') ? convertIsoToDateFormat(roadCompensationReport.supervisorChangeOn) :
                                                                ""}
                                            </td>
                                            <td>{roadCompensationReport.state !== "CREATED" && roadCompensationReport.note}</td>
                                            <td><a
                                                href={`/road-compensations/month-report/${roadCompensationReport.id}`}>Zobrazit podrobnosti</a></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            {sendSuccessful &&
                                <GovToast variant="success" type="classic" gravity="bottom" position="center" time="0"
                                          wcag-close-label="Close message">Centrála byla úspěšně informována
                                    <GovIcon type="basic" name="check-lg" slot="icon"></GovIcon>
                                </GovToast>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

