import Navigation from "../Navigation";
import {
    GovBreadcrumbs,
    GovButton,
    GovDropdown,
    GovFormCheckbox,
    GovFormControl,
    GovFormGroup,
    GovFormInput,
    GovFormLabel,
    GovFormSelect,
    GovIcon,
    GovMessage,
    GovModal,
    GovPrompt,
    GovSkeleton,
    GovSpacer, GovTabs, GovTabsItem
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {MenuList} from "./MenuList";
import '../css/manage-users.css';
import {
    approvalStateToText,
    convertIsoToDateFormat,
    convertIsoToLocalDate,
    convertIsoToTime, mapDateFormat,
    mapTimeSheetType,
    parseIsoToYearMonth,
    parseJwt
} from "../utils/utils";
import Select, {components} from "react-select";
import {TimesheetModal} from "./TimesheetModal";

export function TimesheetContent({userId}) {

    const jwt = localStorage.getItem("jwt")
    const user = JSON.parse(localStorage.getItem("user"))
    let [timesheets, setTimesheets] = useState(null)

    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");


    const allRegionalCenters = [
        "Moravskoslezský kraj",
        "Středočeský kraj a Praha",
        "Ústecký a Karlovarský kraj",
        "Královehradecký a Liberecký kraj",
        "Pardubický kraj a Kraj Vysočina",
        "Jihočeský a Plzeňský kraj",
        "Zlínský a Jihomoravský kraj",
        "Olomoucký kraj"
    ];


    const rolesOfExternalPersons = [
        "Statutární zástupce",
        "Zmocněnec",
        "Nahlížející osoba",
        "Kontaktní osoba",
    ]

    const rolesOfEmployees = [
        "Specialista RC",
        "Manažer RC",
        "Centrála"
    ]

    const allInstitutions = [
        "CTU FEE",
        "SFPI"
    ]

    const handleUpdating = () => {
        fetchData()
    }

    const [whatToDisplay, setWhatToDisplay] = useState("Správa aktivit");
    let request;
    const fetchData = () => {
        let request = userId ? `?userId=${userId}` : `?userId=${localStorage.getItem("userId")}`;
        fetch(process.env.REACT_APP_API_URL + `/workReports${request}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const sortedData = jsonData.sort((a, b) => new Date(a.start) - new Date(b.start));
                setFilteredItems(sortedData);
                console.log(sortedData)
                setTimesheets(sortedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    };
    const [workFund, setWorkFund] = useState(null)

    const fetchMonthWorkFund = (date) => {
        let request = parseIsoToYearMonth(dateFromFilter)
        fetch(process.env.REACT_APP_API_URL + `/workFund/${request}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setWorkFund(jsonData.workFund)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    };

    function getRegionalCenterByRegionName(regionName) {
        if (regionName === "Hlavní město Praha") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Moravskoslezský kraj") {
            return "Moravskoslezský kraj"
        } else if (regionName === "Olomoucký kraj") {
            return "Olomoucký kraj"
        } else if (regionName === "Pardubický kraj") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Kraj Vysočina") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Zlínský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Královéhradecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Ústecký kraj") {
            return "Ústecký a Karlovarský kraj"
        } else if (regionName === "Plzeňský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Liberecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Jihomoravský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Jihočeský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Středočeský kraj") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Karlovarský kraj") {
            return "Ústecký a Karlovarský kraj"
        }
    }


    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 100;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [currentTimesheetId, setCurrentTimesheetId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(null);
    const [modalTimesheetOpen, setModalTimesheetOpen] = useState(false);

    const [editModalExternalPerson, setEditModalExternalPerson] = useState(false);
    const [createTimesheet, setCreateTimesheet] = useState(false);

    const [projects, setProjects] = useState([])
    const [villages, setVillages] = useState([])


    function openModalTimesheet() {
        setModalTimesheetOpen(true)
    }

    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;

    const filterItemsByDateRange = () => {
        if (timesheets) {
            if (!dateFromFilter && !dateToFilter) {
                setFilteredItems([])
                return
            }
            const fromDate = new Date(dateFromFilter);
            fromDate.setHours(0, 0, 0, 0);
            let toDate = new Date(dateToFilter);
            toDate.setHours(23, 59, 59, 999);

            let filtered
            if (dateFromFilter && dateToFilter) {
                filtered = timesheets.filter(item => {
                    const startDate = new Date(item.start);
                    return startDate >= fromDate && startDate <= toDate;
                });
            } else if (dateFromFilter) {
                filtered = timesheets.filter(item => {
                    const startDate = new Date(item.start);
                    return startDate >= fromDate;
                });
            }

            setFilteredItems(filtered);
        }
    };

    const filterItems = () => {
        if (timesheets) {
            if (!dateFromFilter && !dateToFilter) {
                setFilteredItems([])
                return
            }
            let filtered = timesheets;

            // Filter by date range
            if (dateFromFilter || dateToFilter) {
                const fromDate = new Date(dateFromFilter);
                fromDate.setHours(0, 0, 0, 0);
                let toDate = new Date(dateToFilter);
                toDate.setHours(23, 59, 59, 999);

                if (dateFromFilter && dateToFilter) {
                    filtered = timesheets.filter(item => {
                        const startDate = new Date(item.start);
                        return startDate >= fromDate && startDate <= toDate;
                    });
                } else if (dateFromFilter) {
                    filtered = timesheets.filter(item => {
                        const startDate = new Date(item.start);
                        return startDate >= fromDate;
                    });
                }
            }

            // Filter by type
            filtered = filtered.filter(item => filtersByTimesheetType[item.type.toString()]);

            setFilteredItems(filtered);
        }
    };

    const openEditTimesheetModal = (event) => {
        setCurrentTimesheetId(event.target.value)
        setModalTimesheetOpen(true)
        let timesheet;
    }

    const handlePageChange = (event) => {
        setCurrentPage(event.detail.pagination.currentPage);
    };
    const closeEditModal = (event) => {
        setTimesheetDate("2024-01-01")
        setTimesheetStartTime("")
        setVacationDateTo("")
        setMoreDaysVacation(false)
        setTimesheetEndTime("")
        setTimesheetType("1")
        setTimesheetIsTrip(false)
        setTimesheetDescription("")
        setTimesheetHoursWorked(null)

        setSelectedProjectId("")
        setSelectedVillageId("")
        setCreateTimesheet(false)
        setModalTimesheetOpen(false);
        setSelectedProjectIdInvalid(true)
        setSelectedVillageIdInvalid(true)
        setTimesheetStartTimeInvalid(true)
        setTimesheetEndTimeInvalid(true)
        setTimesheetDescriptionInvalid(true)
        setTimesheetTimesRangeInvalid(true)
        fetchData()
    }

    const [monthReports, setMonthReports] = useState([])

    function fetchMonthReports() {
        let request = userId ? `?userId=${userId}` : `?userId=${localStorage.getItem("userId")}`;
        fetch(process.env.REACT_APP_API_URL + `/monthReports/workReports${request}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            console.log(jsonData)
            setMonthReports(jsonData)
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    function postMonthReport() {
        fetch(process.env.REACT_APP_API_URL + "/monthReports/workReports", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "date": parseIsoToYearMonth(dateFromFilter)
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                fetchData()
                fetchMonthReports()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });

        closeEditModal()
    }

    function decideMonthReport(id, approve, message) {
        fetch(process.env.REACT_APP_API_URL + "/monthReports/workReports/", {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "state": approve ? "APPROVED" : "REJECTED",
                "note": !approve && message
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                fetchMonthReports()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });

        closeEditModal()
    }


    const [timesheetType, setTimesheetType] = useState("1");
    const [timesheetIsTrip, setTimesheetIsTrip] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState('')
    const [selectedTypes, setSelectedTypes] = useState([]);


    const [selectedVillageId, setSelectedVillageId] = useState('')
    const [timesheetDate, setTimesheetDate] = useState('2024-01-01')
    const [vacationDateTo, setVacationDateTo] = useState('')
    const [dateFromFilter, setDateFromFilter] = useState(() => {
        const today = new Date();
        const startOfMonth = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1))
        return startOfMonth.toISOString().split('T')[0];
    });
    const [dateToFilter, setDateToFilter] = useState(() =>
        new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()))
            .toISOString()
            .split('T')[0]
    );
    const [timesheetStartTime, setTimesheetStartTime] = useState('')
    const [timesheetEndTime, setTimesheetEndTime] = useState('')
    const [timesheetHoursWorked, setTimesheetHoursWorked] = useState(null)
    const [timesheetDescription, setTimesheetDescription] = useState('')

    const [timesheetTypeInvalid, setTimesheetTypeInvalid] = useState(false)
    const [selectedProjectIdInvalid, setSelectedProjectIdInvalid] = useState(true)
    const [selectedVillageIdInvalid, setSelectedVillageIdInvalid] = useState(true)
    const [timesheetDateInvalid, setTimesheetDateInvalid] = useState(true)
    const [vacationDateToInvalid, setVacationDateToInvalid] = useState(true)
    const [timesheetStartTimeInvalid, setTimesheetStartTimeInvalid] = useState(true)
    const [timesheetEndTimeInvalid, setTimesheetEndTimeInvalid] = useState(true)
    const [timesheetDescriptionInvalid, setTimesheetDescriptionInvalid] = useState(true)
    const [timesheetTimesRangeInvalid, setTimesheetTimesRangeInvalid] = useState(true)

    const [timesheetValid, setTimesheetValid] = useState(false)
    const [vacationValid, setVacationValid] = useState(false)

    const handleDateFromFilterChange = (event) => {
        setDateFromFilter(event.target.value);
    }

    const handleDateToFilterChange = (event) => {
        setDateToFilter(event.target.value);
    }

    const calculateHoursWorkedFromIso = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const differenceInMilliseconds = endDate - startDate;
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
        return differenceInHours.toFixed(2); // Returns the difference in hours, rounded to 2 decimal places
    };


    const [dateRange, setDateRange] = useState('');
    const [labels, setLabels] = useState({
        today: '',
        yesterday: '',
        thisWeek: '',
        lastWeek: '',
        thisMonth: '',
        lastMonth: '',
        custom: 'Volný výběr'
    });
    const generateLabels = () => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const thisWeekStart = new Date(today);
        thisWeekStart.setDate(today.getDate() - today.getDay() + 1);

        const lastWeekStart = new Date(thisWeekStart);
        lastWeekStart.setDate(thisWeekStart.getDate() - 7);

        const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        const formatDate = (date) => date.toLocaleDateString('cs-CZ', {day: 'numeric', month: 'long', year: 'numeric'});

        const newLabels = {
            today: `Dnes (${formatDate(today)})`,
            yesterday: `Včera (${formatDate(yesterday)})`,
            thisWeek: `Tento týden (${formatDate(thisWeekStart)} - ${formatDate(today)})`,
            lastWeek: `Minulý týden (${formatDate(lastWeekStart)} - ${formatDate(thisWeekStart)})`,
            thisMonth: `Tento měsíc (${formatDate(thisMonthStart)} - ${formatDate(today)})`,
            lastMonth: `Minulý měsíc (${formatDate(lastMonthStart)} - ${formatDate(lastMonthEnd)})`,
            custom: 'Volný výběr'
        };

        setLabels(newLabels);
        setDateRange(newLabels.thisMonth);
    };
    useEffect(() => {
        generateLabels();
        fetchData()
        fetchMonthReports()
        fetchVillages()
    }, []);
    const handleRangeOfDatesChange = (event) => {
        const selectedRange = event.target.value;
        setDateRange(selectedRange);

        const today = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));
        const yesterday = new Date(today);
        yesterday.setUTCDate(today.getUTCDate() - 1);

        const thisWeekStart = new Date(today);
        thisWeekStart.setUTCDate(today.getUTCDate() - today.getUTCDay() + 1);

        const lastWeekStart = new Date(thisWeekStart);
        lastWeekStart.setUTCDate(thisWeekStart.getUTCDate() - 7);

        const thisMonthStart = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1));
        const lastMonthStart = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - 1, 1));
        const lastMonthEnd = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 0))
        switch (selectedRange) {
            case labels.today:
                setDateFromFilter(today.toISOString().split('T')[0]);
                setDateToFilter(today.toISOString().split('T')[0]);
                break;
            case labels.yesterday:
                setDateFromFilter(yesterday.toISOString().split('T')[0]);
                setDateToFilter(yesterday.toISOString().split('T')[0]);
                break;
            case labels.thisWeek:
                setDateFromFilter(thisWeekStart.toISOString().split('T')[0]);
                setDateToFilter(today.toISOString().split('T')[0]);
                break;
            case labels.lastWeek:
                setDateFromFilter(lastWeekStart.toISOString().split('T')[0]);
                setDateToFilter(thisWeekStart.toISOString().split('T')[0]);
                break;
            case labels.thisMonth:
                setDateFromFilter(thisMonthStart.toISOString().split('T')[0]);
                setDateToFilter(today.toISOString().split('T')[0]);
                break;
            case labels.lastMonth:
                setDateFromFilter(lastMonthStart.toISOString().split('T')[0]);
                setDateToFilter(lastMonthEnd.toISOString().split('T')[0]);
                break;
            case labels.custom:
                setDateFromFilter(null);
                setDateToFilter(null);
                break;
            default:
                break;
        }
    };
    const fetchProjects = () => {
        if (authority !== "HEADQUARTERS" && authority !== "HQ_WITH_REPORT") {
            request = "?regionalCenterId=" + RCid;
        } else {
            request = "";
        }
        fetch(process.env.REACT_APP_API_URL + "/projects" + request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const projectsArray = jsonData.map(project => {
                    return {value: project.id, label: project.name + " (" + project.id + ")"}
                })
                setProjects(projectsArray);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const fetchVillages = () => {
        if (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") {
            request = "/towns";
        } else {
            request = "/rc/" + RCid + "/towns";
        }
        if (localStorage.getItem("towns") === null) {
            fetch(process.env.REACT_APP_API_URL + request, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(jsonData => {
                    setVillages(jsonData);
                    localStorage.setItem("towns", JSON.stringify(jsonData))
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            setVillages(JSON.parse(localStorage.getItem("towns")))
        }
    };

    useEffect(() => {
        if (timesheetType === "2" || timesheetType === 2) {
            fetchVillages();
        } else if (timesheetType === "3" || timesheetType === 3) {
            fetchProjects();
        }
    }, [timesheetType]);

    const [sortDateOrder, setSortDateOrder] = useState('asc');

    const sortTimesheetsByDate = () => {
        if (timesheets) {
            let sortedTimesheets = [...filteredItems];
            sortedTimesheets.sort((a, b) => {
                const dateA = new Date(a.start);
                const dateB = new Date(b.start);
                return sortDateOrder === 'asc' ? dateA - dateB : dateB - dateA;
            });
            setFilteredItems(sortedTimesheets);
        }
    };


    const [activityCollision, setActivityCollision] = useState(false)

    function checkErrorsVacations() {
        let ready
        if (moreDaysVacation) {
            ready = !timesheetTypeInvalid && !timesheetDateInvalid && !vacationDateToInvalid
                && !vacationDateToInvalid && (vacationDateTo > timesheetDate)
        } else {
            const startTime = new Date(`${timesheetDate}T${timesheetStartTime}:00`);
            const endTime = new Date(`${timesheetDate}T${timesheetEndTime}:00`);
            const timeDifferenceInMinutes = (endTime - startTime) / (1000 * 60);

            const validEndings = [0, 15, 30, 45];
            const isValidDifference = timeDifferenceInMinutes >= 15 && validEndings.includes(timeDifferenceInMinutes % 60);

            ready = !timesheetTypeInvalid && !timesheetDateInvalid
                && !timesheetStartTimeInvalid && !timesheetEndTimeInvalid
                && (timesheetEndTime > timesheetStartTime)
                && isValidDifference;
        }
        console.log(timesheetTypeInvalid, timesheetDateInvalid, vacationDateToInvalid, timesheetStartTimeInvalid, timesheetEndTimeInvalid)
        console.log(ready)
        setVacationValid(ready)
    }


    function checkErrorsTimesheet() {
        const formStartTime = new Date(`${timesheetDate}T${timesheetStartTime}:00`);
        const formEndTime = new Date(`${timesheetDate}T${timesheetEndTime}:00`);
        let hasMatchingActivity
        if (currentItems) {
            hasMatchingActivity = currentItems.some(item => {
                const itemStartTime = new Date(item.start);
                const itemEndTime = new Date(item.end);
                return itemStartTime.getTime() === formStartTime.getTime() &&
                    itemEndTime.getTime() === formEndTime.getTime() &&
                    item.id !== currentTimesheetId;
            });
        }
        if (hasMatchingActivity) {
            setActivityCollision(true);
        } else {
            setActivityCollision(false);
        }
        let ready = !timesheetTypeInvalid && !timesheetDateInvalid
            && !timesheetStartTimeInvalid && !timesheetEndTimeInvalid && !timesheetDescriptionInvalid
            && !timesheetTimesRangeInvalid && !hasMatchingActivity

        if (timesheetType === "2") {
            ready = ready && !selectedVillageIdInvalid
        } else if (timesheetType === "3") {
            ready = ready && !selectedProjectIdInvalid
        }
        setTimesheetValid(ready)
    }

    const [filtersByTimesheetType, setFiltersByTimesheetType] = useState({
        "-1": true,
        "1": true,
        "2": true,
        "3": true,
        "4": true,
        "5": true,
        "6": true,
        "7": true
    });

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    function openDeleteModal(event) {
        setCurrentTimesheetId(event.target.value)
        setDeleteModalOpen(true)
    }

    function closeDeleteModal() {
        setCurrentTimesheetId(null)
        setDeleteModalOpen(false)
    }

    function deleteTimesheet(event) {
        fetch(process.env.REACT_APP_API_URL + `/workReports/${currentTimesheetId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.status === 204) {
                    fetchData()
                } else {
                    return response.json().then(data => {
                        throw new Error(`Unexpected response: ${data.message || 'No message provided'}`);
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        closeDeleteModal()
    }

    const handleCheckboxChange = (event) => {
        const {value, checked} = event.target;
        setFiltersByTimesheetType(prevFilters => ({
            ...prevFilters,
            [value]: checked
        }));
    };

    const calculateTotalWorkHours = () => {
        if (currentItems) {
            return currentItems.reduce((total, item) => {
                // if (item.type !== -1) {
                const startDate = new Date(item.start);
                const endDate = new Date(item.end);
                const startDay = startDate.getDay();
                const endDay = endDate.getDay();

                // Check if the start and end dates are not weekends (Saturday or Sunday)
                if (startDay !== 0 && startDay !== 6 && endDay !== 0 && endDay !== 6) {
                    const hoursWorked = calculateHoursWorkedFromIso(item.start, item.end);
                    return total + parseFloat(hoursWorked);
                }
                // }
                return total;
            }, 0).toFixed(2);
        }
        return 0;
    };

    const calculateUtilization = () => {
        if (!filteredItems) return 0;

        const type2And3Items = filteredItems.filter(item => item.type === 2 || item.type === 3);

        const totalHoursType2And3 = type2And3Items.reduce((total, item) => {
            const hoursWorked = calculateHoursWorkedFromIso(item.start, item.end);
            return total + parseFloat(hoursWorked);
        }, 0);

        const totalHoursAllActivities = filteredItems.reduce((total, item) => {
            const hoursWorked = calculateHoursWorkedFromIso(item.start, item.end);
            return total + parseFloat(hoursWorked);
        }, 0);

        const utilization = totalHoursAllActivities ? (totalHoursType2And3 / totalHoursAllActivities) * 100 : 0;

        return utilization.toFixed(2);
    };
    const [moreDaysVacation, setMoreDaysVacation] = useState(false)

    const handleMoreDaysVacationChange = (event) => {
        setMoreDaysVacation(!moreDaysVacation);
    };

    useEffect(() => {
        checkErrorsTimesheet()
    }, [timesheetType, timesheetDate, timesheetStartTime, timesheetEndTime, timesheetDescription, selectedProjectId, selectedVillageId])


    useEffect(() => {
        checkErrorsVacations()
    }, [timesheetType, moreDaysVacation, timesheetDate, timesheetStartTime, timesheetEndTime, vacationDateTo])


    useEffect(() => {
        sortTimesheetsByDate();
        // eslint-disable-next-line no-use-before-define
    }, [sortDateOrder]);

    useEffect(() => {
        filterItems();
        fetchMonthWorkFund(dateFromFilter)
    }, [timesheets, dateFromFilter, dateToFilter, filtersByTimesheetType]);


    const [currentMonthReport, setCurrentMonthReport] = useState(monthReports.find(report => report.date === parseIsoToYearMonth(dateFromFilter)))
    console.log(currentMonthReport)
    useEffect(() => {
        setCurrentMonthReport(monthReports.find(report => report.date === parseIsoToYearMonth(dateFromFilter)));
    }, [dateFromFilter, monthReports]);

    const setTypeOfView = (event) => {
        setWhatToDisplay(event.detail.label)
        fetchData()
    }

    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const isWithinLastFiveDays = (lastDayOfMonth - today) / (1000 * 60 * 60 * 24) <= 5;

    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    function calculateHoursForMonth(monthWorkFund) {
        const workAgreementType = localStorage.getItem("workAgreementTypeId")
        const workAllocation = localStorage.getItem("workAllocation")
        const workMultiplier = workAgreementType === "1" ? 1 : 0.5
        return monthWorkFund * workMultiplier * workAllocation
    }

    useEffect(() => {
        fetchData()
    }, [modalTimesheetOpen]);

    return (
        <div className="">
            <Navigation active={"timesheet"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Timesheet</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container vh-80">
                    <div className="gov-container__content">
                        <div className="gov-title--beta">Správa timesheetů</div>
                        <GovTabs onGov-change={setTypeOfView} type="chip" variant="primary"
                                 wcag-label="Basic information about the gov.cz design system">
                            <GovTabsItem label="Správa aktivit"></GovTabsItem>
                            <GovTabsItem label="Přehled timesheetů"/>
                        </GovTabs>
                        {whatToDisplay === "Správa aktivit" &&
                            <div>
                                <div className="gov-flexbox gov-flexbox--justify-start align-items-normal">
                                    <div>

                                        <GovFormControl>
                                            <GovFormSelect size="s" variant="secondary" value={dateRange}
                                                           onGov-change={handleRangeOfDatesChange}>
                                                {Object.entries(labels).map(([key, value]) => (
                                                    <option key={key} value={value}>{value}</option>
                                                ))}
                                            </GovFormSelect>
                                            <GovFormLabel slot="top" size="s">Období k zobrazení*</GovFormLabel>
                                        </GovFormControl>
                                        {dateRange === labels.custom &&
                                            <div className="gov-flexbox gov-flexbox--justify-start">
                                                <GovFormControl>
                                                    <GovFormLabel slot="top" size="s">Datum od</GovFormLabel>
                                                    <GovFormInput
                                                        size="s" variant="secondary"
                                                        input-type="date"
                                                        value={dateFromFilter}
                                                        onGov-input={handleDateFromFilterChange}>
                                                    </GovFormInput>
                                                </GovFormControl>
                                                <GovFormControl className="ml-5">
                                                    <GovFormLabel slot="top" size="s">Datum do</GovFormLabel>
                                                    <GovFormInput
                                                        size="s" variant="secondary"
                                                        input-type="date"
                                                        value={dateToFilter}
                                                        onGov-input={handleDateToFilterChange}>
                                                    </GovFormInput>
                                                </GovFormControl>
                                            </div>
                                        }
                                    </div>
                                    <div className="ml-30">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <th>Pracovní fond:</th>
                                                <td>{workFund ? calculateHoursForMonth(workFund) + " hodin" :
                                                    <GovSkeleton wcag-label="Loading content of page"
                                                                 variant="secondary"
                                                                 shape="text"
                                                                 animation="progress" count="1" width="300px"
                                                                 height="30px"></GovSkeleton>}</td>
                                            </tr>
                                            <tr>
                                                <th>Celková odpracovaná doba:</th>
                                                <td>{currentItems ? calculateTotalWorkHours() + " hodin" :
                                                    <GovSkeleton wcag-label="Loading content of page"
                                                                 variant="secondary"
                                                                 shape="text"
                                                                 animation="progress" count="1" width="300px"
                                                                 height="30px"></GovSkeleton>} </td>
                                            </tr>
                                            <tr>
                                                <th>Počet aktivit:</th>
                                                <td>{currentItems ? currentItems.length :
                                                    <GovSkeleton wcag-label="Loading content of page"
                                                                 variant="secondary"
                                                                 shape="text"
                                                                 animation="progress" count="1" width="300px"
                                                                 height="30px"></GovSkeleton>}</td>
                                            </tr>
                                            {/*<tr>*/}
                                            {/*    <th>Utilizace:</th>*/}
                                            {/*    <td style={{color: calculateUtilization() < 50 ? 'red' : 'green'}}>*/}
                                            {/*        {calculateUtilization()} %*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                <div className="gov-flexbox gov-flexbox--justify-start">
                                    <div>
                                        {(!currentMonthReport && !userId) &&
                                            <div>
                                                <GovButton className="gov-flexbox--justify-center"
                                                           wcag-label="What exactly happens when you click?"
                                                           variant="primary"
                                                           type="solid"
                                                           onGov-click={() => {
                                                               openModalTimesheet();
                                                               setCreateTimesheet(true);
                                                           }}
                                                           size="m"
                                                           disabled={currentItems && workFund && calculateTotalWorkHours() >= calculateHoursForMonth(workFund)}>
                                                    Přidat aktivitu
                                                </GovButton>
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        {(dateRange === labels.lastMonth && !currentMonthReport) &&
                                            <GovButton className="ml-5 gov-flexbox--justify-center"
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="solid"
                                                       onGov-click={() => {
                                                           postMonthReport()
                                                           setCreateTimesheet(true)
                                                       }} size="m">Uzavřít timesheet
                                            </GovButton>}
                                        {(dateRange === labels.thisMonth && monthReports && !currentMonthReport && isWithinLastFiveDays) &&
                                            <GovButton className="ml-5 gov-flexbox--justify-center"
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="solid"
                                                       onGov-click={() => {
                                                           postMonthReport()
                                                           setCreateTimesheet(true)
                                                       }} size="m">Uzavřít timesheet
                                            </GovButton>}
                                        {(currentMonthReport && (currentMonthReport.state === "CREATED")) &&
                                            <div>Tento výkaz se aktuálně schvaluje.</div>
                                        }
                                        {(currentMonthReport && (currentMonthReport.state === "APPROVED_BY_SUPERVISOR")) &&
                                            <div style={{color: 'green'}}>Tento výkaz byl schválen vedoucím regionálního
                                                centra
                                                dne {convertIsoToDateFormat(currentMonthReport.supervisorChangeOn)}.</div>
                                        }
                                        {(currentMonthReport && currentMonthReport.state === "APPROVED_BY_HQ") &&
                                            <div style={{color: 'green'}}>Tento výkaz byl schválen centrálou
                                                dne {convertIsoToDateFormat(currentMonthReport.hqChangeOn)}</div>
                                        }
                                        {(currentMonthReport && currentMonthReport.state === "REJECTED_BY_HQ") &&
                                            <div><span style={{color: 'red'}}>Tento výkaz byl zamítnut centrálou.</span>
                                                <p>Důvod
                                                    zamítnutí: {currentMonthReport.note}</p>
                                            </div>
                                        }
                                        {(authority === "RC_LEADER" && currentMonthReport && currentMonthReport.state === "REJECTED_BY_HQ") &&
                                            <div>
                                                <div>
                                                    <GovButton className="gov-flexbox--justify-center"
                                                               wcag-label="What exactly happens when you click?"
                                                               variant="primary"
                                                               type="solid"
                                                               onGov-click={() => {
                                                                   openModalTimesheet();
                                                                   setCreateTimesheet(true);
                                                               }}
                                                               size="m"
                                                               disabled={currentItems && workFund && calculateTotalWorkHours() >= calculateHoursForMonth(workFund)}>
                                                        Přidat aktivitu
                                                    </GovButton>
                                                    <GovButton className="gov-flexbox--justify-center"
                                                               wcag-label="What exactly happens when you click?"
                                                               variant="primary"
                                                               type="solid"
                                                               onGov-click={() => {
                                                                   postMonthReport()
                                                                   setCreateTimesheet(true)
                                                               }} size="m">Uzavřít timesheet znovu
                                                    </GovButton>
                                                </div>
                                            </div>
                                        }

                                        {(currentMonthReport && currentMonthReport.state === "REJECTED_BY_SUPERVISOR") &&
                                            <div>
                                                <div><span style={{color: 'red'}}>Tento výkaz byl zamítnut vedoucím regionálního centra.</span>
                                                    <p>Důvod
                                                        zamítnutí: {currentMonthReport.note}</p>
                                                </div>
                                                <div>
                                                    <GovButton className="gov-flexbox--justify-center"
                                                               wcag-label="What exactly happens when you click?"
                                                               variant="primary"
                                                               type="solid"
                                                               onGov-click={() => {
                                                                   openModalTimesheet();
                                                                   setCreateTimesheet(true);
                                                               }} size="m"
                                                               disabled={currentItems && workFund && calculateTotalWorkHours() >= calculateHoursForMonth(workFund)}>
                                                        Přidat aktivitu
                                                    </GovButton>
                                                    <GovButton className="gov-flexbox--justify-center"
                                                               wcag-label="What exactly happens when you click?"
                                                               variant="primary"
                                                               type="solid"
                                                               onGov-click={() => {
                                                                   postMonthReport()
                                                                   setCreateTimesheet(true)
                                                               }} size="m">Uzavřít timesheet znovu
                                                    </GovButton>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                                <GovSpacer size="xl"></GovSpacer>
                                {currentItems ?
                                    <table className="fixed" cellSpacing="0" cellPadding="0" width="100%">
                                        <thead>
                                        <tr>
                                            <th className="w-110px">
                                                Datum
                                                {sortDateOrder === "asc" ?
                                                    <GovButton onGov-click={() => setSortDateOrder('desc')}
                                                               wcag-label="What exactly happens when you click?"
                                                               variant="primary"
                                                               type="link" size="xs" loading="false">

                                                        <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                                 slot="left-icon"></GovIcon> </GovButton>
                                                    :
                                                    <GovButton onGov-click={() => setSortDateOrder('asc')}
                                                               wcag-label="What exactly happens when you click?"
                                                               variant="primary"
                                                               type="link" size="xs" loading="false">

                                                        <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                                 slot="left-icon"></GovIcon> </GovButton>
                                                }
                                            </th>
                                            <th className="w-70px">Začátek</th>
                                            <th className="w-70px">Konec</th>
                                            <th className="w-110px">Odpracováno</th>
                                            <th className="w-70px">Cesta</th>
                                            <th className="w-150px">
                                                <div className="gov-flexbox--justify-start">
                                                    <GovDropdown position="left" className="min-height-400">
                                                        <GovButton className="padding-0" variant="primary" size="xs"
                                                                   type="base"
                                                                   wcag-label="What exactly happens when you click?"
                                                                   right-icon="basic/chevron-down">Typ výkazu
                                                            <GovIcon type="basic" name="chevron-down"
                                                                     slot="right-icon"></GovIcon>
                                                        </GovButton>
                                                        <ul slot="list">
                                                            <li>
                                                                <GovButton variant="secondary" size="xs" type="base"
                                                                           wcag-label="Home">
                                                                    <GovFormCheckbox size="xs" value="1"
                                                                                     onGov-change={handleCheckboxChange}
                                                                                     checked={filtersByTimesheetType["1"]}>
                                                                        <GovFormLabel size="xs" slot="label">Administrativa
                                                                        </GovFormLabel>
                                                                    </GovFormCheckbox>
                                                                </GovButton>
                                                            </li>
                                                            <li>
                                                                <GovButton variant="secondary" size="xs" type="base"
                                                                           wcag-label="Home">
                                                                    <GovFormCheckbox size="xs" value="2"
                                                                                     onGov-change={handleCheckboxChange}
                                                                                     checked={filtersByTimesheetType["2"]}>
                                                                        <GovFormLabel size="xs" slot="label">Obec
                                                                        </GovFormLabel>
                                                                    </GovFormCheckbox>
                                                                </GovButton>
                                                            </li>
                                                            <li>
                                                                <GovButton variant="secondary" size="xs" type="base"
                                                                           wcag-label="Home">
                                                                    <GovFormCheckbox size="xs" value="3"
                                                                                     onGov-change={handleCheckboxChange}
                                                                                     checked={filtersByTimesheetType["3"]}>
                                                                        <GovFormLabel size="xs" slot="label">Projekt
                                                                        </GovFormLabel>
                                                                    </GovFormCheckbox>
                                                                </GovButton>
                                                            </li>
                                                            <li>
                                                                <GovButton variant="secondary" size="xs" type="base"
                                                                           wcag-label="Home">
                                                                    <GovFormCheckbox size="xs" value="7"
                                                                                     onGov-change={handleCheckboxChange}
                                                                                     checked={filtersByTimesheetType["7"]}>
                                                                        <GovFormLabel size="xs" slot="label">Konzultace
                                                                        </GovFormLabel>
                                                                    </GovFormCheckbox>
                                                                </GovButton>
                                                            </li>
                                                            <li>
                                                                <GovButton variant="secondary" size="xs" type="base"
                                                                           wcag-label="Home">
                                                                    <GovFormCheckbox size="xs" value="4"
                                                                                     onGov-change={handleCheckboxChange}
                                                                                     checked={filtersByTimesheetType["4"]}>
                                                                        <GovFormLabel size="xs" slot="label">Konzultace
                                                                            MMR
                                                                        </GovFormLabel>
                                                                    </GovFormCheckbox>
                                                                </GovButton>
                                                            </li>
                                                            <li>
                                                                <GovButton variant="secondary" size="xs" type="base"
                                                                           wcag-label="Home">
                                                                    <GovFormCheckbox size="xs" value="5"
                                                                                     onGov-change={handleCheckboxChange}
                                                                                     checked={filtersByTimesheetType["5"]}>
                                                                        <GovFormLabel size="xs" slot="label">Školení
                                                                        </GovFormLabel>
                                                                    </GovFormCheckbox>
                                                                </GovButton>
                                                            </li>
                                                            <li>
                                                                <GovButton variant="secondary" size="xs" type="base"
                                                                           wcag-label="Home">
                                                                    <GovFormCheckbox size="xs" value="6"
                                                                                     onGov-change={handleCheckboxChange}
                                                                                     checked={filtersByTimesheetType["6"]}>
                                                                        <GovFormLabel size="xs" slot="label">Cesta
                                                                        </GovFormLabel>
                                                                    </GovFormCheckbox>
                                                                </GovButton>
                                                            </li>
                                                            <li>
                                                                <GovButton variant="secondary" size="xs" type="base"
                                                                           wcag-label="Home">
                                                                    <GovFormCheckbox size="xs" value="-1"
                                                                                     onGov-change={handleCheckboxChange}
                                                                                     checked={filtersByTimesheetType["-1"]}>
                                                                        <GovFormLabel size="xs" slot="label">Dovolená
                                                                        </GovFormLabel>
                                                                    </GovFormCheckbox>
                                                                </GovButton>
                                                            </li>
                                                        </ul>
                                                    </GovDropdown>
                                                </div>
                                            </th>
                                            <th className="w-200px">
                                                Vazba na entitu
                                            </th>
                                            <th className="w-250px">
                                                Popis vykonaných prací/činností
                                            </th>
                                            <th>
                                                Akce
                                            </th>

                                        </tr>
                                        </thead>

                                        <tbody>
                                        {currentItems.map((row, index) => (
                                            <tr key={index}>
                                                <td>{convertIsoToDateFormat(row.start)}</td>
                                                <td>{convertIsoToTime(row.start)}</td>
                                                <td>{convertIsoToTime(row.end)}</td>
                                                <td>{(calculateHoursWorkedFromIso(row.start, row.end))}</td>
                                                <td>{row.isTrip ? "Ano" : "Ne"}</td>
                                                <td>{(mapTimeSheetType(row.type))}</td>
                                                <td>{(row.project && row.type === 3) ?
                                                    <a href={`/projects/detail/${row.project.id}`}>{row.project.id}</a> : (row.town && row.type === 2) ?
                                                        <a href={`/towns/detail/${row.town.id}`}>{row.town.name + " (" + row.town.id + ")"}</a> : (row.preProjectConsultationId && row.type === 7) ?
                                                            <a href={`/new-consultation/detail/${row.preProjectConsultationId}`}>{row.preProjectConsultationId}</a> : "Aktivita nemá vazbu"}</td>
                                                <td>{row.description}</td>
                                                {((authority === "RC_EMPLOYEE" && row.workMonthReportState === "REJECTED_BY_SUPERVISOR")
                                                        || (row.workMonthReportState === null)
                                                        || (authority === "RC_LEADER" && (row.workMonthReportState === "CREATED" || row.workMonthReportState === "REJECTED_BY_HQ"))
                                                        || (row.workMonthReportState !== "APPROVED_BY_HQ" && (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT"))
                                                        || ((authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") && (currentDay <= 10 || currentDay >= 25))) &&
                                                    <td>
                                                        <GovButton variant="primary" type="link" size="s"
                                                                   value={row.id}
                                                                   onGov-click={openEditTimesheetModal}>
                                                            <GovIcon type="basic" name="pencil-square"
                                                                     slot="left-icon"></GovIcon>
                                                        </GovButton>

                                                        <GovButton className="ml-5"
                                                                   value={row.id}
                                                                   wcag-label="What exactly happens when you click?"
                                                                   variant="error" type="link" size="s"
                                                                   onGov-click={openDeleteModal}>
                                                            <GovIcon type="basic" name="trash"
                                                                     slot="left-icon"></GovIcon>
                                                        </GovButton>
                                                    </td>
                                                }
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    : (
                                        <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                                     shape="text"
                                                     animation="progress" count="1" width="300px"
                                                     height="30px"></GovSkeleton>
                                    )}
                            </div>
                        }
                        {whatToDisplay === "Přehled timesheetů" &&
                            <div>
                                <div>
                                    <table className="gov-table">
                                        <thead>
                                        <tr>
                                            <th>Měsíc</th>
                                            <th>Stav</th>
                                            <th>Datum poslední změny</th>
                                            <th>Poznámka</th>
                                            <th>Detail</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {monthReports.map((report, index) => (
                                            <tr key={index}>
                                                <td>{mapDateFormat(report.date)}</td>
                                                <td style={{color: report.state.includes('APPROVED') ? 'green' : report.state.includes('REJECTED') ? 'red' : 'inherit'}}>
                                                    {approvalStateToText(report.state)}
                                                </td>
                                                <td>
                                                    {report.state.includes('APPROVED_BY_HQ') ? convertIsoToDateFormat(report.hqChangeOn ? report.hqChangeOn : report.supervisorChangeOn) :
                                                        report.state.includes('APPROVED_BY_SUPERVISOR') ? convertIsoToDateFormat(report.supervisorChangeOn) :
                                                            report.state.includes('REJECTED_BY_HQ') ? convertIsoToDateFormat(report.hqChangeOn) :
                                                                report.state.includes('REJECTED_BY_SUPERVISOR') ? convertIsoToDateFormat(report.supervisorChangeOn) :
                                                                    ""}
                                                </td>
                                                <td>{report.state !== "CREATED" && report.note}</td>
                                                <td><a href={`/timesheet/month-report/${report.id}`}>Zobrazit
                                                    podrobnosti</a></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
            <GovPrompt open={deleteModalOpen} onGov-close={closeDeleteModal}
                       label="Smazat výkaz práce" role="dialog"
                       label-tag="h5"
                       wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu
                chcete smazat tento výkaz práce?<br/>Tato operace je
                nevratná.
                <GovButton onGov-click={closeDeleteModal} variant="primary"
                           size="m"
                           type="base" slot="actions"
                           wcag-label="Cancel delete file my-photo.jpg">Zrušit
                </GovButton>
                <GovButton variant="error" size="m"
                           type="solid"
                           slot="actions"
                           wcag-label="Potvrdit smazání"
                           onGov-click={deleteTimesheet}>Smazat
                </GovButton>
            </GovPrompt>
            {modalTimesheetOpen &&
                <TimesheetModal timesheetId={currentTimesheetId} timesheets={timesheets} create={createTimesheet}
                                onGovModalClose={closeEditModal}></TimesheetModal>
            }
        </div>
    )
}

