import {
    GovButton,
    GovFormControl,
    GovFormFile,
    GovFormInput,
    GovFormLabel,
    GovSpacer
} from "@gov-design-system-ce/react";
import {useRef, useState} from "react";
import {parseJwt} from "../utils/utils";
import {useNavigate} from "react-router-dom";

export function SyncConsultationsContent() {
    const jwt = localStorage.getItem("jwt");
    const [fileToSend, setFileToSend] = useState('');
    const user = parseJwt(localStorage.getItem("jwt"))
    let role
    const navigate = useNavigate()
    if (user !== null) {
        role = user.role[0].authority
    }

    function goToLogin() {
        navigate("/login")
    }

    const handleFileChange = (event) => {
        const file = event.detail.files[0].file
        setFileToSend(file);
        console.log(file);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let data = new FormData();
        data.append('file', fileToSend);

        fetch(process.env.REACT_APP_API_URL + "/consultations/import", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: data
        })
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
    }

    return (

        <div>
            {(role === "HEADQUARTERS" || role === "HQ_WITH_REPORT") ?
                <div>
                    <div className="gov-container">
                        <div className="gov-container__content vh-80">
                            <form>
                                <GovFormFile onGov-files={handleFileChange} max-file-size="616448"
                                             accept=".pdf,.csv,.png,.jpeg"
                                             expanded="true">
                                    <p>
                                        <GovButton variant="primary" size="m" type="outlined">Nahrát soubor ze
                                            zařízení</GovButton>
                                    </p>
                                    <GovSpacer size="m"></GovSpacer>
                                    <p className="gov-text--s">Podporované formáty CSV, PDF, DOC</p></GovFormFile>
                                {/*<input ref={fileInputRef} onChange={handleFileChange} type="file"/>*/}
                                <GovButton onGov-click={handleSubmit} wcag-label="What exactly happens when you click?"
                                           variant="primary" type="solid"
                                           class="mt-10"
                                           size="m">Nahrát
                                </GovButton>
                            </form>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className="gov-container">
                        <div className="gov-container__content vh-80">
                            <h3>Pro tuto operaci nemáte dostatečná oprávnění.</h3>
                            <GovButton onGov-click={goToLogin}
                                       wcag-label="What exactly happens when you click?"
                                       variant="primary" type="solid"
                                       size="m">Přejít na přihlašovací stránku
                            </GovButton>
                        </div>
                    </div>
                </div>
            }
        </div>

    )

}
