import Navigation from "../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs, GovButton, GovDropdown, GovFormCheckbox,
    GovFormControl, GovFormFile, GovFormGroup, GovFormInput,
    GovFormLabel, GovFormMessage,
    GovFormSelect,
    GovIcon, GovLoading, GovMessage, GovModal, GovPrompt, GovSkeleton, GovSpacer, GovTabs, GovTabsItem,
    GovTile,
    GovTiles
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import "../css/report-statistics.css";
import {
    convertIsoToDateFormat,
    convertIsoToCustomFormat,
    parseJwt,
    convertIsoToLocalDate,
    convertIsoToTime, mapTimeSheetType, getIcon, parseIsoToYearMonth, mapDateFormat, approvalStateToText
} from "../utils/utils";
import Select, {components} from "react-select";
import {MenuList} from "./MenuList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {RoadCompensationModal} from "./RoadCompensationModal";
import {TripReportDetailModal} from "./TripReportDetailModal";
import {TownDetailModal} from "./TownDetailModal";

export function RoadCompensationContent({userId}) {
    const jwt = localStorage.getItem("jwt")
    let user = JSON.parse(localStorage.getItem("user"));

    let [roadCompensations, setRoadCompensations] = useState(null)


    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");


    const fetchData = () => {
        fetch(process.env.REACT_APP_API_URL + `/workTrips?userId=${localStorage.getItem("userId")}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const sortedData = jsonData.sort((a, b) => new Date(a.workReport.start) - new Date(b.workReport.start));
                setFilteredItems(sortedData);
                setRoadCompensations(sortedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    };

    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 100;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [filteredItems, setFilteredItems] = useState(null);
    const [modalTimesheetOpen, setModalTimesheetOpen] = useState(false);

    const [createTimesheet, setCreateTimesheet] = useState(true);

    const [projects, setProjects] = useState([])
    const [villages, setVillages] = useState([])


    function openModalTimesheet() {
        setCreateTimesheet(true)
        setModalTimesheetOpen(true)
    }


    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;

    const filterItemsByDateRange = () => {
        if (roadCompensations) {
            if (!dateFromFilter && !dateToFilter) {
                setFilteredItems([])
                return
            }
            const fromDate = new Date(dateFromFilter);
            fromDate.setHours(0, 0, 0, 0);
            let toDate = new Date(dateToFilter);
            toDate.setHours(23, 59, 59, 999);

            let filtered
            if (dateFromFilter && dateToFilter) {
                filtered = roadCompensations.filter(item => {
                    const startDate = new Date(item.workReport.start);
                    return startDate >= fromDate && startDate <= toDate;
                });
            } else if (dateFromFilter) {
                filtered = roadCompensations.filter(item => {
                    const startDate = new Date(item.workReport.start);
                    return startDate >= fromDate;
                });
            }

            setFilteredItems(filtered);
        }
    };

    const filterItems = () => {
        if (roadCompensations) {
            if (!dateFromFilter && !dateToFilter) {
                setFilteredItems([])
                return
            }
            let filtered = roadCompensations;

            // Filter by date range
            if (dateFromFilter || dateToFilter) {
                const fromDate = new Date(dateFromFilter);
                fromDate.setHours(0, 0, 0, 0);
                let toDate = new Date(dateToFilter);
                toDate.setHours(23, 59, 59, 999);

                if (dateFromFilter && dateToFilter) {
                    filtered = roadCompensations.filter(item => {
                        const startDate = new Date(item.workReport.start);
                        return startDate >= fromDate && startDate <= toDate;
                    });
                } else if (dateFromFilter) {
                    filtered = roadCompensations.filter(item => {
                        const startDate = new Date(item.workReport.start);
                        return startDate >= fromDate;
                    });
                }
            }

            // Filter by type
            filtered = filtered.filter(item => filtersByTimesheetType[item.workReport.type.toString()]);

            setFilteredItems(filtered);
        }
    };

        const [nameOfExpense, setNameOfExpense] = useState("");
        const [costOfExpense, setCostOfExpense] = useState("");
        const [expenses, setExpenses] = useState([]);
        const [currentRoadCompensationId, setCurrentRoadCompensationId] = useState(null);

        function fetchCurrentWorkTripCompensation(id) {
            let roadCompensation;
            fetch(process.env.REACT_APP_API_URL + `/workTrips/${id}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                }).then(jsonData => {
                console.log(jsonData)
                roadCompensation = jsonData;
                setCurrentRoadCompensationId(roadCompensation.id)

                setExpenses(roadCompensation.expenses)
                setFromPlace(roadCompensation.from)
                setToPlace(roadCompensation.to)
                setRoadDistance(roadCompensation.distance)
                setFoodCountProvided(roadCompensation.mealsCount)
                setRoadCompensationDate(convertIsoToLocalDate(roadCompensation.workReport.start))
                setRoadCompensationStartTime(convertIsoToTime(roadCompensation.workReport.start))
                setRoadCompensationEndTime(convertIsoToTime(roadCompensation.workReport.end))
                setRoadCompensationDuration(calculateHoursWorkedForEdit(roadCompensation.workReport.start, roadCompensation.workReport.end))
                setWorkReportType(roadCompensation.workReport.type)
                setTransportType(roadCompensation.transportType.toString())
                console.log(roadCompensation.workReport.town ? roadCompensation.workReport.town.id : '')
            })
        }

        const [addExpensesAfterCreation, setAddExpensesAfterCreation] = useState(false)

        function openRoadCompensationModalForAdditionalExpenses(id) {
            setCreateTimesheet(false)
            fetchCurrentWorkTripCompensation(id)
            setPageView("Další náklady")
            setAddExpensesAfterCreation(true)
        }


        const openEditRoadCompensationModal = (event) => {
            setCreateTimesheet(false)
            setModalTimesheetOpen(true)
            setCurrentRoadCompensationId(event.target.value)
        }

        const handlePageChange = (event) => {
            setCurrentPage(event.detail.pagination.currentPage);
        };

        const changeCreateTimesheet = (state) => {
            setCreateTimesheet(state)
        }

        const closeEditModal = (event) => {
            setCurrentRoadCompensationId(null)
            setIsSelectedCarInvalid(false)
            setPageView("Cesta")
            setNameOfExpense("")
            setCostOfExpense("")
            setTransportType("2")
            setWorkReportType("6")
            setSelectedProjectId("")
            setSelectedVillageId("")
            setSelectedCarId("")
            setRoadDistance("")
            setFromPlace("")
            setToPlace("")
            setRoadCompensationDate("2024-01-01")
            setRoadCompensationStartTime("")
            setRoadCompensationEndTime("")
            setFoodCountProvided("0")
            setSelectedCarId("")
            setRoadCompensationDescription("")
            setRoadCompensationDuration(null)

            setSelectedProjectId("")
            setSelectedVillageId("")
            setCreateTimesheet(true)
            setPassengerInvalid(false)
            setModalTimesheetOpen(false);
            setSelectedProjectIdInvalid(true)
            setRoadCompensationDateInvalid(true)
            setSelectedVillageIdInvalid(true)
            setRoadCompensationStartTimeInvalid(true)
            setRoadCompensationEndTimeInvalid(true)
            setRoadCompensationDescriptionInvalid(true)
            setRoadCompensationTimesRangeInvalid(true)
        }

        function calculateCompensation() {
            const fuelPrice = 38.2
            const usageCompensation = 5.6

            return (roadDistance * usageCompensation + roadDistance * (fuelPrice * fuelConsumption / 100)).toFixed(2)
        }

        function postRoadCompensation() {
            fetch(process.env.REACT_APP_API_URL + "/workTrips", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "description": roadCompensationDescription,
                    "note": "",
                    "transportType": transportType,
                    "from": fromPlace,
                    "to": toPlace,
                    "mealsCount": foodCountProvided,
                    "distance": transportType === "2" ? roadDistance : 0,
                    "privateVehicleId": transportType === "2" ? selectedCarId : null,
                    "companyVehicleId": transportType === "3" ? selectedCarId : null,
                    "start": roadCompensationDate + "T" + roadCompensationStartTime + ":00",
                    "end": roadCompensationDate + "T" + roadCompensationEndTime + ":00",
                    "workReportType": workReportType,
                    "projectId": selectedProjectId === "" ? null : selectedProjectId,
                    "townId": selectedVillageId === "" ? null : selectedVillageId,
                })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(jsonData => {
                    console.log(jsonData);
                    openRoadCompensationModalForAdditionalExpenses(jsonData.id);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }


        function putRoadCompensation() {
            fetch(process.env.REACT_APP_API_URL + `/workTrips/${currentRoadCompensationId}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify({
                    "description": roadCompensationDescription,
                    "note": "",
                    "transportType": transportType,
                    "from": fromPlace,
                    "to": toPlace,
                    "mealsCount": foodCountProvided,
                    "distance": transportType === "2" ? roadDistance : 0,
                    "privateVehicleId": transportType === "2" ? selectedCarId : null,
                    "companyVehicleId": transportType === "3" ? selectedCarId : null,
                    "start": roadCompensationDate + "T" + roadCompensationStartTime + ":00",
                    "end": roadCompensationDate + "T" + roadCompensationEndTime + ":00",
                    "workReportType": workReportType,
                    "projectId": selectedProjectId === "" ? null : selectedProjectId,
                    "townId": selectedVillageId === "" ? null : selectedVillageId,
                })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(() => {
                    closeEditModal()
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
            closeEditModal()
        }


        const [transportType, setTransportType] = useState("2");
        const [workReportType, setWorkReportType] = useState("6");
        const [selectedProjectId, setSelectedProjectId] = useState('')
        const [selectedTypes, setSelectedTypes] = useState([]);
        const [selectedCarId, setSelectedCarId] = useState('')

        const [selectedVillageId, setSelectedVillageId] = useState('')
        const [roadCompensationDate, setRoadCompensationDate] = useState('2024-01-01')
        const [fromPlace, setFromPlace] = useState('')
        const [toPlace, setToPlace] = useState('')
        const [roadDistance, setRoadDistance] = useState('')
        const [dateFromFilter, setDateFromFilter] = useState(() => {
        const today = new Date();
        const startOfMonth = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1))
        return startOfMonth.toISOString().split('T')[0];
    });
    const [dateToFilter, setDateToFilter] = useState(() =>
        new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()))
            .toISOString()
            .split('T')[0]
    );
        const [roadCompensationStartTime, setRoadCompensationStartTime] = useState('')
        const [roadCompensationEndTime, setRoadCompensationEndTime] = useState('')
        const [roadCompensationDuration, setRoadCompensationDuration] = useState(null)
        const [roadCompensationDescription, setRoadCompensationDescription] = useState('')
        const [foodCountProvided, setFoodCountProvided] = useState('0')
        const [timesheetTypeInvalid, setTimesheetTypeInvalid] = useState(false)
        const [passenger, setPassenger] = useState('')
        const [passengerInvalid, setPassengerInvalid] = useState(false)
        const [selectedProjectIdInvalid, setSelectedProjectIdInvalid] = useState(true)
        const [selectedVillageIdInvalid, setSelectedVillageIdInvalid] = useState(true)
        const [roadCompensationFromPlaceInvalid, setRoadCompensationFromPlaceInvalid] = useState(true)
        const [roadCompensationToPlaceInvalid, setRoadCompensationToPlaceInvalid] = useState(true)
        const [foodCountProvidedInvalid, setFoodCountProvidedInvalid] = useState(true)
        const [roadCompensationDateInvalid, setRoadCompensationDateInvalid] = useState(true)
        const [roadCompensationStartTimeInvalid, setRoadCompensationStartTimeInvalid] = useState(true)
        const [roadCompensationEndTimeInvalid, setRoadCompensationEndTimeInvalid] = useState(true)
        const [roadCompensationDescriptionInvalid, setRoadCompensationDescriptionInvalid] = useState(true)
        const [roadCompensationTimesRangeInvalid, setRoadCompensationTimesRangeInvalid] = useState(true)
        const [cars, setCarsArray] = useState([])
        const [fuelConsumption, setFuelConsumption] = useState(0)

        const [roadCompensationValid, setRoadCompensationValid] = useState(false)

        const handleFromPlaceChange = (event) => {
            if (event.target.value === "") {
                setRoadCompensationFromPlaceInvalid(true)
            } else {
                setRoadCompensationFromPlaceInvalid(false)
            }
            setFromPlace(event.target.value);
        }


        const handleFoodCountProvidedChange = (event) => {
            if (event.target.value === "") {
                setFoodCountProvidedInvalid(true)
            } else {
                setFoodCountProvidedInvalid(false)
            }
            setFoodCountProvided(event.target.value);
        }

        const handleToPlaceChange = (event) => {
            if (event.target.value === "") {
                setRoadCompensationToPlaceInvalid(true)
            } else {
                setRoadCompensationToPlaceInvalid(false)
            }
            setToPlace(event.target.value);
        }

        const handleMeanOfTransportSelectChange = (event) => {
            setSelectedCarId("")
            if (event.target.value === "4") {
                setPassengerInvalid(true)
                setRoadCompensationDescription("Spolujezdec: ")
            } else {
                setPassengerInvalid(false)
                setRoadCompensationDescription("")
            }
            setTransportType(event.target.value);
        };

        const handleVillageIdChange = (event) => {
            if (event.target === "") {
                setSelectedVillageIdInvalid(true)
            } else {
                console.log("setting to valid")
                setSelectedVillageIdInvalid(false)
            }
            setSelectedVillageId(event.value);
        };

        const handleRoadDistanceChange = (event) => {
            setRoadDistance(event.target.value)
        }

        const handleDateChange = (event) => {
            if (event.target.value === "") {
                setRoadCompensationDateInvalid(true)
            } else {
                setRoadCompensationDateInvalid(false)
            }
            setRoadCompensationDate(event.target.value);
            setRoadCompensationDuration(calculateRoadTripDurationForDateChange(event.target.value, roadCompensationStartTime, roadCompensationEndTime));
        };

        const handleDateFromFilterChange = (event) => {
            setDateFromFilter(event.target.value);
        }

        const handleDateToFilterChange = (event) => {
            setDateToFilter(event.target.value);
        }

        const handleStartTimeChange = (event) => {
            if (event.target.value === "") {
                setRoadCompensationStartTimeInvalid(true)
            } else {
                setRoadCompensationStartTimeInvalid(false)
            }
            setRoadCompensationStartTime(event.target.value);
            setRoadCompensationDuration(calculateRoadTripDuration(event.target.value, roadCompensationEndTime));
        }

        const handleWorkReportTypeChange = (event) => {
            setWorkReportType(event.target.value);
        }

        const handleEndTimeChange = (event) => {
            if (event.target.value === "") {
                setRoadCompensationEndTimeInvalid(true)
            } else {
                setRoadCompensationEndTimeInvalid(false)
            }
            setRoadCompensationEndTime(event.target.value);
            setRoadCompensationDuration(calculateRoadTripDuration(roadCompensationStartTime, event.target.value));
        }

        const handleDescriptionChange = (event) => {
            if (event.target.value === "") {
                setRoadCompensationDescriptionInvalid(true)
            } else {
                setRoadCompensationDescriptionInvalid(false)
            }
            if ((transportType === "4" || transportType === 4)) {
                if (event.target.value.includes("Spolujezdec:")) {
                    setPassengerInvalid(false)
                } else {
                    setPassengerInvalid(true)
                }
            }
            setRoadCompensationDescription(event.target.value);
        }
        const [isSelectedCarInvalid, setIsSelectedCarInvalid] = useState(false);
        const handleCarIdChange = (event) => {
            if (transportType === "2" || transportType === 2) {
                console.log(event.target.value)
                const selectedCar = cars.find(car => car.id.toString() === event.target.value);
                console.log(cars)
                console.log(selectedCar)
                setIsSelectedCarInvalid(selectedCar
                    ? [selectedCar.crashInsurance, selectedCar.carInsurance, selectedCar.technicalCertificate, selectedCar.greenCard].some(field => field === null)
                    : false);
            } else {
                setIsSelectedCarInvalid(false)
            }
            setSelectedCarId(event.target.value);
        };

        const handleProjectIdChange = (event) => {
            if (event.value === "") {
                setSelectedProjectIdInvalid(true)
            } else {
                setSelectedProjectIdInvalid(false)
            }
            setSelectedProjectId(event.value);
        };

        function calculateHoursWorkedForEdit(startTime, endTime) {
            const start = new Date(`${startTime}`);
            const end = new Date(`${endTime}`);
            const result = (end - start) / (1000 * 60 * 60)
            if (isNaN(result) || result <= 0) {
                setRoadCompensationTimesRangeInvalid(true)
                return 0
            } else {
                const decimalPart = result % 1;
                const validEndings = [0.00, 0.25, 0.50, 0.75];

                if (!validEndings.includes(decimalPart)) {
                    setRoadCompensationTimesRangeInvalid(true);
                    return result.toFixed(2);
                } else {
                    setRoadCompensationTimesRangeInvalid(false);
                    return result.toFixed(2);
                }
            }
        }

        function calculateRoadTripDurationForDateChange(date, startTime, endTime) {
            const start = new Date(`${date}T${startTime}:00`);
            const end = new Date(`${date}T${endTime}:00`);
            const result = (end - start) / (1000 * 60 * 60)
            if (isNaN(result) || result <= 0) {
                setRoadCompensationTimesRangeInvalid(true)
                return 0
            } else {
                const decimalPart = result % 1;
                const validEndings = [0.00, 0.25, 0.50, 0.75];

                if (!validEndings.includes(decimalPart)) {
                    setRoadCompensationTimesRangeInvalid(true);
                    return result.toFixed(2);
                } else {
                    setRoadCompensationTimesRangeInvalid(false);
                    return result.toFixed(2);
                }
            }
        }

        function calculateRoadTripDuration(startTime, endTime) {
            const start = new Date(`${roadCompensationDate}T${startTime}:00`);
            const end = new Date(`${roadCompensationDate}T${endTime}:00`);
            const result = (end - start) / (1000 * 60 * 60)
            if (isNaN(result) || result <= 0) {
                setRoadCompensationTimesRangeInvalid(true)
                return 0
            } else {
                const decimalPart = result % 1;
                const validEndings = [0.00, 0.25, 0.50, 0.75];

                if (!validEndings.includes(decimalPart)) {
                    setRoadCompensationTimesRangeInvalid(true);
                    return result.toFixed(2);
                } else {
                    setRoadCompensationTimesRangeInvalid(false);
                    return result.toFixed(2);
                }
            }
        }

        const calculateHoursWorkedFromIso = (start, end) => {
            const startDate = new Date(start);
            const endDate = new Date(end);
            const differenceInMilliseconds = endDate - startDate;
            const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
            return differenceInHours.toFixed(2); // Returns the difference in hours, rounded to 2 decimal places
        };


        const [dateRange, setDateRange] = useState('');
        const [labels, setLabels] = useState({
            today: '',
            yesterday: '',
            thisWeek: '',
            lastWeek: '',
            thisMonth: '',
            lastMonth: '',
            custom: 'Volný výběr'
        });
        const generateLabels = () => {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            const thisWeekStart = new Date(today);
            thisWeekStart.setDate(today.getDate() - today.getDay() + 1);

            const lastWeekStart = new Date(thisWeekStart);
            lastWeekStart.setDate(thisWeekStart.getDate() - 7);

            const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

            const formatDate = (date) => date.toLocaleDateString('cs-CZ', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            });

            const newLabels = {
                today: `Dnes (${formatDate(today)})`,
                yesterday: `Včera (${formatDate(yesterday)})`,
                thisWeek: `Tento týden (${formatDate(thisWeekStart)} - ${formatDate(today)})`,
                lastWeek: `Minulý týden (${formatDate(lastWeekStart)} - ${formatDate(thisWeekStart)})`,
                thisMonth: `Tento měsíc (${formatDate(thisMonthStart)} - ${formatDate(today)})`,
                lastMonth: `Minulý měsíc (${formatDate(lastMonthStart)} - ${formatDate(lastMonthEnd)})`,
                custom: 'Volný výběr'
            };

            setLabels(newLabels);
            setDateRange(newLabels.thisMonth);
        };
        const handleRangeOfDatesChange = (event) => {
            const selectedRange = event.target.value;
            setDateRange(selectedRange);

            const today = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));
            const yesterday = new Date(today);
            yesterday.setUTCDate(today.getUTCDate() - 1);

            const thisWeekStart = new Date(today);
            thisWeekStart.setUTCDate(today.getUTCDate() - today.getUTCDay() + 1);

            const lastWeekStart = new Date(thisWeekStart);
            lastWeekStart.setUTCDate(thisWeekStart.getUTCDate() - 7);

            const thisMonthStart = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1));
            const lastMonthStart = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth()-1, 1));
            const lastMonthEnd = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 0))

            switch (selectedRange) {
                case labels.today:
                    setDateFromFilter(today.toISOString().split('T')[0]);
                    setDateToFilter(today.toISOString().split('T')[0]);
                    break;
                case labels.yesterday:
                    setDateFromFilter(yesterday.toISOString().split('T')[0]);
                    setDateToFilter(yesterday.toISOString().split('T')[0]);
                    break;
                case labels.thisWeek:
                    setDateFromFilter(thisWeekStart.toISOString().split('T')[0]);
                    setDateToFilter(today.toISOString().split('T')[0]);
                    break;
                case labels.lastWeek:
                    setDateFromFilter(lastWeekStart.toISOString().split('T')[0]);
                    setDateToFilter(thisWeekStart.toISOString().split('T')[0]);
                    break;
                case labels.thisMonth:
                    setDateFromFilter(thisMonthStart.toISOString().split('T')[0]);
                    setDateToFilter(today.toISOString().split('T')[0]);
                    break;
                case labels.lastMonth:
                    setDateFromFilter(lastMonthStart.toISOString().split('T')[0]);
                    setDateToFilter(lastMonthEnd.toISOString().split('T')[0]);
                    break;
                case labels.custom:
                    setDateFromFilter(null);
                    setDateToFilter(null);
                    break;
                default:
                    break;
            }
        };

        let request = "";
        const fetchProjects = () => {
            if (authority !== "HEADQUARTERS" && authority !== "HQ_WITH_REPORT") {
                request = "?regionalCenterId=" + RCid;
            } else {
                request = "";
            }
            fetch(process.env.REACT_APP_API_URL + "/projects" + request, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(jsonData => {
                    const projectsArray = jsonData.map(project => {
                        return {value: project.id, label: project.name + " (" + project.id + ")"}
                    })
                    setProjects(projectsArray);

                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };
        const fetchVillages = () => {
            if (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") {
                request = "/towns";
            } else {
                request = "/rc/" + RCid + "/towns";
            }
            if (localStorage.getItem("towns") === null) {
                fetch(process.env.REACT_APP_API_URL + request, {
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(jsonData => {
                        setVillages(jsonData);
                        localStorage.setItem("towns", JSON.stringify(jsonData))
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            } else {
                setVillages(JSON.parse(localStorage.getItem("towns")))
            }
        };

        const [sortDateOrder, setSortDateOrder] = useState('asc');

        const sortTimesheetsByDate = () => {
            if (roadCompensations) {
                let sortedTimesheets = [...filteredItems];
                sortedTimesheets.sort((a, b) => {
                    const dateA = new Date(a.workReport.start);
                    const dateB = new Date(b.workReport.start);
                    return sortDateOrder === 'asc' ? dateA - dateB : dateB - dateA;
                });
                setFilteredItems(sortedTimesheets);
            }
        };


        const [filtersByTimesheetType, setFiltersByTimesheetType] = useState({
            "1": true,
            "2": true,
            "3": true,
            "4": true,
            "5": true,
            "6": true,
            "7": true
        });

        const [deleteModalOpen, setDeleteModalOpen] = useState(false)

        function openDeleteModal(event) {
            setCurrentRoadCompensationId(event.target.value)
            setDeleteModalOpen(true)
        }

        function closeDeleteModal() {
            setCurrentRoadCompensationId(null)
            setDeleteModalOpen(false)
        }

        function deleteRoadCompensation() {
            fetch(process.env.REACT_APP_API_URL + `/workTrips/${currentRoadCompensationId}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                method: 'DELETE',
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    if (response.status === 204) {
                        fetchData()
                    } else {
                        return response.json().then(data => {
                            throw new Error(`Unexpected response: ${data.message || 'No message provided'}`);
                        });
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
            closeDeleteModal()
        }

        const handleCheckboxChange = (event) => {
            const {value, checked} = event.target;
            setFiltersByTimesheetType(prevFilters => ({
                ...prevFilters,
                [value]: checked
            }));
        };

    const calculateTotalWorkHours = () => {
        if (currentItems) {
            return currentItems.reduce((total, item) => {
                if (item.type !== -1) {
                    const startDate = new Date(item.workReport.start);
                    const endDate = new Date(item.workReport.end);
                    const startDay = startDate.getDay();
                    const endDay = endDate.getDay();

                    // Check if the start and end dates are not weekends (Saturday or Sunday)
                    if (startDay !== 0 && startDay !== 6 && endDay !== 0 && endDay !== 6) {
                        const hoursWorked = calculateHoursWorkedFromIso(item.workReport.start, item.workReport.end);
                        return total + parseFloat(hoursWorked);
                    }
                }
                return total;
            }, 0).toFixed(2);
        }
        return 0;
    };


        useEffect(() => {
            sortTimesheetsByDate();
            // eslint-disable-next-line no-use-before-define
        }, [sortDateOrder]);

        const filterItemsByType = () => {
            if (roadCompensations) {
                const filtered = roadCompensations.filter(item => filtersByTimesheetType[item.type.toString()]);

                setFilteredItems(filtered);
            }
        };

        useEffect(() => {
            filterItems();
        }, [roadCompensations, dateFromFilter, dateToFilter, filtersByTimesheetType]);

        const [whatToDisplay, setWhatToDisplay] = useState("Správa cest");

        const [pageView, setPageView] = useState("Cesta");
        const setTypeOfView = (event) => {
            setWhatToDisplay(event.detail.label)
            fetchData()
        }

        const handleSettingPageView = (event) => {
            setPageView(event.detail.label)
        }

        const [monthRoadCompensationReports, setMonthRoadCompensationReports] = useState([])

        function fetchMonthRoadCompensationReports() {
            let request = userId ? `?userId=${userId}` : `?userId=${localStorage.getItem("userId")}`;
            fetch(process.env.REACT_APP_API_URL + `/monthReports/tripsReports${request}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                }).then(jsonData => {
                setMonthRoadCompensationReports(jsonData)
            })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }

        useEffect(() => {
            generateLabels();
            fetchData()
            fetchMonthRoadCompensationReports()
            fetchVillages()
        }, []);

        useEffect(() => {
            fetchData()
        }, [modalTimesheetOpen]);

        function postMonthRoadCompensationReport() {
            fetch(process.env.REACT_APP_API_URL + "/monthReports/tripsReports", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "date": parseIsoToYearMonth(dateFromFilter)
                })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(() => {
                    fetchData()
                    fetchMonthRoadCompensationReports()
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                })
                .catch(error => {
                    return error.message;
                });

            closeEditModal()
        }

        const navigate = useNavigate();

        function goToCarAddPage() {
            navigate("/profile/cars/add")
        }

        function goToCarDetailPage(id) {
            navigate(`/profile/cars/${id}`)
        }

        const [currentRoadCompensationReport, setCurrentRoadCompensationReport] = useState(monthRoadCompensationReports.find(report => report.date === parseIsoToYearMonth(dateFromFilter)))

        useEffect(() => {
            setCurrentRoadCompensationReport(monthRoadCompensationReports.find(report => report.date === parseIsoToYearMonth(dateFromFilter)));
        }, [dateFromFilter, monthRoadCompensationReports]);


        const [roadCompensationViewModalOpen, setRoadCompensationViewModalOpen] = useState(false)

        function openRoadCompensationViewModal(event) {
            setCurrentRoadCompensationId(event.target.value)
            setRoadCompensationViewModalOpen(true)
        }

        function closeRoadCompensationViewModal() {
            setRoadCompensationViewModalOpen(false)
        }

        const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const isWithinLastFiveDays = (lastDayOfMonth - today) / (1000 * 60 * 60 * 24) <= 5;

    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    return (
        <div className="">
            <Navigation active={"road-compensation"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Timesheet</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container vh-80">
                    <div className="gov-container__content">
                        <div className="gov-title--beta">Správa cestovních náhrad</div>
                        <GovTabs onGov-change={setTypeOfView} type="chip" variant="primary"
                                 wcag-label="Basic information about the gov.cz design system">
                            <GovTabsItem label="Správa cest"></GovTabsItem>
                            <GovTabsItem label="Přehled cestovních náhrad"/>
                        </GovTabs>
                        {whatToDisplay === "Správa cest" &&
                            <div>
                                <div className="gov-flexbox gov-flexbox--justify-start align-items-normal">
                                    <div>

                                            <GovFormControl>
                                                <GovFormSelect size="s" variant="secondary" value={dateRange}
                                                               onGov-change={handleRangeOfDatesChange}>
                                                    {Object.entries(labels).map(([key, value]) => (
                                                        <option key={key} value={value}>{value}</option>
                                                    ))}
                                                </GovFormSelect>
                                                <GovFormLabel slot="top" size="s">Období k zobrazení*</GovFormLabel>
                                            </GovFormControl>
                                            {dateRange === labels.custom &&
                                                <div className="gov-flexbox gov-flexbox--justify-start">
                                                    <GovFormControl>
                                                        <GovFormLabel slot="top" size="s">Datum od</GovFormLabel>
                                                        <GovFormInput
                                                            size="s" variant="secondary"
                                                            input-type="date"
                                                            value={dateFromFilter}
                                                            onGov-input={handleDateFromFilterChange}>
                                                        </GovFormInput>
                                                    </GovFormControl>
                                                    <GovFormControl className="ml-5">
                                                        <GovFormLabel slot="top" size="s">Datum do</GovFormLabel>
                                                        <GovFormInput
                                                            size="s" variant="secondary"
                                                            input-type="date"
                                                            value={dateToFilter}
                                                            onGov-input={handleDateToFilterChange}>
                                                        </GovFormInput>
                                                    </GovFormControl>
                                                </div>
                                            }
                                        </div>
                                        <div className="ml-30">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <th>Celková doba na cestách:</th>
                                                    <td>{calculateTotalWorkHours()} hodin</td>
                                                </tr>
                                                <tr>
                                                    <th>Počet aktivit:</th>
                                                    <td>{currentItems ? currentItems.length : 0}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                    <div className="gov-flexbox gov-flexbox--justify-start">

                                        <div>
                                            {(!currentRoadCompensationReport && !userId) &&
                                                <div>
                                                    <GovButton className=" gov-flexbox--justify-center"
                                                               wcag-label="What exactly happens when you click?"
                                                               variant="primary"
                                                               type="solid"
                                                               onGov-click={() => {
                                                                   openModalTimesheet()
                                                               }} size="m">Přidat cestu
                                                    </GovButton>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            {(dateRange === labels.lastMonth && !currentRoadCompensationReport) &&
                                                <GovButton className="ml-5 gov-flexbox--justify-center"
                                                           wcag-label="What exactly happens when you click?"
                                                           variant="primary"
                                                           type="solid"
                                                           onGov-click={() => {
                                                               postMonthRoadCompensationReport()
                                                           }} size="m">Uzavřít cestovní náhrady
                                                </GovButton>}
                                        {(dateRange === labels.thisMonth && roadCompensations && !currentRoadCompensationReport && isWithinLastFiveDays) &&
                                            <GovButton className="ml-5 gov-flexbox--justify-center"
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="solid"
                                                       onGov-click={() => {
                                                           postMonthRoadCompensationReport()
                                                           setCreateTimesheet(true)
                                                       }} size="m">Uzavřít cestovní náhrady
                                            </GovButton>}

                                            {(currentRoadCompensationReport && (currentRoadCompensationReport.state === "CREATED")) &&
                                                <div>Tyto cestovní náhrady se aktuálně schvalují.</div>
                                            }
                                            {(currentRoadCompensationReport && (currentRoadCompensationReport.state === "APPROVED_BY_SUPERVISOR")) &&
                                                <div style={{color: 'green'}}>Tyto cestovní náhrady byly schváleny
                                                    vedoucím
                                                    regionálního
                                                    centra
                                                    dne {convertIsoToDateFormat(currentRoadCompensationReport.supervisorChangeOn)}.</div>
                                            }
                                            {(currentRoadCompensationReport && currentRoadCompensationReport.state === "APPROVED_BY_HQ") &&
                                                <div style={{color: 'green'}}>Tyto cestovní náhrady byly schváleny
                                                    centrálou.
                                                    dne {convertIsoToDateFormat(currentRoadCompensationReport.hqChangeOn)}</div>
                                            }
                                            {(currentRoadCompensationReport && currentRoadCompensationReport.state === "REJECTED_BY_HQ") &&
                                                <div><span style={{color: 'red'}}>Tyto cestovní náhrady byly zamítnuty centrálou.</span>
                                                    <p>Důvod
                                                        zamítnutí: {currentRoadCompensationReport.note}</p>
                                                </div>
                                            }
                                            {(authority === "RC_LEADER" && currentRoadCompensationReport && currentRoadCompensationReport.state === "REJECTED_BY_HQ") &&
                                                <div>
                                                    <div>
                                                        <GovButton className=" gov-flexbox--justify-center"
                                                                   wcag-label="What exactly happens when you click?"
                                                                   variant="primary"
                                                                   type="solid"
                                                                   onGov-click={() => {
                                                                       openModalTimesheet()
                                                                   }} size="m">Přidat cestu
                                                        </GovButton>
                                                        <GovButton className="gov-flexbox--justify-center"
                                                                   wcag-label="What exactly happens when you click?"
                                                                   variant="primary"
                                                                   type="solid"
                                                                   onGov-click={() => {
                                                                       postMonthRoadCompensationReport()
                                                                       setCreateTimesheet(true)
                                                                   }} size="m">Uzavřít cestovní náhrady znovu
                                                        </GovButton>
                                                    </div>
                                                </div>
                                            }

                                            {(currentRoadCompensationReport && currentRoadCompensationReport.state === "REJECTED_BY_SUPERVISOR") &&
                                                <div>
                                                    <div><span style={{color: 'red'}}>Tyto cestovní náhrady byly zamítnuty vedoucím regionálního centra.</span>
                                                        <p>Důvod
                                                            zamítnutí: {currentRoadCompensationReport.note}</p>
                                                    </div>
                                                    <div>
                                                        <GovButton className=" gov-flexbox--justify-center"
                                                                   wcag-label="What exactly happens when you click?"
                                                                   variant="primary"
                                                                   type="solid"
                                                                   onGov-click={() => {
                                                                       openModalTimesheet()
                                                                   }} size="m">Přidat cestu
                                                        </GovButton>
                                                        <GovButton className="gov-flexbox--justify-center"
                                                                   wcag-label="What exactly happens when you click?"
                                                                   variant="primary"
                                                                   type="solid"
                                                                   onGov-click={() => {
                                                                       postMonthRoadCompensationReport()
                                                                       setCreateTimesheet(true)
                                                                   }} size="m">Uzavřít cestovní náhrady znovu
                                                        </GovButton>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <GovSpacer size="xl"></GovSpacer>
                                    {currentItems ?
                                        <table className="fixed" cellSpacing="0" cellPadding="0" width="100%">
                                            <thead>
                                            <tr>
                                                <th className="w-110px">
                                                    Datum
                                                    {sortDateOrder === "asc" ?
                                                        <GovButton onGov-click={() => setSortDateOrder('desc')}
                                                                   wcag-label="What exactly happens when you click?"
                                                                   variant="primary"
                                                                   type="link" size="xs" loading="false">

                                                            <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                                     slot="left-icon"></GovIcon> </GovButton>
                                                        :
                                                        <GovButton onGov-click={() => setSortDateOrder('asc')}
                                                                   wcag-label="What exactly happens when you click?"
                                                                   variant="primary"
                                                                   type="link" size="xs" loading="false">

                                                            <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                                     slot="left-icon"></GovIcon> </GovButton>
                                                    }
                                                </th>
                                                <th className="w-110px">Začátek</th>
                                                <th className="w-110px">Konec</th>
                                                <th className="w-120px">Délka cesty</th>
                                                <th className="w-150px">
                                                    <div className="gov-flexbox--justify-start">
                                                        <GovDropdown position="left" className="min-height-400">
                                                            <GovButton className="padding-0" variant="primary" size="xs"
                                                                       type="base"
                                                                       wcag-label="What exactly happens when you click?"
                                                                       right-icon="basic/chevron-down">Upřesňující typ
                                                                <GovIcon type="basic" name="chevron-down"
                                                                         slot="right-icon"></GovIcon>
                                                            </GovButton>
                                                            <ul slot="list">
                                                                <li>
                                                                    <GovButton variant="secondary" size="xs" type="base"
                                                                               wcag-label="Home">
                                                                        <GovFormCheckbox size="xs" value="1"
                                                                                         onGov-change={handleCheckboxChange}
                                                                                         checked={filtersByTimesheetType["1"]}>
                                                                            <GovFormLabel size="xs" slot="label">Administrativa
                                                                            </GovFormLabel>
                                                                        </GovFormCheckbox>
                                                                    </GovButton>
                                                                </li>
                                                                <li>
                                                                    <GovButton variant="secondary" size="xs" type="base"
                                                                               wcag-label="Home">
                                                                        <GovFormCheckbox size="xs" value="2"
                                                                                         onGov-change={handleCheckboxChange}
                                                                                         checked={filtersByTimesheetType["2"]}>
                                                                            <GovFormLabel size="xs" slot="label">Obec
                                                                            </GovFormLabel>
                                                                        </GovFormCheckbox>
                                                                    </GovButton>
                                                                </li>
                                                                <li>
                                                                    <GovButton variant="secondary" size="xs" type="base"
                                                                               wcag-label="Home">
                                                                        <GovFormCheckbox size="xs" value="3"
                                                                                         onGov-change={handleCheckboxChange}
                                                                                         checked={filtersByTimesheetType["3"]}>
                                                                            <GovFormLabel size="xs" slot="label">Projekt
                                                                            </GovFormLabel>
                                                                        </GovFormCheckbox>
                                                                    </GovButton>
                                                                </li>
                                                                <li>
                                                                    <GovButton variant="secondary" size="xs" type="base"
                                                                               wcag-label="Home">
                                                                        <GovFormCheckbox size="xs" value="7"
                                                                                         onGov-change={handleCheckboxChange}
                                                                                         checked={filtersByTimesheetType["7"]}>
                                                                            <GovFormLabel size="xs" slot="label">Konzultace
                                                                            </GovFormLabel>
                                                                        </GovFormCheckbox>
                                                                    </GovButton>
                                                                </li>
                                                                <li>
                                                                    <GovButton variant="secondary" size="xs" type="base"
                                                                               wcag-label="Home">
                                                                        <GovFormCheckbox size="xs" value="4"
                                                                                         onGov-change={handleCheckboxChange}
                                                                                         checked={filtersByTimesheetType["4"]}>
                                                                            <GovFormLabel size="xs" slot="label">Konzultace
                                                                                MMR
                                                                            </GovFormLabel>
                                                                        </GovFormCheckbox>
                                                                    </GovButton>
                                                                </li>
                                                                <li>
                                                                    <GovButton variant="secondary" size="xs" type="base"
                                                                               wcag-label="Home">
                                                                        <GovFormCheckbox size="xs" value="5"
                                                                                         onGov-change={handleCheckboxChange}
                                                                                         checked={filtersByTimesheetType["5"]}>
                                                                            <GovFormLabel size="xs" slot="label">Školení
                                                                            </GovFormLabel>
                                                                        </GovFormCheckbox>
                                                                    </GovButton>
                                                                </li>
                                                                <li>
                                                                    <GovButton variant="secondary" size="xs" type="base"
                                                                               wcag-label="Home">
                                                                        <GovFormCheckbox size="xs" value="6"
                                                                                         onGov-change={handleCheckboxChange}
                                                                                         checked={filtersByTimesheetType["6"]}>
                                                                            <GovFormLabel size="xs" slot="label">Cesta
                                                                            </GovFormLabel>
                                                                        </GovFormCheckbox>
                                                                    </GovButton>
                                                                </li>
                                                            </ul>
                                                        </GovDropdown>
                                                    </div>
                                                </th>
                                                <th className="w-350px">
                                                    Důvod/program cesty
                                                </th>
                                                <th>
                                                    Akce
                                                </th>

                                            </tr>
                                            </thead>

                                            <tbody>
                                            {currentItems.map((row, index) => (
                                                <tr key={index}>
                                                    <td>{convertIsoToDateFormat(row.workReport.start)}</td>
                                                    <td>{convertIsoToTime(row.workReport.start)}</td>
                                                    <td>{convertIsoToTime(row.workReport.end)}</td>
                                                    <td>{(calculateHoursWorkedFromIso(row.workReport.start, row.workReport.end))}</td>
                                                    <td>{(mapTimeSheetType(row.workReport.type))}</td>
                                                    <td>{row.description}</td>
                                                    <td>
                                                        <GovButton variant="primary" type="link" size="s"
                                                                   value={row.id}
                                                                   onGov-click={openRoadCompensationViewModal}>
                                                            <GovIcon type="basic" name="eye"
                                                                     slot="left-icon"></GovIcon>
                                                        </GovButton>
                                                        {((authority === "RC_EMPLOYEE" && row.tripMonthReportState === "REJECTED_BY_SUPERVISOR")
                                                            || (row.tripMonthReportState === null)
                                                            || (authority === "RC_LEADER" && (row.tripMonthReportState === "CREATED" || row.tripMonthReportState === "REJECTED_BY_HQ"))
                                                            || (row.tripMonthReportState !== "APPROVED_BY_HQ" && (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT"))
                                                            || ((authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") && (currentDay <= 10 || currentDay >= 25))) && (
                                                            <>
                                                                <GovButton className="ml-5"
                                                                           variant="primary" type="link" size="s"
                                                                           value={row.id}
                                                                           onGov-click={openEditRoadCompensationModal}>
                                                                    <GovIcon type="basic" name="pencil-square"
                                                                             slot="left-icon"></GovIcon>
                                                                </GovButton>
                                                                <GovButton className="ml-5"
                                                                           value={row.id}
                                                                           wcag-label="What exactly happens when you click?"
                                                                           variant="error" type="link" size="s"
                                                                           onGov-click={openDeleteModal}>
                                                                    <GovIcon type="basic" name="trash"
                                                                             slot="left-icon"></GovIcon>
                                                                </GovButton>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        : (
                                            <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                                         shape="text"
                                                         animation="progress" count="1" width="300px"
                                                         height="30px"></GovSkeleton>
                                        )}
                                </div>
                            }
                            {whatToDisplay === "Přehled cestovních náhrad" &&
                                <div>
                                    <div>
                                        <table className="gov-table">
                                            <thead>
                                            <tr>
                                                <th>Měsíc</th>
                                                <th>Stav</th>
                                                <th>Datum poslední změny</th>
                                                <th>Poznámka</th>
                                                <th>Detail</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {monthRoadCompensationReports.map((roadCompensationReport, index) => (
                                                <tr key={index}>
                                                    <td>{mapDateFormat(roadCompensationReport.date)}</td>
                                                    <td style={{color: roadCompensationReport.state.includes('APPROVED') ? 'green' : roadCompensationReport.state.includes('REJECTED') && 'red'}}>
                                                        {approvalStateToText(roadCompensationReport.state)}
                                                    </td>
                                                    <td>
                                                        {roadCompensationReport.state.includes('APPROVED_BY_HQ') ? convertIsoToDateFormat(roadCompensationReport.hqChangeOn ? roadCompensationReport.hqChangeOn : roadCompensationReport.supervisorChangeOn) :
                                                            roadCompensationReport.state.includes('APPROVED_BY_SUPERVISOR') ? convertIsoToDateFormat(roadCompensationReport.supervisorChangeOn) :
                                                                roadCompensationReport.state.includes('REJECTED_BY_HQ') ? convertIsoToDateFormat(roadCompensationReport.hqChangeOn) :
                                                                    roadCompensationReport.state.includes('REJECTED_BY_SUPERVISOR') ? convertIsoToDateFormat(roadCompensationReport.supervisorChangeOn) :
                                                                        ""}
                                                    </td>
                                                    <td>{roadCompensationReport.state !== "CREATED" && roadCompensationReport.note}</td>
                                                    <td><a
                                                        href={`/road-compensations/month-report/${roadCompensationReport.id}`}>Zobrazit
                                                        podrobnosti</a></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <GovPrompt open={deleteModalOpen} onGov-close={closeDeleteModal}
                           label="Smazat cestovní náhradu" role="dialog" label-tag="h5"
                           wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu
                    chcete smazat tuto cestovní náhradu?<br/>Tato operace je nevratná.
                    <GovButton onGov-click={closeDeleteModal} variant="primary" size="m"
                               type="base" slot="actions"
                               wcag-label="Cancel delete file my-photo.jpg">Zrušit
                    </GovButton>
                    <GovButton variant="error" size="m" type="solid"
                               slot="actions"
                               wcag-label="Potvrdit smazání"
                               onGov-click={deleteRoadCompensation}>Smazat
                    </GovButton>
                </GovPrompt>
                {modalTimesheetOpen &&
                    <RoadCompensationModal create={createTimesheet} setCreateTimesheet={changeCreateTimesheet}
                                           id={currentRoadCompensationId} roadCompensations={roadCompensations}
                                           onGovModalClose={closeEditModal}></RoadCompensationModal>
                }
                {roadCompensationViewModalOpen &&
                    <TripReportDetailModal roadTripId={currentRoadCompensationId}
                                           onGovModalClose={closeRoadCompensationViewModal}></TripReportDetailModal>
                }
            </div>
        )
    }
