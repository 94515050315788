import {
    GovBreadcrumbs,
    GovButton,
    GovFormControl,
    GovFormGroup,
    GovFormInput, GovFormLabel, GovFormMessage,
    GovFormPasswordPower, GovIcon,
    GovSpacer
} from "@gov-design-system-ce/react";
import Navigation from "../Navigation";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export function LoginContent() {
    let navigate = useNavigate();
    let jwt = null

    const [invalidCredentials, setInvalidCredentials] = useState('')
    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleChangePassword = (event) => {
        setPassword(event.target.value)
    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validatePassword(password) {
        // Password should be at least 10 characters long, contain at least one number, one lowercase and one uppercase letter
        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(String(password));
    }

    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    function validateLoginForm(email, password) {
        let isValid = true;
        if (!validateEmail(email)) {
            setInvalidCredentials(true)
            isValid = false;
        } else {
            setEmailError("");
        }
        if (!validatePassword(password)) {
            setInvalidCredentials(true)
            isValid = false;
        } else {
            setPasswordError("");
        }
        return isValid;
    }

    function loginUser() {
        if (validateLoginForm(email, password)) {
            fetch(process.env.REACT_APP_API_URL + "/login", {
                method: 'POST', // Specify the method
                headers: {
                    'Content-Type': 'application/json',
                    // Include other headers here if needed
                },
                body: JSON.stringify({
                        email: email,
                        password: password
                    }
                ),
            })
                .then(response => {
                    if (!response.ok) {
                        setInvalidCredentials(true);
                    }
                    return response.json();
                })// Convert the response to JSON
                .then(data => {
                    jwt = data.token

                    if (jwt) {
                        localStorage.setItem('jwt', jwt);
                        localStorage.setItem('user', JSON.stringify(data.user))
                        localStorage.setItem('firstname', data.user.firstname)
                        localStorage.setItem('lastname', data.user.lastname)
                        localStorage.setItem('RCid', data.user.regionalCenter.id)
                        localStorage.setItem('workAgreementTypeId', data.user.workAgreementTypeId)
                        localStorage.setItem('workAllocation', data.user.workAllocation)
                        localStorage.setItem('RCname', data.user.regionalCenter.name)
                        localStorage.setItem('userId', data.user.id);
                        console.log(data.user.role)
                        if(data.user.role === "MMR"){
                            navigate("/mmr-export")
                        }
                        else{
                            navigate("/")
                        }
                    } else {
                    }
                })
                .catch(error => {
                    console.error('Error:', error); // Handle any errors
                });


        }
    }

    const handleKeyDown = (event) => {
        if (event && event.detail.originalEvent && event.detail.originalEvent.key === 'Enter') {
            loginUser();
        }
    }


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    return (
        <div>
            <Navigation />
            <div className="gov-container">
                    <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                        <ul>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <a href="/">Úvod</a></li>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <strong>Přihlášení</strong>
                            </li>
                        </ul>
                    </GovBreadcrumbs>
                    <div className="gov-container mb-30">
                        <div className="gov-container__content">
                            <div className="gov-title--beta">Přihlášení</div>

                            <form className="gov-form" onKeyDown={handleKeyDown}>
                                <GovFormControl>
                                    <GovFormInput value={email} onGov-input={handleChangeEmail} onGov-keydown={handleKeyDown}
                                                  size="s" variant="secondary" input-type="email"
                                                  placeholder="Email"></GovFormInput>
                                    <GovFormLabel slot="top" size="s">Email*</GovFormLabel>
                                    {emailError && <span className="error-text">{emailError}</span>}
                                </GovFormControl>
                                <GovSpacer size="l"></GovSpacer>
                                <GovFormControl>
                                    <GovFormInput value={password} size="s" variant="secondary"
                                                  onGov-input={handleChangePassword} onGov-keydown={handleKeyDown}
                                                  input-type="password" placeholder="Heslo">
                                        <p className="gov-text--m"
                                           slot="sufix">
                                            <a className="lost-password" href="/passwordreset">Zapomenuté heslo?</a>
                                        </p>
                                    </GovFormInput>
                                    <GovFormLabel slot="top" size="s">Heslo*</GovFormLabel>
                                    {passwordError && <span className="error-text">{passwordError}</span>}
                                </GovFormControl>
                                <GovButton onGov-click={loginUser} wcag-label="What exactly happens when you click?"
                                           variant="primary" type="solid" size="m">Přihlásit
                                    se
                                </GovButton>
                                <GovSpacer size="m"></GovSpacer>

                                {invalidCredentials &&
                                    <p className="gov-form-undertext error-text">Chybně zadané uživatelské údaje</p>}
                            </form>

                        </div>
                    </div>
            </div>
        </div>
    )
}
