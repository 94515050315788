import {
    GovButton, GovFormCheckbox,
    GovFormControl,
    GovFormFile,
    GovFormInput,
    GovFormLabel, GovIcon, GovMessage,
    GovSpacer, GovToast
} from "@gov-design-system-ce/react";
import form from "../assets/forms/RC-Poptavkovy_formular-2025-01-VZOR.xlsx";
import {useEffect, useRef, useState} from "react";
import {parseJwt} from "../utils/utils";
import {useNavigate} from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha'

export function MMRExportContent() {
    const jwt = localStorage.getItem("jwt");
    const [requestSuccess, setRequestSuccess] = useState(false);
    const user = parseJwt(localStorage.getItem("jwt"))
    let role
    const navigate = useNavigate()
    if (user !== null) {
        role = user.role[0].authority
    }

    function goToLogin() {
        navigate("/login")
    }


    const handleSubmit = (event) => {
        fetch(process.env.REACT_APP_API_URL + "/report/mmr/request", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
        })
            .then(data => {
                    console.log(data)
                    setRequestSuccess(true)
                }
            )
            .catch(error => console.error('Error:', error));
    }

    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    }
    const [disclaimerConfirmed, setDisclaimerConfirmed] = useState(false);
    const handleDisclaimerConfirmedChange = () => {
        setDisclaimerConfirmed(!disclaimerConfirmed);
    }
    const [allowedToDownload, setAllowedToDownload] = useState(false);

    useEffect(() => {
        setAllowedToDownload(disclaimerConfirmed && recaptchaValue);
    }, [disclaimerConfirmed, recaptchaValue]);

    return (

        <div>
            {(role === "HQ_WITH_REPORT" || role==="MMR") ?
                <div>
                    <div className="gov-container">
                        <div className="gov-container__content vh-80">
                            <form>
                                <ReCAPTCHA hl="cs" sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleRecaptchaChange}/>
                                {!recaptchaValue &&
                                    <GovMessage className="mt-10" variant="error">
                                        <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                        <p>reCAPTCHA není splněna</p>
                                    </GovMessage>
                                }
                                {recaptchaValue &&
                                    <div className="mt-20">
                                        <ol>
                                            <li>Datová sada, kterou chcete v následném kroku vygenerovat je v režimu
                                                "důvěrné" a je poskytována na základě Dohody o mlčenlivosti ze
                                                dne 06.01.2025 (Smlouva) uzavřené mezi Ministerstvem pro místní rozvoj ČR (MMR) a
                                                Státním fondem podpory investic (SFPI). Datová sada není určena k
                                                poskytování třetím osobám mimo seznam Vyjmenovaných zaměstnanců MMR
                                                uvedených ve Smlouvě nebo není-li mezi MMR a SFPI výslovně sjednáno
                                                jinak.
                                            </li>

                                            <li>SFPI neručí za správnost vyplněných dat, nemusí být vyplněny všechny
                                                datové položky, příjemce vygenerováním sady dat uvedené akceptuje.
                                            </li>
                                            <li>Charakter dat v datové sadě je dynamický a mění se v režimu online.</li>
                                            <li>Žadatelé o fin.nástroj 2.10 poskytují SFPI data na základě deklarované
                                                důvěrnosti a příjemce datové sady se zavazuje tuto důvěrnost dodržovat.
                                            </li>
                                            <li>Jediným kontaktem pro případné dotazy je <a href="mailto:kadlec.stanislav@sfpi.cz">kadlec.stanislav@sfpi.cz</a>
                                            </li>
                                            <li>V případě dotazů příjemce k datům povede Centrála regionálních center  podpory investic do bydlení SFPI (CRC)
                                                seznam bodů k řešení (dále také „Issue log“), v němž bude CRC dotazy monitorovat, přidělovat
                                                případnou kapacitu, navrhovat řešení, odmítat a určovat případný termín
                                                ke splnění. Formulář k zadání požadavku ke stažení <a href={form}
                                                                                                      download>zde</a>.
                                                Formulář zasílejte na email <a
                                                    href="mailto:kadlec.stanislav@sfpi.cz">kadlec.stanislav@sfpi.cz</a>.
                                            </li>
                                            <li>Data, která si stahujete:
                                                <ul>
                                                    <li>Jsou výsledkem sběru dat Regionálních center podpory investic do
                                                        bydlení SFPI v režimu obchodní tajemství.
                                                    </li>
                                                    <li>Zahrnují projekty, které odpovídají definici v komponentě 2.10
                                                        NPO, tj. projekty dostupného nájemního bydlení.“
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                        <GovFormControl>
                                            <GovFormCheckbox checked={disclaimerConfirmed} size="s"
                                                             onGov-change={handleDisclaimerConfirmedChange}>
                                                <GovFormLabel size="s" slot="label">Souhlasím s uvedenými podmínkami a
                                                    chci pokračovat ke stažení datové sady v režimu "Důvěrné".</GovFormLabel>
                                        </GovFormCheckbox>
                                    </GovFormControl>
                                    </div>

                                }
                                <GovButton onGov-click={handleSubmit} wcag-label="What exactly happens when you click?"
                                           variant="primary" type="solid"
                                           disabled={(!allowedToDownload)}
                                           class="mt-10"
                                           size="m">Vyžádat export dat
                                </GovButton>
                            </form>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className="gov-container">
                        <div className="gov-container__content vh-80">
                            <h3>Pro tuto operaci nemáte dostatečná oprávnění.</h3>
                            <GovButton onGov-click={goToLogin}
                                       wcag-label="What exactly happens when you click?"
                                       variant="primary" type="solid"
                                       size="m">Přejít na přihlašovací stránku
                            </GovButton>
                        </div>
                    </div>
                </div>
            }
            <div>
                {requestSuccess &&
                    <GovToast variant="success" type="classic" gravity="bottom" position="center" time="10000"
                              wcag-close-label="Close message">Žádost o export dat byla úspěšně provedena.
                        Soubor s exportem dat Vám bude zaslán emailem.
                        <GovIcon type="basic" name="check-lg" slot="icon"></GovIcon>
                    </GovToast>

                }
            </div>
        </div>

    )

}
