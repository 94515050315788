import Navigation from "../Navigation";
import {
    GovBreadcrumbs,
    GovButton,
    GovFormAutocomplete,
    GovFormControl, GovFormGroup, GovFormInput, GovFormLabel, GovFormMultiSelect, GovFormRadio, GovFormSelect,
    GovIcon, GovInfobar, GovMessage, GovModal, GovPagination, GovSkeleton,
    GovSpacer, GovTabs, GovTabsItem, GovToast, GovTooltip
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import '../css/manage-users.css';
import {parseJwt} from "../utils/utils";
import {json} from "react-router-dom";

export function ManageUsersContent({user}) {

    const jwt = localStorage.getItem("jwt")
    let [persons, setPersonArray]=useState(null)
    const [editModalOpen, setEditModalOpen] = useState(false);

    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");

    const translateEnumToRole = (role) => {
        // eslint-disable-next-line default-case
        switch (role) {
            case "STATUTORY_DEPUTY":
                return "Statutární zástupce"
            case "DELEGATE":
                return "Zmocněnec"
            case "OBSERVER":
                return "Nahlížející osoba"
            case "CONTACT_PERSON":
                return "Kontaktní osoba"
            case "OTHER":
                return "Jiná"
            case "RC_EMPLOYEE":
                return "Specialista RC"
            case "RC_LEADER":
                return "Manažer RC"
            case "EXPERT":
                return "Expert"
            case "HEADQUARTERS":
                return "Centrála"
            case "HQ_WITH_REPORT":
                return "Centrála+"
            case "MMR":
                return "MMR"
            case "NON_ACTIVE":
                return "Neověřený uživatel"
        }
    }

    const translateRoleToEnum = (roleName) => {
        // eslint-disable-next-line default-case
        switch (roleName) {
            case "Statutární zástupce":
                return "STATUTORY_DEPUTY";
            case "Zmocněnec":
                return "DELEGATE";
            case "Nahlížející osoba":
                return "OBSERVER";
            case "Anonymní uživatel":
                return "ANONYMOUS";
            case "Specialista RC":
                return "RC_EMPLOYEE";
            case "Manažer RC":
                return "RC_LEADER";
            case "Expert":
                return "EXPERT";
            case "Centrála":
                return "HEADQUARTERS";
            case "Centrála+":
                return "HQ_WITH_REPORT"
            case "Neověřený uživatel":
                return "NON_ACTIVE";
            default:
                return null; // Neznámá role
        }
    };

    const allRegionalCenters = [
        "Moravskoslezský kraj",
        "Středočeský kraj a Praha",
        "Ústecký a Karlovarský kraj",
        "Královehradecký a Liberecký kraj",
        "Pardubický kraj a Kraj Vysočina",
        "Jihočeský a Plzeňský kraj",
        "Zlínský a Jihomoravský kraj",
        "Olomoucký kraj",
        "Ministerstvo pro místní rozvoj ČR"
    ];


    const rolesOfExternalPersons = [
        "Statutární zástupce",
        "Zmocněnec",
        "Nahlížející osoba",
        "Kontaktní osoba",
    ]

    const rolesOfEmployees = [
        "MMR",
        "Specialista RC",
        "Manažer RC",
        "Centrála"
    ]

    const allInstitutions = [
        "CTU FEE",
        "SFPI"
    ]

    const [userType, setUserType] = useState("Regionální centra");
    let request;
    if ((authority === 'HEADQUARTERS' || authority === 'HQ_WITH_REPORT')) {
        if (userType === "Regionální centra") {
            request = "/users";
        } else {
            request = "/externalPersons";
        }
    } else {
        if (userType === "Regionální centra") {
            request = "/rc/"+RCid+"/employees";
        } else {
            request = "/externalPersons";
        }
    }

    const fetchData = () => {
        fetch(process.env.REACT_APP_API_URL + request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setFilteredItems(jsonData);
                setPersonArray(jsonData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    function getRegionalCenterByRegionName(regionName){
        if (regionName === "Hlavní město Praha") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Moravskoslezský kraj") {
            return "Moravskoslezský kraj"
        } else if (regionName === "Olomoucký kraj") {
            return "Olomoucký kraj"
        } else if (regionName === "Pardubický kraj") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Kraj Vysočina") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Zlínský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Královéhradecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Ústecký kraj") {
            return "Ústecký a Karlovarský kraj"
        } else if (regionName === "Plzeňský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Liberecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Jihomoravský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Jihočeský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Středočeský kraj") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Karlovarský kraj") {
            return "Ústecký a Karlovarský kraj"
        }
    }

    const [personName, setPersonName] = useState('');
    const [personSurname, setPersonSurname] = useState('');
    const [personRole, setPersonRole] = useState('');
    const [personTelephone, setPersonTelephone] = useState('');
    const [personEmail, setPersonEmail] = useState('');
    const [personOrganization, setPersonOrganization] = useState('');
    const [personRoleDescription, setPersonRoleDescription] = useState('');
    const [personRegionalCentreId, setPersonRegionalCentreId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [letterChosen, setLetterChosen] = useState(false);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [currentId, setCurrentId] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(null);
    const [editModalEmployeeOpen, setEditModalEmployeeOpen] = useState(false);
    const [editModalExternalPerson, setEditModalExternalPerson] = useState(false);
    const [createEmployee, setCreateEmployee] = useState(false);
    const [createExternalPerson, setCreateExternalPerson] = useState(false);
    const [employeeId, setEmployeeId] = useState();
    const [selectedCategoriesEmpty, setSelectedCategoriesEmpty] = useState(true);
    const [employmentRate, setEmploymentRate] = useState(null);
    const [employmentType, setEmploymentType] = useState("");
    const [employeeAddress, setEmployeeAddress] = useState(null);
    const [workContractFromDate, setWorkContractFromDate] = useState("");
    const [workContractToDate, setWorkContractToDate] = useState("");

    function openEditModalEmployee(){
        setEditModalEmployeeOpen(true)
    }

    function openEditModalExternalPerson(){
        setEditModalExternalPerson(true)
    }



    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;

    const openEditModal = (event) => {
        setCurrentId(event.target.value)
        let person;
        if (userType === "Regionální centra") {
            request = `/users`;
        } else {
            request = `/externalPersons`;
        }
        fetch(process.env.REACT_APP_API_URL + request+`/${event.target.value}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {

            person = jsonData;
            console.log(person)
            if(person.institution){
                setPersonName(person.firstname)
                setPersonSurname(person.lastname)
                setPersonEmail(person.email)
                setPersonTelephone(person.phone)
                setPersonRole(person.role)
                setPersonOrganization(person.institution)
                if(person.roleDescription){
                    setPersonRoleDescription(person.roleDescription)
                }

                setNameInvalid(false)
                setSurnameInvalid(false)
                setInstitutionInvalid(false)
                setPhoneInvalid(false)
                setEmailInvalid(false)
                setRoleInvalid(false)

                setEditModalExternalPerson(true)
            }
            else {
                setPersonName(person.firstname)
                setEmploymentType(person.workAgreementTypeId)
                setEmploymentRate(person.workAllocation)
                setEmployeeAddress(person.address)
                setWorkContractFromDate(person.workFrom)
                setWorkContractToDate(person.workTo)
                setPersonSurname(person.lastname)
                setPersonEmail(person.loginEmail)
                setPersonTelephone(person.phone)
                setPersonRole(person.role)
                setEmployeeId(person.employeeId)
                setPersonRegionalCentreId(person.regionalCenter.id)

                setNameInvalid(false)
                setSurnameInvalid(false)
                setPhoneInvalid(false)
                setEmailInvalid(false)
                setRoleInvalid(false)
                setIdInvalid(false)
                setRegionalCentreInvalid(false)

                setEditModalEmployeeOpen(true)
            }


        })
            .catch(error => {
                console.error('Error fetching data:', error);
        });
    }

    const handlePageChange = (event) => {
        setCurrentPage(event.detail.pagination.currentPage);
    };
    const closeEditModal = (event) => {
        setPersonName("")
        setPersonSurname("")
        setPersonRole("")
        setPersonOrganization("")
        setPersonRoleDescription("")
        setPersonTelephone("")
        setPersonEmail("")
        setEmployeeId("")
        setPersonRegionalCentreId("")
        setCreateExternalPerson(false)
        setCreateEmployee(false)
        setEditModalEmployeeOpen(false);
        setEditModalExternalPerson(false);

        setNameInvalid(true)
        setSurnameInvalid(true)
        setInstitutionInvalid(true)
        setPhoneInvalid(true)
        setEmailInvalid(true)
        setRoleInvalid(true)
        setIdInvalid(true)
        setRegionalCentreInvalid((authority === 'HEADQUARTERS' || authority === 'HQ_WITH_REPORT'))
    }

    function putEmployee(){
        fetch(process.env.REACT_APP_API_URL +`/users/${currentId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                "employeeId": employeeId,
                "workAllocation": employmentRate,
                "address": employeeAddress,
                "workAgreementTypeId": employmentType,
                "firstname": personName,
                "lastname": personSurname,
                "role": personRole,
                "phone": personTelephone,
                "regionalCenterId": personRegionalCentreId,
                "loginEmail": personEmail,
                "workFrom": workContractFromDate && (workContractFromDate + "T00:00:00"),
                "workTo":  workContractToDate && (workContractToDate + "T00:00:00")
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                fetchData()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        setPersonName("")
        setPersonSurname("")
        setPersonRole("")
        setEmployeeId("")
        setPersonTelephone("")
        setPersonEmail("")
        setPersonRegionalCentreId("")
        closeEditModal()
    }

    function postEmployee() {
        fetch(process.env.REACT_APP_API_URL+ "/users", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "firstname": personName,
                "lastname": personSurname,
                "phone": personTelephone,
                "workAllocation": 1,
                "loginEmail": personEmail,
                "employeeId": employeeId,
                "regionalCenterId": (authority === 'HEADQUARTERS' || authority === 'HQ_WITH_REPORT') ? personRegionalCentreId : RCid,
                "role": personRole
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                fetchData()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });

        setPersonName("")
        setPersonSurname("")
        setPersonRole("")
        setEmployeeId("")
        setPersonTelephone("")
        setPersonEmail("")
        setPersonRegionalCentreId("")
        closeEditModal()
    }




    function putExternalPerson(){
        if (externalPersonValid) {
            fetch(process.env.REACT_APP_API_URL + `/externalPersons/${currentId}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify({
                    "firstname": personName,
                    "lastname": personSurname,
                    "institution": personOrganization,
                    "roleDescription": personRoleDescription,
                    "role": personRole,
                    "email": personEmail,
                    "phone": personTelephone
                })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(() => {
                    fetchData()
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

            setPersonName("")
            setPersonSurname("")
            setPersonRole("")
            setPersonOrganization("")
            setPersonRoleDescription("")
            setPersonTelephone("")
            setPersonEmail("")
            closeEditModal()
        }
    }


    function postNewExternalPerson() {
        if (externalPersonValid) {
            fetch(process.env.REACT_APP_API_URL + "/externalPersons", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "firstname": personName,
                    "lastname": personSurname,
                    "roleDescription": personRoleDescription,
                    "institution": personOrganization,
                    "role": personRole,
                    "email": personEmail,
                    "phone": personTelephone
                })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(() => {
                    fetchData()
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                })
                .catch(error => {
                    return error.message;
                });

            setPersonName("")
            setPersonSurname("")
            setPersonRole("")
            setPersonOrganization("")
            setPersonRoleDescription("")
            setPersonTelephone("")
            setPersonEmail("")
            closeEditModal()
        }
    }

    function phonePrettier(phone) {
        if (phone !== undefined) {
            return phone.replace(/(?=(?:.{3})*$)/g, ' ')
        }
    }


    const handleLetterClick = (letter) => {
        setCurrentPage(1)
        if(letter === ''){
            setLetterChosen(false)
        }
        else {
            setLetterChosen(true)
        }
        setSearchTerm(letter);
        if(persons.length > 0) {
            const filtered = persons.filter(person =>
                person.lastname.toLowerCase().startsWith(letter.toLowerCase())
            );
            setFilteredItems(filtered);
        }

    };

    const [sortRoleOrder, setSortRoleOrder] = useState('asc');
    const [sortNameOrder, setSortNameOrder] = useState('asc');
    const sortByPersonsName = () => {
        if(persons){
            let sortedPersons = [...filteredItems];
            if (sortNameOrder === 'asc') {
                sortedPersons.sort((a, b) => a.lastname.localeCompare(b.lastname));
            } else {
                sortedPersons.sort((a, b) => b.lastname.localeCompare(a.lastname));
            }
            setFilteredItems(sortedPersons);
        }
    };
    useEffect(() => {
        sortByPersonsName();
        // eslint-disable-next-line no-use-before-define
    }, [sortNameOrder]);

    const sortRoles = () => {
        if(persons){
            let sortedPersons = [...filteredItems];
            if (sortRoleOrder === 'asc') {
                sortedPersons.sort((a, b) => translateEnumToRole(a.role).localeCompare(translateEnumToRole(b.role)));
            } else {
                sortedPersons.sort((a, b) => translateEnumToRole(b.role).localeCompare(translateEnumToRole(a.role)));
            }
            setFilteredItems(sortedPersons);
        }

    };
    useEffect(() => {
        sortRoles();
        // eslint-disable-next-line no-use-before-define
    }, [sortRoleOrder]);


    const [selectedRoles, setSelectedRoles] = useState(rolesOfEmployees);

    const [selectedRegionalCenters, setSelectedRegionalCenters] = useState(allRegionalCenters);

    const setTypeOfUsers = (event) => {
        setUserType(event.detail.label)
        if ((authority === 'HEADQUARTERS' || authority === 'HQ_WITH_REPORT')) {
            if (event.detail.label === "Regionální centra") {
                setSelectedRoles(rolesOfEmployees)
                request = "/users";
            } else {
                setSelectedRoles(rolesOfExternalPersons)
                request = "/externalPersons";
            }
        } else {
            if (event.detail.label === "Regionální centra") {
                setSelectedRoles(rolesOfEmployees)
                request = "/rc/"+RCid+"/employees";
            } else {
                setSelectedRoles(rolesOfExternalPersons)
                request = "/externalPersons";
            }
        }
        fetchData()
    }

    const handlePersonNameChange = (event) => {
        if (event.target.value === "") {
            setNameInvalid(true)
        } else {
            setNameInvalid(false)
        }
        setPersonName(event.target.value);
    };
    const handlePersonSurnameChange = (event) => {
        if (event.target.value === "") {
            setSurnameInvalid(true)
        } else {
            setSurnameInvalid(false)
        }
        setPersonSurname(event.target.value);
    };
    const handlePersonRoleChange = (event) => {
        if (event.target.value === "") {
            setRoleInvalid(true)
        } else {
            setRoleInvalid(false)
        }
        setPersonRole(event.target.value);
    };
    const handleEmployeeIdChange = (event) => {
        if (event.target.value === "") {
            setIdInvalid(true)
        } else {
            setIdInvalid(false)
        }
        setEmployeeId(event.target.value);
    };

    const handleRegionalCentreChange = (event) => {
        if (event.target.value === "") {
            setRegionalCentreInvalid(true)
        } else {
            setRegionalCentreInvalid(false)
        }
        setPersonRegionalCentreId(event.target.value)
    };

    const handlePersonTelephoneChange = (event) => {
        if (!/^\d{9}$/.test(event.target.value)) {
            setPhoneInvalid(true)
        } else {
            setPhoneInvalid(false)
        }
        setPersonTelephone(event.target.value);
    };
    const handlePersonEmailChange = (event) => {
        if (editModalExternalPerson && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value.toLowerCase())) {
            setEmailInvalid(true)
        } else if (editModalEmployeeOpen && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(sfpi\.cz)$/.test(event.target.value.toLowerCase())) {
            setEmailInvalid(true)

        } else {
            setEmailInvalid(false)
        }
        setPersonEmail(event.target.value);
    };
    const handlePersonOrganizationChange = (event) => {
        if (event.target.value === "") {
            setInstitutionInvalid(true)
        } else {
            setInstitutionInvalid(false)
        }
        setPersonOrganization(event.target.value);
    };
    const handlePersonRoleDescriptionChange = (event) => {
        setPersonRoleDescription(event.target.value);
    };




    const handleSearchChange = (event) => {
        setCurrentPage(1)
        setSearchTerm(event.target.value);
        console.log(persons)
        console.log(selectedRegionalCenters)
        persons.forEach(person => {
            if (!person.email) {
                console.log(person.lastname);
            }
        });
        const filtered = persons.filter(person =>
            (person.lastname.toLowerCase().includes(event.target.value.toLowerCase()) ||
                (person.email ? person.email.toLowerCase().includes(event.target.value.toLowerCase()) :
                    person.loginEmail ? person.loginEmail.toLowerCase().includes(event.target.value.toLowerCase()) : false))
            &&
            selectedRoles.includes(translateEnumToRole(person.role))
            && (userType === "Regionální centra" ? selectedRegionalCenters.includes(person.regionalCenter.name) : true)
        )
        console.log(filtered)
        setFilteredItems(filtered);
    };

    const handleRoleChange = (event) => {
        console.log(event.detail.value)
        setSelectedRoles(event.detail.value.map(role => role.name));
        let rolesToFilter = event.detail.value.map(role => role.name);
        setSelectedCategoriesEmpty(false)
        console.log(rolesToFilter)
        if (rolesToFilter.length === 0) {
            rolesToFilter = userType === "Regionální centra" ? rolesOfEmployees : rolesOfExternalPersons
            setSelectedRoles(userType === "Regionální centra" ? rolesOfEmployees : rolesOfExternalPersons)
            setSelectedCategoriesEmpty(true)
        }
        console.log(rolesToFilter)
        const filtered = persons.filter(person =>
            (person.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            person.email.toLowerCase().includes(searchTerm.toLowerCase())) &&
            rolesToFilter.includes(translateEnumToRole(person.role))
            && (userType === "Regionální centra" ? selectedRegionalCenters.includes(person.regionalCenter.name) : true)
        );
        setFilteredItems(filtered);
    };

    const handleRegionalCentreFilterChange = (event) => {
        setSelectedRegionalCenters(event.detail.value.map(centre => centre.name));
        let centresToFilter = event.detail.value.map(centre => centre.name);
        console.log(centresToFilter)
        if (centresToFilter.length === 0) {
            centresToFilter = allRegionalCenters
            setSelectedRegionalCenters(allRegionalCenters)

        }
        const filtered = persons.filter(person =>
            (person.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                person.email.toLowerCase().includes(searchTerm.toLowerCase()))&&
            selectedRoles.includes(translateEnumToRole(person.role))
            && (userType === "Regionální centra" ? centresToFilter.includes(person.regionalCenter.name) : true)
        );
        setFilteredItems(filtered);
    };



    useEffect(() => {
        fetchData();
    }, []);

    const [nameInvalid, setNameInvalid] = useState(true)
    const [surnameInvalid, setSurnameInvalid] = useState(true)
    const [phoneInvalid, setPhoneInvalid] = useState(true)
    const [emailInvalid, setEmailInvalid] = useState(true)
    const [roleInvalid, setRoleInvalid] = useState(true)

    // External person
    const [institutionInvalid, setInstitutionInvalid] = useState(true)

    // RC worker
    const [idInvalid, setIdInvalid] = useState(true)
    const [regionalCentreInvalid, setRegionalCentreInvalid] = useState(null);

    useEffect(() => {
        setRegionalCentreInvalid((authority === 'HEADQUARTERS' || authority === 'HQ_WITH_REPORT'));
    }, [authority]);
    function checkErrorsExternalPerson() {
        let ready = !nameInvalid && !surnameInvalid && !phoneInvalid && !emailInvalid && !institutionInvalid && !roleInvalid
        setExternalPersonValid(ready)
    }

    function checkErrorsRcPerson() {
        let ready = !nameInvalid && !surnameInvalid && !phoneInvalid && !emailInvalid && !regionalCentreInvalid && !idInvalid && !roleInvalid
        setRcPersonValid(ready)
    }

    useEffect(() => {
        checkErrorsExternalPerson()
        checkErrorsRcPerson()
    }, [nameInvalid, surnameInvalid, phoneInvalid, emailInvalid, institutionInvalid, roleInvalid, idInvalid, regionalCentreInvalid]);

    const [externalPersonValid, setExternalPersonValid] = useState(false)
    const [rcPersonValid, setRcPersonValid] = useState(false)

    return (
        <div>
            <Navigation active={"user-management"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Správa uživatelů</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">
                        <div className="gov-title--beta">Správa
                            uživatelů: {(authority === 'HEADQUARTERS' || authority === 'HQ_WITH_REPORT') ? '' : localStorage.getItem("RCname")}</div>
                        {selectedCategoriesEmpty ?
                            <GovTabs onGov-change={setTypeOfUsers} type="chip" variant="primary"
                                     wcag-label="Basic information about the gov.cz design system">
                                <GovTabsItem label="Regionální centra"></GovTabsItem>
                                <GovTabsItem label="Externí osoby" {...(userType === "Externí osoby" ? { default: true } : {})} />
                            </GovTabs> :
                            <div>
                            <GovInfobar variant="primary"><p className="text-white">Pro zvolení typu uživatelů, vypněte zvolený filtr rolí.
                            </p>
                                <gov-icon type="basic" name="info-circle" slot="icon"></gov-icon>
                            </GovInfobar>
                            <GovSpacer size="m"></GovSpacer>
                            </div>

                        }
                        <div className="gov-flexbox search-row-width">
                            <GovFormInput onGov-input={handleSearchChange}
                                          placeholder="Zadejte email nebo příjmení uživatele" size="m"
                                          variant="secondary">
                                <GovIcon type="basic" name="search" slot="right-icon"></GovIcon>
                            </GovFormInput>
                        </div>
                        <GovSpacer size="3xl"></GovSpacer>

                        <GovFormControl>
                            <GovFormMultiSelect size="m" placeholder="Role" onGov-change={handleRoleChange}
                                                value={selectedRoles}>
                                <div>
                                    {userType === "Regionální centra" ?

                                        rolesOfEmployees.map((role, index) => (
                                            <option key={index} value={role}>{role}</option>
                                        )) :
                                        rolesOfExternalPersons.map((role, index) => (
                                            <option key={index} value={role}>{role}</option>
                                        ))
                                    }
                                < /div>
                            </GovFormMultiSelect>
                        </GovFormControl>
                        {userType === "Regionální centra" &&
                            <GovFormControl>
                                <GovFormMultiSelect size="m" placeholder="Regionální centrum"
                                                    onGov-change={handleRegionalCentreFilterChange}
                                                    value={selectedRegionalCenters}>
                                    {allRegionalCenters.map((center, index) => (
                                        <option key={index} value={center}>{center}</option>
                                    ))}
                                </GovFormMultiSelect>
                            </GovFormControl>
                        }
                        <div className="gov-flexbox">
                            <div className="gov-container gov-flexbox gov-alphabet">
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('A')}>A
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('B')}>B
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('C')}>C
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('Č')}>Č
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('D')}>D
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('E')}>E
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('F')}>F
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('G')}>G
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('H')}>H
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('CH')}>CH
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('I')}>I
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('J')}>J
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('K')}>K
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('L')}>L
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('M')}>M
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('N')}>N
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('O')}>O
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('P')}>P
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('Q')}>Q
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('R')}>R
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('Ř')}>Ř
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('S')}>S
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('Š')}>Š
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('T')}>T
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('U')}>U
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('V')}>V
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('W')}>W
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('X')}>X
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('Y')}>Y
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('Z')}>Z
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="link"
                                           size="m" onGov-click={() => handleLetterClick('Ž')}>Ž
                                </GovButton>
                                {letterChosen && <GovButton onGov-click={() => {
                                    handleLetterClick('')
                                }} wcag-label="What exactly happens when you click?" variant="primary"
                                                            size="xs">Odstranit výběr
                                </GovButton>}
                            </div>
                            {
                            (userType === "Regionální centra") ?
                                <div>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="solid"
                                           onGov-click={() => {openEditModalEmployee()
                                           setCreateEmployee(true)}} size="m">Přidat pracovníka RC
                                </GovButton>
                                    </div> :
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="solid"
                                           onGov-click={() => {openEditModalExternalPerson()
                                           setCreateExternalPerson(true)}} size="m">Přidat externí osobu
                                </GovButton>
                            }
                        </div>
                        <GovSpacer size="xl"></GovSpacer>
                        {currentItems ?
                        <table cellSpacing="0" cellPadding="0">
                            <thead>
                            <tr>
                                <th>Jméno</th>
                                <th>
                                    Příjmení
                                    {sortNameOrder === "asc" ?
                                        <GovButton onGov-click={() => setSortNameOrder('desc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                        :
                                        <GovButton onGov-click={() => setSortNameOrder('asc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                    }
                                </th>
                                <th>Email</th>
                                <th>Telefon</th>
                                <th>
                                    {userType === "Regionální centra" ?
                                        "Regionální centrum" :
                                        "Instituce"
                                    }
                                </th>
                                <th>
                                    Role
                                    {sortRoleOrder === "asc" ?
                                        <GovButton onGov-click={() => setSortRoleOrder('desc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                        :
                                        <GovButton onGov-click={() => setSortRoleOrder('asc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                    }
                                </th>
                                {
                                    ((authority === 'HEADQUARTERS' || authority === 'HQ_WITH_REPORT')) ?
                                        <th>
                                            Akce
                                        </th> :
                                        ""
                                }
                            </tr>
                            </thead>

                            <tbody>
                            {currentItems.map((row, index) => (
                                <tr key={index}>
                                    {((authority === "RC_LEADER" || authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") &&
                                        translateEnumToRole(row.role) !== "MMR") ?
                                        <td><a href={`/user-management/month-reports/`+row.id}>{row.firstname}</a></td>
                                        :
                                        <td>{row.firstname}</td>
                                    }
                                    {((authority === "RC_LEADER" || authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") &&
                                        translateEnumToRole(row.role) !== "MMR") ?
                                        <td><a href={`/user-management/month-reports/`+row.id}>{row.lastname}</a></td>
                                        :
                                        <td>{row.lastname}</td>
                                    }
                                    {userType === "Regionální centra" ?
                                        <td>{row.loginEmail}</td> :
                                        <td>{row.email}</td>
                                    }
                                    {
                                        <td>+420 {phonePrettier(row.phone)}</td>
                                    }
                                    {
                                        (userType === "Regionální centra") ?
                                            <td>{row.regionalCenter && row.regionalCenter.name}</td> :
                                            <td>{row.institution}</td>
                                    }
                                    {
                                        <td>{translateEnumToRole(row.role)}</td>
                                    }
                                    {
                                        (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") ?
                                            <td>
                                                <GovButton value={row.id} variant="primary" type="solid" size="m"
                                                           onGov-click={openEditModal}>Upravit
                                                </GovButton>
                                            </td> :
                                            ((authority === "RC_LEADER" && userType !== "Regionální centra") ||
                                                (userType === "Regionální centra" && row.role === "RC_EMPLOYEE")) &&
                                            <td>
                                                <GovButton value={row.id} variant="primary" type="solid" size="m"
                                                           onGov-click={openEditModal}>Upravit
                                                </GovButton>
                                            </td>
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </table>
                            : (
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                            )}
                    </div>
                </div>
                <div className="pagination-container">
                    {persons ? (
                        <GovPagination onGov-page={handlePageChange} variant="primary" size="s" type="button"
                                       current={currentPage} total={filteredItems.length} page-size={itemsPerPage}
                                       max-pages="4" wcag-label="Main paging" wcag-page-label="Page"></GovPagination>
                    ) : (
                        <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                     animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                    )}
                </div>
            </div>
            <GovModal open={editModalEmployeeOpen} onGov-close={closeEditModal}
                      label={createEmployee ? "Založení nového pracovníka" : "Úprava pracovníka"}
                      wcag-close-label="Close dialog box with more information">
                <GovFormControl>
                    <GovFormInput value={personName} onGov-input={handlePersonNameChange}
                                  size="s" variant="secondary"
                                  input-type="text"
                                  placeholder="Jméno"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Jméno*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={personSurname} onGov-input={handlePersonSurnameChange}
                                  size="s" variant="secondary"
                                  input-type="text" placeholder="Příjmení"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Příjmení*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={employeeId} onGov-input={handleEmployeeIdChange}
                                  size="s" variant="secondary"
                                  input-type="number" placeholder="Číslo zaměstnance"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Číslo zaměstnance*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={personEmail} onGov-input={handlePersonEmailChange}
                                  size="s" variant="secondary"
                                  input-type="text" placeholder="E-mail"></GovFormInput>
                    <GovFormLabel slot="top" size="s">E-mail*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={personTelephone}
                                  onGov-input={handlePersonTelephoneChange}
                                  size="s" variant="secondary"
                                  input-type="text" placeholder="Telefon"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Telefon*</GovFormLabel>
                </GovFormControl>
                <div className="sfpi-title-section mt-20">Role
                </div>
                <GovFormControl>
                    <GovFormGroup gap="s">
                        <GovFormRadio checked={personRole === "RC_EMPLOYEE"}
                                      onGov-change={handlePersonRoleChange}
                                      value="RC_EMPLOYEE" size="s"
                                      name="list-radio">
                            <GovFormLabel size="s" slot="label">Specialista RC</GovFormLabel>
                        </GovFormRadio>
                        {(authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") &&
                        <GovFormRadio checked={personRole === "RC_LEADER"} onGov-change={handlePersonRoleChange}
                                      value="RC_LEADER"
                                      size="s"
                                      name="list-radio">
                            <GovFormLabel size="s" slot="label">Manažer RC</GovFormLabel>
                        </GovFormRadio>
                        }
                        <GovFormRadio checked={personRole === "NON_ACTIVE"} onGov-change={handlePersonRoleChange}
                                      value="NON_ACTIVE"
                                      size="s" name="list-radio">
                            <GovFormLabel size="s" slot="label">Neaktivní</GovFormLabel>
                        </GovFormRadio>
                    </GovFormGroup>
                </GovFormControl>
                {(authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") &&
                    <div>
                        <div className="sfpi-title-section mt-20">Regionální centrum</div>
                                <GovFormControl>
                                    <GovFormSelect size="m" placeholder="Regionální centrum" onGov-change={handleRegionalCentreChange}
                                                   value={personRegionalCentreId}>
                                        <option disabled="disabled" value="">Regionální centrum</option>
                                        {allRegionalCenters.map((center, index) => (
                                            <option key={index} value={index + 1}>{center}</option>
                                        ))}
                                    </GovFormSelect>
                                </GovFormControl>
                    </div>
                }
                <div>
                    {emailInvalid &&
                        <GovMessage variant="error">
                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                            <p>Email musí být z domény @sfpi.cz</p>
                        </GovMessage>

                    }
                </div>
                <div>
                    {phoneInvalid &&
                        <GovMessage variant="error">
                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                            <p>Telefon musí obsahovat 9 číslic bez předvolby</p>
                        </GovMessage>
                    }
                </div>
                <div>
                    {!rcPersonValid &&
                        <GovMessage variant="error">
                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                            <p>Nejsou vyplněny všechny povinné položky</p>
                        </GovMessage>}
                </div>

                <GovButton variant="primary" size="m" type="solid"
                           wcag-label="Open dialog box for more information" disabled={!rcPersonValid}
                           onGov-click={createEmployee ? postEmployee : putEmployee}
                >
                    <div>
                        {createEmployee ? "Přidat" : "Upravit"}
                    </div>
                </GovButton>


            </GovModal>
            <GovModal open={editModalExternalPerson} onGov-close={closeEditModal}
                      label={createExternalPerson ? "Založení nové externí osoby" : "Úprava externí osoby"}
                      wcag-close-label="Close dialog box with more information">
                <GovFormControl>
                    <GovFormInput value={personName} onGov-input={handlePersonNameChange}
                                  size="s" variant="secondary"
                                  input-type="text"
                                  placeholder="Jméno"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Jméno*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={personSurname}
                                  onGov-input={handlePersonSurnameChange}
                                  size="s" variant="secondary"
                                  input-type="text"
                                  placeholder="Příjmení"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Příjmení*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={personTelephone}
                                  onGov-input={handlePersonTelephoneChange}
                                  size="s" variant="secondary"
                                  input-type="text" placeholder="Telefon"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Telefon*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={personEmail} onGov-input={handlePersonEmailChange}
                                  size="s" variant="secondary"
                                  input-type="text" placeholder="E-mail"></GovFormInput>
                    <GovFormLabel slot="top" size="s">E-mail*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={personOrganization}
                                  onGov-input={handlePersonOrganizationChange}
                                  size="s" variant="secondary"
                                  input-type="text" placeholder="Organizace"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Organizace*</GovFormLabel>
                </GovFormControl>
                <div className="sfpi-title-section mt-20">Role
                </div>
                <GovFormControl>
                    <GovFormGroup gap="s">
                        <GovFormRadio checked={personRole === "STATUTORY_DEPUTY"}
                                      onGov-change={handlePersonRoleChange}
                                      value="STATUTORY_DEPUTY" size="s"
                                      name="list-radio">
                            <GovFormLabel size="s" slot="label">Statutární
                                zástupce</GovFormLabel>
                        </GovFormRadio>
                        <GovFormRadio checked={personRole === "DELEGATE"}
                                      onGov-change={handlePersonRoleChange} value="DELEGATE"
                                      size="s"
                                      name="list-radio">
                            <GovFormLabel size="s" slot="label">Zmocněnec</GovFormLabel>
                        </GovFormRadio>
                        <GovFormRadio checked={personRole === "OBSERVER"}
                                      onGov-change={handlePersonRoleChange}
                                      value="OBSERVER" size="s" name="list-radio">
                            <GovFormLabel size="s" slot="label">Nahlížející
                                osoba</GovFormLabel>
                        </GovFormRadio>
                        <GovFormRadio checked={personRole === "CONTACT_PERSON"}
                                      onGov-change={handlePersonRoleChange}
                                      value="CONTACT_PERSON"
                                      size="s" name="list-radio">
                            <GovFormLabel size="s" slot="label">Kontaktní
                                osoba</GovFormLabel>
                        </GovFormRadio>
                        <GovFormRadio checked={personRole === "OTHER"}
                                      onGov-change={handlePersonRoleChange}
                                      value="OTHER"
                                      size="s" name="list-radio">
                            <GovFormLabel size="s" slot="label">Jiná
                            </GovFormLabel>
                        </GovFormRadio>

                    </GovFormGroup>
                    <div>
                        {personRole === "OTHER" &&
                            <div>
                                <GovFormControl>
                                    <GovFormInput value={personRoleDescription}
                                                  className="mt-10"
                                                  onGov-input={handlePersonRoleDescriptionChange}
                                                  size="s" variant="secondary"
                                                  input-type="text" placeholder="Popis role"></GovFormInput>
                                </GovFormControl>
                            </div>
                        }
                    </div>

                </GovFormControl>
                <div>
                    {phoneInvalid &&
                        <GovMessage variant="error">
                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                            <p>Telefon musí obsahovat 9 číslic bez předvolby</p>
                        </GovMessage>
                    }
                </div>
                <div>
                    {!externalPersonValid &&
                        <GovMessage variant="error">
                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                            <p>Nejsou vyplněny všechny povinné položky</p>
                        </GovMessage>}
                </div>

                <GovButton variant="primary" size="m" type="solid"
                           wcag-label="Open dialog box for more information" disabled={!externalPersonValid}
                           onGov-click={createExternalPerson ? postNewExternalPerson : putExternalPerson}>
                    <div>
                        {createExternalPerson ? "Přidat" : "Upravit"}
                    </div>
                </GovButton>
            </GovModal>
        </div>
    )
}

