import Navigation from "../Navigation";
import {
    GovBreadcrumbs,
    GovButton,
    GovDropdown,
    GovFormCheckbox,
    GovFormControl,
    GovFormGroup,
    GovFormInput,
    GovFormLabel,
    GovFormSelect,
    GovIcon, GovLoading,
    GovMessage,
    GovModal,
    GovPrompt,
    GovSkeleton,
    GovSpacer
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {MenuList} from "./MenuList";
import '../css/manage-users.css';
import {
    convertIsoToDateFormat,
    convertIsoToLocalDate,
    convertIsoToTime, convertUtilizationToPercentage, mapDateFormat,
    mapTimeSheetType,
    parseIsoToYearMonth,
    parseJwt
} from "../utils/utils";
import Select, {components} from "react-select";
import {TimesheetModal} from "./TimesheetModal";

export function TimesheetOneMonthContent({monthReportId}) {
    const jwt = localStorage.getItem("jwt")

    let [timesheets, setTimesheets] = useState(null)
    const [monthReport, setMonthReport] = useState(null)

    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");

    const fetchMonthTimesheetsReport = () => {
        fetch(process.env.REACT_APP_API_URL + `/monthReports/workReports/${monthReportId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
                setMonthReport(jsonData);
                setUser(jsonData.user)
                const sortedData = jsonData.workReports.sort((a, b) => new Date(a.start) - new Date(b.start));
                setTimesheets(sortedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    };


    function getRegionalCenterByRegionName(regionName) {
        if (regionName === "Hlavní město Praha") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Moravskoslezský kraj") {
            return "Moravskoslezský kraj"
        } else if (regionName === "Olomoucký kraj") {
            return "Olomoucký kraj"
        } else if (regionName === "Pardubický kraj") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Kraj Vysočina") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Zlínský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Královéhradecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Ústecký kraj") {
            return "Ústecký a Karlovarský kraj"
        } else if (regionName === "Plzeňský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Liberecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Jihomoravský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Jihočeský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Středočeský kraj") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Karlovarský kraj") {
            return "Ústecký a Karlovarský kraj"
        }
    }


    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 100;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [currentTimesheetId, setCurrentTimesheetId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(null);
    const [modalTimesheetOpen, setModalTimesheetOpen] = useState(false);
    const [editModalExternalPerson, setEditModalExternalPerson] = useState(false);
    const [createTimesheet, setCreateTimesheet] = useState(false);

    const [projects, setProjects] = useState([])
    const [villages, setVillages] = useState([])


    function openModalTimesheet() {
        setModalTimesheetOpen(true)
    }


    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;



    const filterItems = () => {
        if (timesheets) {
            // Filter by type
            let filtered = timesheets.filter(item => filtersByTimesheetType[item.type.toString()]);

            setFilteredItems(filtered);
        }
    };

    const openEditTimesheetModal = (event) => {
        setCurrentTimesheetId(event.target.value)
        let timesheet;
        fetch(process.env.REACT_APP_API_URL + `/workReports/${event.target.value}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            console.log(jsonData)
            timesheet = jsonData;

            setTimesheetDate(convertIsoToLocalDate(timesheet.start))
            setTimesheetStartTime(convertIsoToTime(timesheet.start))
            setTimesheetEndTime(convertIsoToTime(timesheet.end))
            setTimesheetHoursWorked(calculateHoursWorkedForEdit(timesheet.start, timesheet.end))
            setTimesheetType(timesheet.type)
            console.log(timesheet.type)
            setTimesheetDescription(timesheet.description)

            timesheet.project ? setSelectedProjectId(timesheet.project.id) : setSelectedProjectId('')
            timesheet.town ? setSelectedVillageId(timesheet.town.id) : setSelectedVillageId('')
            timesheet.town ? console.log(timesheet.town.id) : console.log("null")


            setModalTimesheetOpen(true)
            setSelectedProjectIdInvalid(false)
            setSelectedVillageIdInvalid(false)
            setTimesheetDateInvalid(false)
            setTimesheetStartTimeInvalid(false)
            setTimesheetEndTimeInvalid(false)
            setTimesheetDescriptionInvalid(false)
            setTimesheetTimesRangeInvalid(false)
            setTimesheetValid(true)

        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const handlePageChange = (event) => {
        setCurrentPage(event.detail.pagination.currentPage);
    };
    const closeEditModal = (event) => {
        setTimesheetDate("")
        setTimesheetStartTime("")
        setTimesheetEndTime("")
        setTimesheetType("1")
        setTimesheetDescription("")
        setTimesheetHoursWorked(null)

        setSelectedProjectId("")
        setSelectedVillageId("")
        setCreateTimesheet(false)
        setModalTimesheetOpen(false);
        setSelectedProjectIdInvalid(true)
        setSelectedVillageIdInvalid(true)
        setTimesheetStartTimeInvalid(true)
        setTimesheetEndTimeInvalid(true)
        setTimesheetDescriptionInvalid(true)
        setTimesheetTimesRangeInvalid(true)
    }


    function putWorkReport() {
        fetch(process.env.REACT_APP_API_URL + `/workReports/${currentTimesheetId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                "type": timesheetType,
                "start": timesheetDate + "T" + timesheetStartTime + ":00",
                "end": timesheetDate + "T" + timesheetEndTime + ":00",
                "description": timesheetDescription,
                "projectId": selectedProjectId === "" ? null : selectedProjectId,
                "townId": selectedVillageId === "" ? null : selectedVillageId,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                fetchMonthTimesheetsReport()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        closeEditModal()
    }


    const [monthReports, setMonthReports] = useState([])


    function postWorkReport() {
        fetch(process.env.REACT_APP_API_URL + "/workReports", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "type": timesheetType,
                "start": timesheetDate + "T" + timesheetStartTime + ":00",
                "end": timesheetDate + "T" + timesheetEndTime + ":00",
                "description": timesheetDescription,
                "projectId": selectedProjectId === "" ? null : selectedProjectId,
                "townId": selectedVillageId === "" ? null : selectedVillageId,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                fetchMonthTimesheetsReport()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });

        closeEditModal()
    }

    const [note, setNote] = useState('')

    function handleNoteChange(event) {
        setNote(event.target.value)
    }

    function approveTimesheet() {
        fetch(process.env.REACT_APP_API_URL + `/monthReports/workReports/${monthReportId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "state": authority === (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") ? "APPROVED_BY_HQ" : "APPROVED_BY_SUPERVISOR",
                "note": note
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                fetchMonthTimesheetsReport()
            })
            .catch(error => {
                return error.message;
            });
    }

    function declineTimesheet() {
        fetch(process.env.REACT_APP_API_URL + `/monthReports/workReports/${monthReportId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "state": authority === (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") ? "REJECTED_BY_HQ" : "REJECTED_BY_SUPERVISOR",
                "note": note
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                closeRejectModal()
                fetchMonthTimesheetsReport()

            })
            .catch(error => {
                return error.message;
            });
    }


    const [timesheetType, setTimesheetType] = useState("1");
    const [selectedProjectId, setSelectedProjectId] = useState('')
    const [selectedTypes, setSelectedTypes] = useState([]);


    const [selectedVillageId, setSelectedVillageId] = useState('')
    const [timesheetDate, setTimesheetDate] = useState('')
    const [timesheetStartTime, setTimesheetStartTime] = useState('')
    const [timesheetEndTime, setTimesheetEndTime] = useState('')
    const [timesheetHoursWorked, setTimesheetHoursWorked] = useState(null)
    const [timesheetDescription, setTimesheetDescription] = useState('')

    const [timesheetTypeInvalid, setTimesheetTypeInvalid] = useState(false)
    const [selectedProjectIdInvalid, setSelectedProjectIdInvalid] = useState(true)
    const [selectedVillageIdInvalid, setSelectedVillageIdInvalid] = useState(true)
    const [timesheetDateInvalid, setTimesheetDateInvalid] = useState(true)
    const [timesheetStartTimeInvalid, setTimesheetStartTimeInvalid] = useState(true)
    const [timesheetEndTimeInvalid, setTimesheetEndTimeInvalid] = useState(true)
    const [timesheetDescriptionInvalid, setTimesheetDescriptionInvalid] = useState(true)
    const [timesheetTimesRangeInvalid, setTimesheetTimesRangeInvalid] = useState(true)

    const [timesheetValid, setTimesheetValid] = useState(false)


    const handleTimesheetTypeChange = (event) => {
        if (event.target.value === "") {
            setTimesheetTypeInvalid(true)
        } else {
            setTimesheetTypeInvalid(false)
        }
        setTimesheetType(event.target.value);
    }

    const handleVillageIdChange = (event) => {
        if (event.target === "") {
            setSelectedVillageIdInvalid(true)
        } else {
            setSelectedVillageIdInvalid(false)
        }
        setSelectedVillageId(event.value);
    };

    const handleDateChange = (event) => {
        if (event.target.value === "") {
            setTimesheetDateInvalid(true)
        } else {
            setTimesheetDateInvalid(false)
        }
        setTimesheetDate(event.target.value);
        setTimesheetHoursWorked(calculateHoursWorkedForDateChange(event.target.value, timesheetStartTime, timesheetEndTime));
    };

    const handleStartTimeChange = (event) => {
        if (event.target.value === "") {
            setTimesheetStartTimeInvalid(true)
        } else {
            setTimesheetStartTimeInvalid(false)
        }
        setTimesheetStartTime(event.target.value);
        setTimesheetHoursWorked(calculateHoursWorked(event.target.value, timesheetEndTime));
    }

    const handleEndTimeChange = (event) => {
        if (event.target.value === "") {
            setTimesheetEndTimeInvalid(true)
        } else {
            setTimesheetEndTimeInvalid(false)
        }
        setTimesheetEndTime(event.target.value);
        setTimesheetHoursWorked(calculateHoursWorked(timesheetStartTime, event.target.value));
    }

    const handleDescriptionChange = (event) => {
        if (event.target.value === "") {
            setTimesheetDescriptionInvalid(true)
        } else {
            setTimesheetDescriptionInvalid(false)
        }
        setTimesheetDescription(event.target.value);
    }

    const handleProjectIdChange = (event) => {
        if (event.value === "") {
            setSelectedProjectIdInvalid(true)
        } else {
            setSelectedProjectIdInvalid(false)
        }
        setSelectedProjectId(event.value);
    };

    function calculateHoursWorkedForEdit(startTime, endTime) {
        const start = new Date(`${startTime}`);
        const end = new Date(`${endTime}`);
        const result = (end - start) / (1000 * 60 * 60)
        if (isNaN(result) || result <= 0) {
            setTimesheetTimesRangeInvalid(true)
            return 0
        } else {
            setTimesheetTimesRangeInvalid(false)
            return result
        }
    }

    function calculateHoursWorkedForDateChange(date, startTime, endTime) {
        const start = new Date(`${date}T${startTime}:00`);
        const end = new Date(`${date}T${endTime}:00`);
        const result = (end - start) / (1000 * 60 * 60)

        if (isNaN(result) || result <= 0) {
            setTimesheetTimesRangeInvalid(true)
            return 0
        } else {
            setTimesheetTimesRangeInvalid(false)
            return result
        }
    }

    function calculateHoursWorked(startTime, endTime) {
        const start = new Date(`${timesheetDate}T${startTime}:00`);
        const end = new Date(`${timesheetDate}T${endTime}:00`);
        const result = (end - start) / (1000 * 60 * 60)

        if (isNaN(result) || result <= 0) {
            setTimesheetTimesRangeInvalid(true)
            return 0
        } else {
            setTimesheetTimesRangeInvalid(false)
            return result
        }
    }

    const calculateHoursWorkedFromIso = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const differenceInMilliseconds = endDate - startDate;
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
        return differenceInHours.toFixed(2); // Returns the difference in hours, rounded to 2 decimal places
    };


    const [dateRange, setDateRange] = useState('');
    const [labels, setLabels] = useState({
        lastMonth: '',
    });
    const generateLabels = () => {
        const today = new Date();
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        const formatDate = (date) => date.toLocaleDateString('cs-CZ', {day: 'numeric', month: 'long', year: 'numeric'});

        const newLabels = {
            lastMonth: `Minulý měsíc (${formatDate(lastMonthStart)} - ${formatDate(lastMonthEnd)})`,
        };

        setLabels(newLabels);
        setDateRange(newLabels.today);
    };
    let request = "";
    const [user, setUser] = useState(null);


    useEffect(() => {
        generateLabels();
        fetchMonthTimesheetsReport()
        fetchVillages()
    }, []);

    const fetchProjects = () => {
        if (authority !== "HEADQUARTERS" && authority !== "HQ_WITH_REPORT") {
            request = "?regionalCenterId=" + RCid;
        } else {
            request = "";
        }
        fetch(process.env.REACT_APP_API_URL + "/projects" + request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const projectsArray = jsonData.map(project => {
                    return {value: project.id, label: project.name + " (" + project.id + ")"}
                })
                setProjects(projectsArray);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const fetchVillages = () => {
        if (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") {
            request = "/towns";
        } else {
            request = "/rc/" + RCid + "/towns";
        }
        if (localStorage.getItem("towns") === null) {
            fetch(process.env.REACT_APP_API_URL + request, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(jsonData => {
                    setVillages(jsonData);
                    localStorage.setItem("towns", JSON.stringify(jsonData))
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            setVillages(JSON.parse(localStorage.getItem("towns")))
        }
    };

    const handleUpdating = () => {
        fetchMonthTimesheetsReport()
    }

    useEffect(() => {
        if (timesheetType === "2" || timesheetType === 2) {
            fetchVillages();
        } else if (timesheetType === "3" || timesheetType === 3) {
            fetchProjects();
        }
    }, [timesheetType]);

    const DropdownIndicator = (
        props
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <GovIcon type="basic" name="chevron-down"></GovIcon>
            </components.DropdownIndicator>
        );
    };
    const customStyles = {
        dropdownIndicator: (styles, state) => ({
            ...styles,
            width: "28px",
            marginRight: "5px",
            "&:hover": {
                color: "#686868"
            },
            color: state.isFocused ? "#686868" : "#686868",
            fill: "red"
        }),
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            cursor: 'pointer',
            borderRadius: '3px',
            "&:hover": {
                borderColor: "#2362a2"
            },
            borderColor: '#686868',
            minHeight: '32px',
            height: '32px',
            boxShadow: state.isFocused ? null : null,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            color: '#686868',
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            fontSize: '14px',
            padding: '0 8px',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            color: '#686868',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#686868',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '32px',
        }),
    };


    const [sortDateOrder, setSortDateOrder] = useState('asc');

    const sortTimesheetsByDate = () => {
        if (timesheets) {
            let sortedTimesheets = [...filteredItems];
            sortedTimesheets.sort((a, b) => {
                const dateA = new Date(a.start);
                const dateB = new Date(b.start);
                return sortDateOrder === 'asc' ? dateA - dateB : dateB - dateA;
            });
            setFilteredItems(sortedTimesheets);
        }
    };


    function checkErrorsTimesheet() {
        let ready = !timesheetTypeInvalid && !timesheetDateInvalid
            && !timesheetStartTimeInvalid && !timesheetEndTimeInvalid && !timesheetDescriptionInvalid
            && !timesheetTimesRangeInvalid
        if (timesheetType === "2") {
            ready = ready && !selectedVillageIdInvalid
        } else if (timesheetType === "3") {
            ready = ready && !selectedProjectIdInvalid
        }
        setTimesheetValid(ready)
    }

    const [filtersByTimesheetType, setFiltersByTimesheetType] = useState({
        "-1": true,
        "1": true,
        "2": true,
        "3": true,
        "4": true,
        "5": true,
        "6": true,
        "7": true
    });

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    function openDeleteModal() {
        setDeleteModalOpen(true)
    }

    function closeDeleteModal() {
        setDeleteModalOpen(false)
    }

    const [rejectModalOpen, setRejectModalOpen] = useState(false)

    function closeRejectModal() {
        setRejectModalOpen(false)
    }

    function deleteTimesheet(event) {
        fetch(process.env.REACT_APP_API_URL + `/workReports/${event.target.value}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.status === 204) {
                    fetchMonthTimesheetsReport()
                } else {
                    return response.json().then(data => {
                        throw new Error(`Unexpected response: ${data.message || 'No message provided'}`);
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        closeDeleteModal()
    }

    const handleCheckboxChange = (event) => {
        const {value, checked} = event.target;
        setFiltersByTimesheetType(prevFilters => ({
            ...prevFilters,
            [value]: checked
        }));
    };

    const calculateTotalWorkHours = () => {
        if (currentItems) {
            return currentItems.reduce((total, item) => {
                // if (item.type !== -1) {
                    const startDate = new Date(item.start);
                    const endDate = new Date(item.end);
                    const startDay = startDate.getDay();
                    const endDay = endDate.getDay();

                    // Check if the start and end dates are not weekends (Saturday or Sunday)
                    if (startDay !== 0 && startDay !== 6 && endDay !== 0 && endDay !== 6) {
                        const hoursWorked = calculateHoursWorkedFromIso(item.start, item.end);
                        return total + parseFloat(hoursWorked);
                    }
                // }
                return total;
            }, 0).toFixed(2);
        }
        return 0;
    };

    const calculateUtilization = () => {
        if (!filteredItems) return 0;

        const type2And3Items = filteredItems.filter(item => item.type === 2 || item.type === 3);

        const totalHoursType2And3 = type2And3Items.reduce((total, item) => {
            const hoursWorked = calculateHoursWorkedFromIso(item.start, item.end);
            return total + parseFloat(hoursWorked);
        }, 0);

        const totalHoursAllActivities = filteredItems.reduce((total, item) => {
            const hoursWorked = calculateHoursWorkedFromIso(item.start, item.end);
            return total + parseFloat(hoursWorked);
        }, 0);

        const utilization = totalHoursAllActivities ? (totalHoursType2And3 / totalHoursAllActivities) * 100 : 0;

        return utilization.toFixed(2);
    };

    useEffect(() => {
        checkErrorsTimesheet()
    }, [timesheetType, timesheetDate, timesheetStartTime, timesheetEndTime, timesheetDescription, selectedProjectId, selectedVillageId])


    useEffect(() => {
        sortTimesheetsByDate();
        // eslint-disable-next-line no-use-before-define
    }, [sortDateOrder]);


    // useEffect(() => {
    //     filterItemsByDateRange()
    // }, [timesheets,dateFromFilter, dateToFilter]);
    //
    // useEffect(() => {
    //     filterItemsByType();
    // }, [filtersByTimesheetType, timesheets]);

    const filterItemsByType = () => {
        if (timesheets) {
            const filtered = timesheets.filter(item => filtersByTimesheetType[item.type.toString()]);
            setFilteredItems(filtered);
        }
    };

    useEffect(() => {
        filterItems();
    }, [timesheets, filtersByTimesheetType]);

    const stateMessages = {
        CREATED: {
            message: "Tento výkaz je schvalován vedoucím regionálního centra."
        },
        APPROVED_BY_SUPERVISOR: {
            message: "Tento výkaz byl schválen vedoucím regionálního centra.",
            style: { color: 'green' }
        },
        APPROVED_BY_HQ: {
            message: "Tento výkaz byl schválen centrálou.",
            style: { color: 'green' }
        },
        REJECTED_BY_SUPERVISOR: {
            message: "Tento výkaz byl zamítnut vedoucím regionálního centra.",
            style: { color: 'red' },
            note: true
        },
        REJECTED_BY_HQ: {
            message: "Tento výkaz byl zamítnut centrálou.",
            style: { color: 'red' },
            note: true
        }
    };

    const [documentIsLoading, setDocumentIsLoading] = useState(false);

    const downloadWorkMonthReport = (event) => {
        fetch(process.env.REACT_APP_API_URL + `/monthReports/workReports/${monthReportId}/export`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = user.firstname + " " + user.lastname + " výkaz za " + monthReport.date
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                setDocumentIsLoading(false);
            })
            .catch(error => console.error('There was a problem with the fetch operation:', error));
    };

    const currentDate = new Date();
    const currentDay = currentDate.getDate();


    return (
        <div className="">
            <Navigation active={"timesheet"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        {(user && authority !== "RC_EMPLOYEE") &&<li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href={`/user-management/month-reports/${user.id}`}>Přehled timesheetů a cestovních náhrad ({user.firstname + " " + user.lastname})</a>
                        </li>
                        }
                       <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>{authority !== "RC_EMPLOYEE" ? "Schválení" : "Detail"} timesheetu</strong>
                        </li>

                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container vh-80">
                    <div className="gov-container__content">
                        {(user && authority !== "RC_EMPLOYEE") ?
                            <div className="gov-title--beta">Schválení timesheetu
                                ({user.firstname + " " + user.lastname})</div>
                            :
                            <div className="gov-title--beta">Detail timesheetu</div>
                        }
                        <div className="gov-flexbox gov-flexbox--justify-start align-items-normal">
                            <div>
                                {monthReport &&
                                <div>
                                    <h3>Cestovní náhrady za období</h3>
                                    <p>{mapDateFormat(monthReport.date)}</p>
                                </div>
                                }
                                <div>
                                    {monthReport && stateMessages[monthReport.state] && (
                                        <div>
                                            <div style={stateMessages[monthReport.state].style}>
                                                {stateMessages[monthReport.state].message}
                                            </div>
                                            {stateMessages[monthReport.state].note && (
                                                <p>Důvod zamítnutí: {monthReport.note}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {monthReport &&
                                    ((authority === "RC_LEADER" && user.id !== monthReport.user.id && (monthReport.state === "CREATED" || monthReport.state === "REJECTED_BY_HQ"))
                                        || ((authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") && (monthReport.state === "CREATED" || monthReport.state === "APPROVED_BY_SUPERVISOR"
                                        || monthReport.state === "REJECTED_BY_HQ" || monthReport.state === "APPROVED_BY_HQ")))
                                    &&
                                    <div>
                                        <GovButton className="gov-flexbox--justify-center"
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="solid"
                                                   onGov-click={() => {
                                                       approveTimesheet()
                                                   }} size="m">Schválit timesheet
                                        </GovButton>
                                        <GovButton className="ml-5 gov-flexbox--justify-center"
                                                   wcag-label="What exactly happens when you click?" variant="error"
                                                   type="solid"
                                                   onGov-click={() => {
                                                       setRejectModalOpen(true)
                                                   }} size="m">Zamítnout timesheet
                                        </GovButton>
                                    </div>
                                }
                                {(monthReport && ((monthReport.state === "APPROVED_BY_SUPERVISOR") || (monthReport.state === "APPROVED_BY_HQ"))) &&
                                    <div>
                                        <GovButton className="gov-flexbox--justify-center"
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="solid"
                                                   onGov-click={() => {
                                                       setDocumentIsLoading(true)
                                                       downloadWorkMonthReport()
                                                   }} size="m">Exportovat výkaz práce
                                        </GovButton>
                                    </div>
                                }
                                <GovPrompt open={rejectModalOpen} onGov-close={closeRejectModal}
                                           label="Zamítnout timesheet" role="dialog" label-tag="h5"
                                           wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu
                                    chcete zamítnout tento timesheet?
                                    <br/>

                                    <GovFormControl>
                                        <GovFormGroup>
                                            <GovFormInput size="m" variant="secondary" input-type="text"
                                                          multiline="true"
                                                          placeholder="Důvod zamítnutí"
                                                          rows="3"

                                                          value={note}
                                                          onGov-input={handleNoteChange}></GovFormInput>
                                        </GovFormGroup>
                                        <GovFormLabel slot="top" size="m">Pro zamítnutí je nutné vyplnit
                                            důvod</GovFormLabel>
                                    </GovFormControl>

                                    <GovButton onGov-click={closeRejectModal} variant="primary" size="m"
                                               type="base"
                                               slot="actions"
                                               wcag-label="Cancel delete file my-photo.jpg">Zrušit
                                    </GovButton>
                                    <GovButton variant="error" size="m" type="solid" slot="actions"
                                               disabled={note === ""}
                                               wcag-label="Potvrdit smazání" onGov-click={declineTimesheet}>Zamítnout
                                    </GovButton>
                                </GovPrompt>
                            </div>
                            <div className="ml-30">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Pracovní fond:</th>
                                        <td>{monthReport ? monthReport.workFund + " hodin" :
                                            <GovSkeleton wcag-label="Loading content of page"
                                                         variant="secondary"
                                                         shape="text"
                                                         animation="progress" count="1" width="300px"
                                                         height="30px"></GovSkeleton>}</td>
                                    </tr>
                                    <tr>
                                        <th>Celková odpracovaná doba:</th>
                                        <td>{calculateTotalWorkHours()} hodin</td>
                                    </tr>
                                    <tr>
                                        <th>Počet aktivit:</th>
                                        <td>{currentItems ? currentItems.length : 0}</td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <th>Utilizace:</th>*/}
                                    {/*    <td style={{color: calculateUtilization() < 50 ? 'red' : 'green'}}>*/}
                                    {/*        {calculateUtilization()} %*/}
                                    {/*    </td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    {authority !== "RC_EMPLOYEE" && monthReport &&
                                        <tr>
                                            <th>Utilizace:</th>
                                            <td style={{color: convertUtilizationToPercentage(monthReport.utilization) < 50 ? 'red' : 'green'}}>
                                                {convertUtilizationToPercentage(monthReport.utilization)} %
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <GovSpacer size="xl"></GovSpacer>
                        {currentItems ?
                            <table className="fixed" cellSpacing="0" cellPadding="0" width="100%">
                                <thead>
                                <tr>
                                    <th className="w-110px">
                                        Datum
                                        {sortDateOrder === "asc" ?
                                            <GovButton onGov-click={() => setSortDateOrder('desc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                            :
                                            <GovButton onGov-click={() => setSortDateOrder('asc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                        }
                                    </th>
                                    <th className="w-70px">Začátek</th>
                                    <th className="w-70px">Konec</th>
                                    <th className="w-110px">Odpracováno</th>
                                    <th className="w-70px">Cesta</th>
                                    <th className="w-150px">
                                        <div className="gov-flexbox--justify-start">
                                            <GovDropdown position="left" className="min-height-400">
                                                <GovButton className="padding-0" variant="primary" size="xs" type="base"
                                                           wcag-label="What exactly happens when you click?"
                                                           right-icon="basic/chevron-down">Typ výkazu
                                                    <GovIcon type="basic" name="chevron-down"
                                                             slot="right-icon"></GovIcon>
                                                </GovButton>
                                                <ul slot="list">
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="1"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["1"]}>
                                                                <GovFormLabel size="xs" slot="label">Administrativa
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="2"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["2"]}>
                                                                <GovFormLabel size="xs" slot="label">Obec
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="3"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["3"]}>
                                                                <GovFormLabel size="xs" slot="label">Projekt
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="7"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["7"]}>
                                                                <GovFormLabel size="xs" slot="label">Konzultace
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="4"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["4"]}>
                                                                <GovFormLabel size="xs" slot="label">Konzultace MMR
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="5"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["5"]}>
                                                                <GovFormLabel size="xs" slot="label">Školení
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="6"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["6"]}>
                                                                <GovFormLabel size="xs" slot="label">Cesta
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="-1"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["-1"]}>
                                                                <GovFormLabel size="xs" slot="label">Dovolená
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                </ul>
                                            </GovDropdown>
                                        </div>
                                    </th>
                                    <th className="w-200px">
                                        Vazba na entitu
                                    </th>
                                    <th className="w-250px">
                                        Popis vykonaných prací/činností
                                    </th>
                                    <th>
                                        Akce
                                    </th>

                                </tr>
                                </thead>

                                <tbody>
                                {currentItems.map((row, index) => (
                                    <tr key={index}>
                                        <td>{convertIsoToDateFormat(row.start)}</td>
                                        <td>{convertIsoToTime(row.start)}</td>
                                        <td>{convertIsoToTime(row.end)}</td>
                                        <td>{(calculateHoursWorkedFromIso(row.start, row.end))}</td>
                                        <td>{row.isTrip ? "Ano" : "Ne"}</td>
                                        <td>{(mapTimeSheetType(row.type))}</td>
                                        <td>{(row.project && row.type === 3) ?
                                            <a href={`/projects/detail/${row.project.id}`}>{row.project.id}</a> : (row.town && row.type === 2) ?
                                                <a href={`/towns/detail/${row.town.id}`}>{row.town.name + " (" + row.town.id + ")"}</a> : (row.preProjectConsultationId && row.type === 7) ?
                                                    <a href={`/new-consultation/detail/${row.preProjectConsultationId}`}>{row.preProjectConsultationId}</a> : "Aktivita nemá vazbu"}</td>
                                        <td>{row.description}</td>
                                        {((authority === "RC_EMPLOYEE" && row.workMonthReportState === "REJECTED_BY_SUPERVISOR")
                                                || (row.workMonthReportState === null)
                                                || (authority === "RC_LEADER" && (row.workMonthReportState === "CREATED" || row.workMonthReportState === "REJECTED_BY_HQ"))
                                                || (row.workMonthReportState !== "APPROVED_BY_HQ" && (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT"))
                                                || ((authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") && (currentDay <= 10 || currentDay >= 25))) &&
                                            <td>
                                                <GovButton variant="primary" type="link" size="s"
                                                           value={row.id}
                                                           onGov-click={openEditTimesheetModal}>
                                                    <GovIcon type="basic" name="pencil-square"
                                                             slot="left-icon"></GovIcon>
                                                </GovButton>

                                                <GovButton className="ml-5"
                                                           wcag-label="What exactly happens when you click?"
                                                           variant="error" type="link" size="s"
                                                           onGov-click={openDeleteModal}>
                                                    <GovIcon type="basic" name="trash" slot="left-icon"></GovIcon>
                                                </GovButton>
                                                <GovPrompt open={deleteModalOpen} onGov-close={closeDeleteModal}
                                                           label="Smazat aktitivtu" role="dialog" label-tag="h5"
                                                           wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu
                                                    chcete smazat tento výkaz práce?<br/>Tato operace je nevratná.
                                                    <GovButton onGov-click={closeDeleteModal} variant="primary" size="m"
                                                               type="base" slot="actions"
                                                               wcag-label="Cancel delete file my-photo.jpg">Zrušit
                                                    </GovButton>
                                                    <GovButton value={row.id} variant="error" size="m" type="solid"
                                                               slot="actions"
                                                               wcag-label="Potvrdit smazání"
                                                               onGov-click={deleteTimesheet}>Smazat
                                                    </GovButton>
                                                </GovPrompt>
                                            </td>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            : (
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                            )}
                    </div>
                </div>
            </div>
            {modalTimesheetOpen &&
                <TimesheetModal user={user} update={handleUpdating} timesheetId={currentTimesheetId} create={createTimesheet} onGovModalClose={closeEditModal}></TimesheetModal>
            }
            {documentIsLoading &&
                <div>
                    <GovLoading>Prosím vyčkejte soubor se generuje.</GovLoading>
                </div>
            }
        </div>
    )
}

