import Navigation from "../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs, GovButton,
    GovFormControl, GovFormFile,
    GovFormGroup,
    GovFormInput,
    GovFormLabel, GovFormRadio, GovFormSelect,
    GovIcon, GovLoading, GovModal, GovPrompt, GovSkeleton, GovSpacer
} from "@gov-design-system-ce/react";
import {Footer} from "../Footer";
import {useEffect, useState} from "react";
import navigation from "../Navigation";
import {useNavigate} from "react-router-dom";
import {
    convertIsoToCustomFormat,
    mapConsultationResult,
    externalRoleToText,
    convertIsoToCustomFormatForDocuments, convertIsoToDateFormat, getIcon, mapRating
} from "../utils/utils";
import {TownDetailModal} from "./TownDetailModal";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function VillageDetailContent({townId}) {
    let navigate = useNavigate();

    const [personName, setPersonName] = useState('');
    const [personSurname, setPersonSurname] = useState('');
    const [personRole, setPersonRole] = useState('');
    const [personPhone, setPersonPhone] = useState('');
    const [personInstitution, setPersonInstitution] = useState('');

    const [villageNote, setVillageNote] = useState('');

    const [currentId, setCurrentId] = useState(0);

    const [modalOpen, setModalOpen] = useState(false);
    const [noteModalOpen, setNoteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [townEditModalOpen, setTownEditModalOpen] = useState(false);
    const [town, setTown] = useState(null);
    const [documents, setDocuments] = useState([]);

    const [persons, setPersonArray]=useState([])
    const [preProjectConsultations, setPreProjectConsultations]=useState([])
    const [importedConsultations, setImportedConsultations]=useState([])
    const [projects, setProjects]=useState([])
    const [selectedConsultationId, setSelectedConsultationId]=useState()
    const [selectedProjectId, setSelectedProjectId]=useState('')
    const jwt = localStorage.getItem("jwt")

    const [nameInvalid, setNameInvalid] = useState(false)
    const [surnameInvalid, setSurnameInvalid] = useState(false)
    const [phoneInvalid, setPhoneInvalid] = useState(false)
    const [institutionInvalid, setInstitutionInvalid] = useState(false)
    const [roleInvalid, setRoleInvalid] = useState(false)

    const [documentIsLoading, setDocumentIsLoading] = useState(false);

    const [personValid, setPersonValid] = useState(true)

    function fetchDocument(documentID, documentName) {
        fetch(`${process.env.REACT_APP_API_URL}/document/${documentID}/download`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                // Check if the MIME type of the blob is a PDF or CSV
                if (blob.type === 'application/pdf') {
                    // Open the file in a new tab
                    window.open(url);
                } else {
                    // Download the file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = documentName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);

                }
            })
    }

    function deleteDocument(documentID) {
        fetch(process.env.REACT_APP_API_URL+"/document/" + documentID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response;
            })
            .then(jsonData => {
                console.log(jsonData)
                fetchData()
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const translateRoleToEnum = (roleName) => {
        // eslint-disable-next-line default-case
        switch (roleName) {
            case "Statutární zástupce":
                return "STATUTORY_DEPUTY";
            case "Zmocněnec":
                return "DELEGATE";
            case "Nahlížející osoba":
                return "OBSERVER";
            case "Anonymní uživatel":
                return "ANONYMOUS";
            case "Specialista RC":
                return "RC_EMPLOYEE";
            case "Manažer RC":
                return "RC_LEADER";
            case "Expert":
                return "EXPERT";
            case "Centrála":
                return "HEADQUARTERS";
            case "Centrála+":
                return "HQ_WITH_REPORT"
            case "Neověřený uživatel":
                return "NON_ACTIVE";
            default:
                return null; // Neznámá role
        }
    };

    const translateEnumToRole = (role) => {
        // eslint-disable-next-line default-case
        switch (role) {
            case "STATUTORY_DEPUTY":
                return "Statutární zástupce"
            case "DELEGATE":
                return "Zmocněnec"
            case "OBSERVER":
                return "Nahlížející osoba"
            case "ANONYMOUS":
                return "Anonymní uživatel"
            case "RC_EMPLOYEE":
                return "Specialista RC"
            case "RC_LEADER":
                return "Manažer RC"
            case "EXPERT":
                return "Expert"
            case "HEADQUARTERS":
                return "Centrála"
            case "HQ_WITH_REPORT":
                return "Centrála+"
            case "NON_ACTIVE":
                return "Neověřený uživatel"
        }
    }

    function fetchData(){
        fetch(process.env.REACT_APP_API_URL+"/towns/"+ townId, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
                setTown(jsonData)
                setVillageNote(jsonData.notes) //towns/{townId}/notes PUT
                setPersonArray(jsonData.externalPersons)
                setImportedConsultations(jsonData.consultations)
                const showMoreInitial = new Array(jsonData.consultations.length).fill(false);
                setShowMore(showMoreInitial);
                setDocuments(jsonData.documents)
                console.log(jsonData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const fetchPreProjectConsultationsData = () => {
        fetch(process.env.REACT_APP_API_URL+"/town/"+ townId + "/preProjectConsultations", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setPreProjectConsultations(jsonData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {

        const fetchTownProjects = () => {
            fetch(process.env.REACT_APP_API_URL + "/projects"+"?townId=" + townId, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(jsonData => {
                    setProjects(jsonData);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };

        fetchData();
        fetchPreProjectConsultationsData()
        fetchTownProjects();
    }, []);

    function addPerson(){
        let newItem = {
            id: crypto.randomUUID(),
            name: personName,
            surname: personSurname,
            role: personRole,
        }
        setPersonArray([...persons, newItem]);
        setPersonName("")
        setPersonSurname("")
        setPersonRole("")
        setPersonPhone("")
        setPersonInstitution("")
        closeModal()
    }

    function checkErrors() {
        let ready = !nameInvalid && !surnameInvalid && !phoneInvalid && !roleInvalid && !institutionInvalid
        setPersonValid(ready)
    }

    function editPerson(){
        if (personValid) {
            fetch(process.env.REACT_APP_API_URL + `/externalPersons/${currentId}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify(
                    {
                        firstname: personName,
                        lastname: personSurname,
                        role: translateRoleToEnum(personRole),
                        phone: personPhone,
                        institution: personInstitution
                    }
                )
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    fetchData();
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
            setPersonName("")
            setPersonSurname("")
            setPersonRole("")
            setPersonPhone("")
            setPersonInstitution("")
            closeEditModal()
        }
    }

    function deletePerson(uuid){
        const updatedArray = persons.filter(person => person.id !== uuid);
        setPersonArray(updatedArray);
    }
    function openModal(){
        setModalOpen(true)
    }
    function closeModal(){
        setModalOpen(false)
    }
    function openNoteModal(){
        setNoteModalOpen(true)
    }
    function closeNoteModal(){
        setNoteModalOpen(false)
    }
    function openTownEditModal() {
        setTownEditModalOpen(true)
    }
    function closeTownEditModal() {
        setTownEditModalOpen(false)
        fetchData()
    }
    const [connectConsultationModal, setConnectConsultationModal] = useState(false);

    function openConnectConsultationModal() {
        setConnectConsultationModal(true)
    }

    function closeConsultationModal() {
        setConnectConsultationModal(false)
    }
    function updateNote(){
        setNoteModalOpen(false)
        putUpdatedNote().then(() => fetchData())
    }

    function patchConsultationToProject() {
        return fetch(process.env.REACT_APP_API_URL+ "/preProjectConsultations/" + selectedConsultationId, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify({
                "projectId": selectedProjectId,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    function connectConsultationToProject() {
        patchConsultationToProject().then(result => {
            fetchPreProjectConsultationsData()
            closeConsultationModal()
        }
        )

    }

    function putUpdatedNote() {
        return fetch(process.env.REACT_APP_API_URL+ "/towns/" + townId + "/comment", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify({
                "comment": villageNote,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                fetchData()
            })
            .catch(error => {
                return error.message;
            });
    }

    const openEditModal = (event) => {
        setCurrentId(event.detail.ref.value)
        const person = persons.find(object => object.id === event.detail.ref.value)
        setPersonName(person.firstname)
        setPersonSurname(person.lastname)
        setPersonRole(translateEnumToRole(person.role))
        setPersonPhone(person.phone)
        setPersonInstitution(person.institution)
        setEditModalOpen(true)
    }

    const closeEditModal = (event) => {
        setEditModalOpen(false)
    }

    const handlePersonNameChange = (event) => {
        if (event.target.value === "") {
            setNameInvalid(true)
        } else {
            setNameInvalid(false)
        }
        setPersonName(event.target.value);
    };
    const handleNoteChange = (event) => {
        setVillageNote(event.target.value);
    };
    const handlePersonSurnameChange = (event) => {
        if (event.target.value === "") {
            setSurnameInvalid(true)
        } else {
            setSurnameInvalid(false)
        }
        setPersonSurname(event.target.value);
    };
    const handlePersonRoleChange = (event) => {
        setPersonRole(event.target.value);
    };
    const handlePersonPhoneChange = (event) => {
        if (!/^\d{9}$/.test(event.target.value)) {
            setPhoneInvalid(true)
        } else {
            setPhoneInvalid(false)
        }
        setPersonPhone(event.target.value);
    };
    const handlePersonInstitutionChange = (event) => {
        if (event.target.value === "") {
            setInstitutionInvalid(true)
        } else {
            setInstitutionInvalid(false)
        }
        setPersonInstitution(event.target.value);
    };
    useEffect(() => {
        checkErrors()
    }, [nameInvalid, surnameInvalid, phoneInvalid, institutionInvalid]);
    function navigateToProjectSearch(townId) {
        navigate("/projects?townId=" + townId)
    }

    const handleProjectIdChange = (event) => {
        setSelectedProjectId(event.target.value);
    };

    function selectConsultation(id) {
        setSelectedConsultationId(id)
        openConnectConsultationModal()
    }

    function phonePrettier(phone) {
        return phone.replace(/(?=(?:.{3})*$)/g, ' ')
    }

    const [showMore, setShowMore] = useState([]);

    const [sortConsultationsByStartTimeOrder, setSortConsultationsByStartTimeOrder] = useState('asc');

    const sortConsultationsByStartTime = () => {
        if(preProjectConsultations){
            let sortedConsultations = [...preProjectConsultations];
            if (sortConsultationsByStartTimeOrder === 'asc') {
                sortedConsultations.sort((a, b) => new Date(a.start) - new Date(b.start));
            } else {
                sortedConsultations.sort((a, b) => new Date(b.start) - new Date(a.start));
            }
            setPreProjectConsultations(sortedConsultations);
        }
    };

    function uploadDocument(file, type) {
        setDocumentIsLoading(true)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('targetEntityId', town.id);
        formData.append('type', parseInt(7));
        formData.append('target', "TOWN");

        fetch(`${process.env.REACT_APP_API_URL}/document/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setDocumentIsLoading(false)
                console.log(jsonData);
                fetchData();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        sortConsultationsByStartTime();
    }, [sortConsultationsByStartTimeOrder]);

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const generateTownPDF = () => {
        const documentDefinition = {
            content: [
                { text: `Karta obce - ${town.name}`, style: 'header' },
                { text: `Kód obce: ${town.id}`, style: 'subheader' },
                { text: `Počet obyvatel:`, style: 'h4' },
                { text: `${town.population ? town.population : "Počet obyvatel není uveden"}` },
                { text: `Rozloha v ha:`, style: 'h4' },
                { text: `${town.area ? town.area : "Rozloha není uvedena"}` },
                { text: `Název okresu:`, style: 'h4' },
                { text: `${town.district.name}` },
                { text: `Název kraje:`, style: 'h4' },
                { text: `${town.region.name}` },
                { text: `Počet obecních bytových jednotek:`, style: 'h4' },
                { text: `${town.councilHousingUnitsCount ? town.councilHousingUnitsCount : "Údaj není uveden"}` },
                { text: `Tržní nájemné v obci (Kč/m2/měs):`, style: 'h4' },
                { text: `${town.marketRent ? town.marketRent : "Údaj není uveden"}` },
                { text: `Obecní nájemné v obci (Kč/m2/měs):`, style: 'h4' },
                { text: `${town.councilRent ? town.councilRent : "Údaj není uveden"}` },
                { text: `Poznámky k obci:`, style: 'h4' },
                { text: `${town.notes ? town.notes : "Údaj není uveden"}` },

                ...(town.townContactEvent !== null) ? [{ text: `Oslovení obce`, style: 'h4' }, {text: `Obec byla oslovena`}] : [],
                ...(town.townContactEvent !== null) ? [{ text: `Datum oslovení`, style: 'h4' }, {text: `${town.townContactEvent !== null ? convertIsoToDateFormat(town.townContactEvent.date) : "Obec nebyla oslovena"}`}] : [],
                ...(town.townContactEvent !== null) ? [{ text: `Obec oslovil`, style: 'h4' }, {text: `${town.townContactEvent.employeeFirstname} ${town.townContactEvent.employeeLastname}`}] : [],
                ...(town.townContactEvent !== null) ? [{ text: `Zájem o další spolupráci`, style: 'h4' }, {text: `${town.townContactEvent.interested === true ? "Obec má zájem o další spolupráci" : "Obec nemá zájem o další spolupráci"}`}] : [],
                { text: `Spolupráce s ASZ:`, style: 'h4' },
                { text: `${town.aszCooperation === true ? "Obec spolupracuje s ASZ" : "Obec nespolupracuje s ASZ"}` },
                { text: `Hodnocení kvality obecního bytového fondu:`, style: 'h4' },
                { text: `${mapRating(town.housingFundRating)}` },
                { text: `Tento dokument byl vygenerován informačním systémem Státního fondu podpory investic dne ${convertIsoToCustomFormatForDocuments(new Date())}.`, style: 'footer' },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                text: {
                    margin: [0, 2, 0, 0]
                },
                h4: {
                    bold: true,
                    margin: [0, 7, 0, 2]
                },
                footer: {
                    fontSize: 10,
                    margin: [0, 10, 0, 0]
                }
            }
        };

        pdfMake.createPdf(documentDefinition).download(`SFPI - Karta obce - ${town.name}.pdf`);
    };

    return (
        <div>
            <Navigation active={"villages"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/towns/search">Obce</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            {town ?
                                <strong>{town.name}</strong>:
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px"
                                             height="30px"></GovSkeleton>
                            }
                        </li>

                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">
                        <div className="gov-title--beta">Detail obce</div>
                        <div className="form-help-layout gov-tabs">
                            <div className="gov-tabs__content-holder ">
                                {town ?
                                    <div className="gov-tabs__content is-active" role="tabpanel">
                                        <div className="project-detail">
                                            {town && town.townCollective &&
                                                <div>
                                                    <h4>Obec je v dobrovolném sdružení obcí</h4>
                                                    <p><a
                                                        href={`/towns/collective/detail/${town.townCollective.id}`}>{town.townCollective.name}</a>
                                                    </p>
                                                </div>
                                            }
                                            <div>
                                                <h4>Název obce</h4>
                                                <p>{town.name}</p>
                                            </div>
                                            <div>
                                                <h4>Kód obce</h4>
                                                <p>{town.id}</p>
                                            </div>
                                            <div>
                                                <h4>Počet obyvatel</h4>
                                                <p>{town.population ? town.population : "Počet obyvatel není uveden"}</p>
                                            </div>
                                            <div>
                                                <h4>Rozloha v ha</h4>
                                                <p>{town.area ? town.area : "Rozloha není uvedena"}</p>
                                            </div>
                                            <div>
                                                <h4>Název okresu</h4>
                                                <p>{town.district.name}</p>
                                            </div>
                                            <div>
                                                <h4>Název kraje</h4>
                                                <p>{town.region.name}</p>
                                            </div>
                                            <div>
                                                <h4>Obec s rozšířenou působností</h4>
                                                <p><a href={`/towns/detail/${town.orp.id}`}>{town.orp.name + " (" + town.orp.id + ")"}</a></p>
                                            </div>
                                            <div>
                                                <h4>Počet obecních bytových jednotek</h4>
                                                <p>{town.councilHousingUnitsCount !== null ? town.councilHousingUnitsCount : "Údaj není uveden"}</p>
                                            </div>
                                            <div>
                                                <h4>Tržní nájemné v obci (Kč/m<sup>2</sup>/měs)</h4>
                                                <p>{town.marketRent !== null ? town.marketRent : "Údaj není uveden"}</p>
                                            </div>
                                            <div>
                                                <h4>Obecní nájemné v obci (Kč/m<sup>2</sup>/měs)</h4>
                                                <p>{town.councilRent !== null ? town.councilRent : "Údaj není uveden"}</p>
                                            </div>
                                            {town.townContactEvent !== null &&
                                                <div>
                                                    <h4>Oslovení obce</h4>
                                                    <p>{town.townContactEvent !== null ? "Obec byla oslovena" : "Obec nebyla oslovena"}</p>
                                                </div>
                                            }
                                            {town.townContactEvent !== null &&
                                                <div>
                                                    <h4>Datum oslovení</h4>
                                                    <p>{town.townContactEvent !== null ? convertIsoToDateFormat(town.townContactEvent.date) : "Obec nebyla oslovena"}</p>
                                                </div>
                                            }
                                            {town.townContactEvent !== null &&
                                                <div>
                                                    <h4>Obec oslovil</h4>
                                                    <p>{town.townContactEvent.employeeFirstname !== null ? `${town.townContactEvent.employeeFirstname} ${town.townContactEvent.employeeLastname}` : "Obec nebyla oslovena"}</p>
                                                </div>
                                            }
                                            {town.townContactEvent !== null &&
                                                <div>
                                                    <h4>Zájem o další spolupráci</h4>
                                                    <p>{town.townContactEvent.interested === true ? "Obec má zájem o další spolupráci" : "Obec nemá zájem o další spolupráci"}</p>
                                                </div>
                                            }
                                            <div>
                                                <h4>Spolupráce s ASZ</h4>
                                                <p>{town.aszCooperation === true ? "Obec spolupracuje s ASZ" : "Obec nespolupracuje s ASZ"}</p>
                                            </div>
                                            <div>
                                                <h4>Hodnocení kvality obecního bytového fondu</h4>
                                                <p>{mapRating(town.housingFundRating)}</p>
                                            </div>


                                        </div>
                                        <GovSpacer size="m"></GovSpacer>
                                        <div className="gov-flexbox flex-space-between ">
                                        <GovButton wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       onGov-click={generateTownPDF}
                                                       type="solid" size="m">
                                                Stáhnout kartu obce
                                            </GovButton>
                                            {/*<GovButton wcag-label="What exactly happens when you click?"*/}
                                            {/*           variant="primary"*/}
                                            {/*           type="solid" size="m">*/}
                                            {/*    Nahrát kartu obce*/}
                                            {/*</GovButton>*/}
                                            <GovButton wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="solid" size="m"
                                                       onGov-click={() => navigateToProjectSearch(townId)}>
                                                Zobrazit projekty
                                            </GovButton>
                                            <GovButton wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="solid" size="m"
                                                       onGov-click={() => navigate("/new-consultation/" + town.id)}>
                                                Založit konzultaci
                                            </GovButton>
                                            <GovButton wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="solid" size="m"
                                                       onGov-click={() => navigate("/new-project/" + town.id)}>
                                                Založit projekt
                                            </GovButton>
                                            <GovButton wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="solid" size="m"
                                                       onGov-click={() => openTownEditModal()}>
                                                Doplnit údaje o obci
                                            </GovButton>
                                        </div>
                                    </div>
                                    :
                                    <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                                 animation="progress" count="1" width="300px"
                                                 height="30px"></GovSkeleton>
                                }
                            </div>
                        </div>
                        <div className="gov-flexbox">
                            <div className="gov-title--beta">Konzultace</div>
                        </div>
                        <table cellSpacing="0" cellPadding="0">
                            <thead>
                            <tr>
                                <th>Datum konání
                                    {sortConsultationsByStartTimeOrder === "asc" ?
                                        <GovButton onGov-click={() => setSortConsultationsByStartTimeOrder('desc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                        :
                                        <GovButton onGov-click={() => setSortConsultationsByStartTimeOrder('asc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                    }
                                </th>
                                <th>Výsledek</th>
                                <th>Odkaz na konzultaci</th>
                                <th>Připojit konzultaci k projektu</th>
                            </tr>
                            </thead>
                            <tbody>
                            {preProjectConsultations.map((row, index) => (
                                <tr key={index}>
                                    <td className="first">{convertIsoToCustomFormat(row.start)}</td>
                                    <td>{mapConsultationResult(row.result)}</td>
                                    <td><a value={index} variant="primary" type="link" size="m"
                                           href={`/new-consultation/detail/${row.id}`}>Detail konzultace</a></td>
                                    <td>{row.projectId === null ?
                                        <GovButton value={index} variant="primary" type="solid" size="m"
                                                   onGov-click={() => {
                                                       selectConsultation(row.id)
                                                   }}>Připojit</GovButton> :
                                        <a href={`/projects/detail/${row.projectId}`}>Odkaz na projekt</a>

                                    }
                                    </td>

                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <GovSpacer size="m"></GovSpacer>

                        {town && town.consultations.length > 0 &&
                            <div>
                                <div className="gov-flexbox">
                                    <div className="gov-title--beta">Expertní konzultace</div>
                                </div>

                                <table cellSpacing="0" cellPadding="0">
                                    <thead>
                                    <tr>
                                        <th>ID konzultace</th>
                                        <th>Popis</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {importedConsultations.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.id}</td>
                                            <td>
                                                <div
                                                    dangerouslySetInnerHTML={{__html: showMore[index] ? row.description : `${row.description.substring(0, 150)} ...`}}>
                                                </div>
                                            </td>
                                            <td>{showMore[index] ?
                                                <button onClick={() => {
                                                    const newShowMore = [...showMore];
                                                    newShowMore[index] = false;
                                                    setShowMore(newShowMore);
                                                }}>
                                                    Skrýt popis
                                                </button> :
                                                <button onClick={() => {
                                                    const newShowMore = [...showMore];
                                                    newShowMore[index] = true;
                                                    setShowMore(newShowMore);
                                                }}>
                                                    Zobrazit celý popis
                                                </button>
                                            }</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                <GovSpacer size="m"></GovSpacer>
                            </div>
                        }
                        <div className="gov-flexbox">
                            <div className="gov-title--beta">Uživatelé v obci</div>
                        </div>
                        <table cellSpacing="0" cellPadding="0">
                            <thead>
                            <tr>
                                <th>Jméno</th>
                                <th>Příjmení</th>
                                <th>Email</th>
                                <th>Telefon</th>
                                <th>Instituce</th>
                                <th>Role</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {persons.map((row, index) => (
                                <tr key={index}>
                                    <td className="first">{row.firstname}</td>
                                    <td>{row.lastname}</td>
                                    <td>{row.email}</td>
                                    <td>+420 {phonePrettier(row.phone)}</td>
                                    <td>{row.institution}</td>
                                    <td>{externalRoleToText(row.role)}</td>
                                    <td><GovButton value={row.id} variant="primary" type="solid" size="m"
                                                   onGov-click={openEditModal}>Upravit</GovButton></td>

                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <GovSpacer size="m"></GovSpacer>
                        <div className="gov-flexbox">
                            <div className="gov-title--beta">Poznámky regionálního centra</div>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                       type="solid" size="m" onGov-click={openNoteModal}>
                                Upravit
                            </GovButton>
                        </div>
                        {town && <p>{town.notes}</p>}
                        <GovSpacer size="m"></GovSpacer>
                        <div className="gov-flexbox">
                            <div className="gov-title--beta">Dokumenty</div>
                        </div>
                        <div>
                            {documentIsLoading &&
                                <div>
                                    <GovLoading>Prosím vyčkejte soubor se nahrává</GovLoading>
                                </div>
                            }

                            {documents.map((document, index) => {
                                return (
                                    <div className="mt-10 mb-10">
                                        <h3>{document.typeValue}</h3>
                                        <FontAwesomeIcon icon={getIcon(document.name)}/>
                                        <span> </span>
                                        <a key={index} href={document.link} onClick={(event) => {
                                            event.preventDefault();
                                            fetchDocument(document.id, document.name);
                                        }}>
                                            {document.name}
                                        </a>
                                        <GovButton className="ml-5"
                                                   wcag-label="What exactly happens when you click?"
                                                   variant="primary"
                                                   type="solid" size="xs"
                                                   onGov-click={() => deleteDocument(document.id)}>
                                            Smazat soubor
                                        </GovButton>
                                    </div>
                                );
                            })}
                            <GovAccordionItem variant="primary" size="xs">
                                <div className="mb-10">
                                    <h3>Obecný dokument</h3>
                                    <div className="sfpi-document-layout">
                                        <div><p>{`Přiložte - dokument`} </p>
                                        </div>
                                        <GovFormFile
                                            onGov-add-file={(event) => uploadDocument(event.detail.file.file, 7)}
                                            max-file-size="616448"
                                            accept=".pdf,.xls,.xlsx,.doc,.docx"
                                            expanded="true"
                                            displayAttachments="false">
                                            <p>
                                                <GovButton variant="primary" size="m"
                                                           type="outlined">Nahrát soubor ze
                                                    zařízení
                                                </GovButton>
                                            </p>
                                            <GovSpacer size="m"></GovSpacer>
                                            <p className="gov-text--s">Podporované formáty
                                                PDF,
                                                DOC, XLS</p></GovFormFile>
                                    </div>
                                </div>
                                <p slot="label">Nahrání dokumentu</p>
                            </GovAccordionItem>
                        </div>


                    </div>
                </div>
            </div>
            {townEditModalOpen &&
                <TownDetailModal town={town} onGovModalClose={closeTownEditModal}></TownDetailModal>
            }
            <GovModal open={connectConsultationModal} onGov-close={closeConsultationModal}
                      label="Připojit konzultaci k projektu"
                      wcag-close-label="Close dialog box with more information">
                <h4>Konzultace</h4>
                <p>{selectedConsultationId}</p>

                <GovFormControl>
                    <GovFormSelect value={selectedProjectId} onGov-change={handleProjectIdChange}
                                   size="s" variant="secondary">
                        <option disabled="disabled" value="">Projekt</option>
                        {projects.length > 0 && projects.map((project) => (
                            <option key={project.id}
                                    value={project.id}>{project.name + " " + project.id}</option>
                        ))
                        }
                    </GovFormSelect>
                    <GovFormLabel slot="top" size="s">Projekt, ke kterému se má konzultace připojit*</GovFormLabel>
                </GovFormControl>
                <GovButton variant="primary" size="m" type="solid"
                           wcag-label="Open dialog box for more information"
                           onGov-click={connectConsultationToProject}>Připojit
                </GovButton>
            </GovModal>
            <GovModal open={editModalOpen} onGov-close={closeEditModal}
                      label="Editace osoby"
                      wcag-close-label="Close dialog box with more information">
                <GovFormControl>
                    <GovFormInput value={personName} onGov-input={handlePersonNameChange}
                                  size="s" variant="secondary"
                                  input-type="text"
                                  placeholder="Jméno"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Jméno*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={personSurname} onGov-input={handlePersonSurnameChange}
                                  size="s" variant="secondary"
                                  input-type="text" placeholder="Příjmení"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Příjmení*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={personPhone} onGov-input={handlePersonPhoneChange}
                                  size="s" variant="secondary"
                                  input-type="text" placeholder="Telefon"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Telefon*</GovFormLabel>
                </GovFormControl>
                <GovFormControl>
                    <GovFormInput value={personInstitution} onGov-input={handlePersonInstitutionChange}
                                  size="s" variant="secondary"
                                  input-type="text" placeholder="Instituce"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Instituce*</GovFormLabel>
                </GovFormControl>
                <div className="sfpi-title-section mt-20">Role
                </div>
                <GovFormControl>
                    <GovFormGroup gap="s">
                        <GovFormRadio checked={personRole === "Statutární zástupce"}
                                      onGov-change={handlePersonRoleChange}
                                      value="Statutární zástupce" size="s"
                                      name="list-radio">
                            <GovFormLabel size="s" slot="label">Statutární zástupce</GovFormLabel>
                        </GovFormRadio>
                        <GovFormRadio checked={personRole === "Zmocněnec"} onGov-change={handlePersonRoleChange}
                                      value="Zmocněnec"
                                      size="s"
                                      name="list-radio">
                            <GovFormLabel size="s" slot="label">Zmocněnec</GovFormLabel>
                        </GovFormRadio>
                        <GovFormRadio checked={personRole === "Nahlížející osoba"} onGov-change={handlePersonRoleChange}
                                      value="Nahlížející osoba" size="s" name="list-radio">
                            <GovFormLabel size="s" slot="label">Nahlížející osoba</GovFormLabel>
                        </GovFormRadio>
                        <GovFormRadio checked={personRole === "Kontaktní osoba"} onGov-change={handlePersonRoleChange}
                                      value="Kontaktní osoba"
                                      size="s" name="list-radio">
                            <GovFormLabel size="s" slot="label">Kontaktní osoba</GovFormLabel>
                        </GovFormRadio>
                    </GovFormGroup>
                </GovFormControl>
                <GovButton variant="primary" size="m" type="solid"
                           wcag-label="Open dialog box for more information"
                           onGov-click={editPerson}
                           disabled={!personValid}
                >Upravit
                </GovButton>
            </GovModal>
            <GovModal open={noteModalOpen} onGov-close={closeNoteModal}
                      label="Úprava poznámky regionálního centra"
                      wcag-close-label="Close dialog box with more information">
                <GovFormControl>
                    <GovFormInput value={villageNote} onGov-input={handleNoteChange}
                                  size="s" variant="secondary"
                                  input-type="text"
                                  placeholder="Poznámka" multiline="true" rows="10"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Poznámka*</GovFormLabel>
                </GovFormControl>
                <GovButton variant="primary" size="m" type="solid"
                           wcag-label="Open dialog box for more information"
                           onGov-click={updateNote}>Upravit
                </GovButton>
            </GovModal>


        </div>
    )
}
