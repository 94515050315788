import "../components/css/layouts.css"
import {GovButton} from "@gov-design-system-ce/react";
import {parseJwt} from "./utils/utils";
const Navigation = (active) => {
    const user = parseJwt(localStorage.getItem("jwt"))
    let role
    if(user !== null){
        role = user.role[0].authority
    }

    // Check user rights here and decide which component to render
    if (user && (role === "RC_EMPLOYEE" || role ==="RC_LEADER" || role === "HEADQUARTERS" || role === "HQ_WITH_REPORT")) {
        return (<div className=" gov-container--no-y-offset gov-header">
            <div className="gov-container__content">
                <nav className="gov-header__nav">
                    <div className="flex-space-between">
                        <div className="gov-header__nav-holder">
                            <div className={"gov-header__link " + (active.active === "home" ? "active" : "")}><a
                                href="/"
                                className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Úvod</a>
                            </div>
                            <div className={"gov-header__link " + (active.active === "projects" ? "active" : "")}><a
                                href="/projects"
                                className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Projekty</a>
                            </div>
                            <div className={"gov-header__link " + (active.active === "villages" ? "active" : "")}><a
                                href="/towns/search"
                                className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Obce</a>
                            </div>
                            <div
                                className={"gov-header__link " + (active.active === "road-compensation" ? "active" : "")}>
                                <a href="/road-compensations"
                                   className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Cestovní
                                    náhrady</a>
                            </div>
                            <div
                                className={"gov-header__link " + (active.active === "timesheet" ? "active" : "")}>
                                <a href="/timesheet"
                                   className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Timesheet</a>
                            </div>
                            {role !== "RC_EMPLOYEE" &&
                                <div
                                    className={"gov-header__link " + (active.active === "user-management" ? "active" : "")}>
                                    <a href="/user-management"
                                       className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Správa
                                        uživatelů</a>
                                </div>
                            }
                            {role !== "RC_EMPLOYEE" &&
                                <div
                                    className={"gov-header__link " + (active.active === "report-management" ? "active" : "")}>
                                    <a href="/report-management"
                                       className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Správa
                                        výkaznictví</a>
                                </div>
                            }

                            {/*<div className={"gov-header__link "+(active.active === "reports" ? "active" : "")}><a href="/reports"*/}
                            {/*                                      className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Výkazy</a>*/}
                            {/*</div>*/}
                            {/*<div className={"gov-header__link "+(active.active === "reporting" ? "active" : "")}><a href="/reporting"*/}
                            {/*                                      className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Reporting</a>*/}
                            {/*</div>*/}
                        </div>
                        {/*<GovButton variant={'primary'} type={'outlined'} inverse={"true"}>*/}
                        {/*        Oznámení*/}
                        {/*    </GovButton>*/}
                    </div>
                </nav>
            </div>
        </div>)
    } else {
        return (
            <div className=" gov-container--no-y-offset gov-header">
                <div className="gov-container__content">
                <nav className="gov-header__nav">
                    <div className="gov-header__nav-holder">
                    <div className="gov-header__link active"><a href="/"
                                                                className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Úvod</a>
                    </div>
                    {/*<div className="gov-header__link "><a href="/faq"*/}
                    {/*                                      className="gov-link gov-link--standalone gov-link--large gov-link--inversed">Časté*/}
                    {/*    dotazy</a></div>*/}
                    </div>
                </nav>
            </div>
        </div>)
    }
};

export default Navigation;



