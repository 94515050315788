import Navigation from "../Navigation";
import {
    GovBreadcrumbs, GovButton, GovFormAutocomplete,
    GovFormControl,
    GovFormInput,
    GovFormLabel, GovFormMultiSelect, GovFormSelect,
    GovIcon, GovMessage, GovPagination, GovSkeleton,
    GovSpacer
} from "@gov-design-system-ce/react";
import '../css/search-village.css';
import {useEffect, useState} from "react";
import {convertIsoToDateFormat, convertIsoToCustomFormat, parseJwt} from "../utils/utils";



export function ProjectsContent()  {
    const allRegions = [
        "Hlavní město Praha",
        "Středočeský kraj",
        "Jihočeský kraj",
        "Plzeňský kraj",
        "Karlovarský kraj",
        "Ústecký kraj",
        "Liberecký kraj",
        "Královehradecký kraj",
        "Pardubický kraj",
        "Kraj Vysočina",
        "Jihomoravský kraj",
        "Olomoucký kraj",
        "Moravskoslezský kraj",
        "Zlínský kraj"
    ];
    const allRegionalCenters = [
        "Moravskoslezský kraj",
        "Středočeský kraj a Praha",
        "Ústecký a Karlovarský kraj",
        "Královehradecký a Liberecký kraj",
        "Pardubický kraj a kraj Vysočina",
        "Jihočeský kraj a Plzeňský kraj",
        "Zlínský a Jihomoravský kraj",
        "Olomoucký kraj"
    ];
    const regionalCentre = localStorage.getItem("RCname");
    const regionalCentreId = localStorage.getItem("RCid");
    const jwt = localStorage.getItem("jwt")
    const [projects, setProjects] = useState(null);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const handlePageChange = (event) => {
        setCurrentPage(event.detail.pagination.currentPage);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [letterChosen, setLetterChosen] = useState(false);
    const [town,setTown] = useState();
    const itemsPerPage = 20;
    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let request;
    if (urlParams.get("townId")) {
        request = "?townId=" + urlParams.get("townId");
    }
    else if (urlParams.get("regionalCenterId") && urlParams.get("regionalCenterId") === "0" && (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT")) {
            request = "";
    } else if (urlParams.get("regionalCenterId") || (authority !== "HEADQUARTERS" && authority !== "HQ_WITH_REPORT")){
        request = "?regionalCenterId=" + (urlParams.get("regionalCenterId") ? urlParams.get("regionalCenterId") : RCid);
    }

else {
        request = "";
    }


    function getRegionalCenterByRegionName(regionName) {
        if (regionName === "Hlavní město Praha") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Moravskoslezský kraj") {
            return "Moravskoslezský kraj"
        } else if (regionName === "Olomoucký kraj") {
            return "Olomoucký kraj"
        } else if (regionName === "Pardubický kraj") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Kraj Vysočina") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Zlínský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Královéhradecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Ústecký kraj") {
            return "Ústecký a Karlovarský kraj"
        } else if (regionName === "Plzeňský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Liberecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Jihomoravský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Jihočeský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Středočeský kraj") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Karlovarský kraj") {
            return "Ústecký a Karlovarský kraj"
        }
    }


    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(null);
    const handleSearchChange = (event) => {
        setCurrentPage(1)
        setSearchTerm(event.target.value);
        const filtered = projects.filter(project =>
            project.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
            project.id.toLowerCase().includes(event.target.value.toString().toLowerCase())
        );
        setFilteredItems(filtered);

    };
    const handleLetterClick = (letter) => {
        setCurrentPage(1)
        if(letter === ''){
            setLetterChosen(false)
        }
        else {
            setLetterChosen(true)
        }
        setSearchTerm(letter);
        if(projects.length > 0) {
            const filtered = projects.filter(project =>
                project.name.toLowerCase().startsWith(letter.toLowerCase())
            );
            setFilteredItems(filtered);
        }

    };
    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;

    const [sortTownsOrder, setSortTownsOrder] = useState('asc');
    const sortTowns = () => {
        if(projects){
            let sortedTowns = [...filteredItems];
            if (sortTownsOrder === 'asc') {
                sortedTowns.sort((a, b) => a.name.localeCompare(b.name));
            } else {
                sortedTowns.sort((a, b) => b.name.localeCompare(a.name));
            }
            setFilteredItems(sortedTowns);
        }

    };
    useEffect(() => {
        sortTowns();
    }, [sortTownsOrder]);

    const [sortProjectsIdOrder, setSortProjectsIdOrder] = useState('asc');

    const sortProjectsId = () => {
        if(projects){
            let sortedTowns = [...filteredItems];
            if (sortProjectsIdOrder === 'asc') {
                sortedTowns.sort((a, b) => a.id.localeCompare(b.id));
            } else {
                sortedTowns.sort((a, b) => b.id.localeCompare(a.id));
            }
            setFilteredItems(sortedTowns);
        }

    };
    useEffect(() => {
        sortProjectsId();
    }, [sortProjectsIdOrder]);

    const [sortProjectsCreationDateOrder, setSortProjectsCreationDateOrder] = useState('asc');


    const sortProjectsByCreationDate = () => {
        if(projects){
            let sortedProjects = [...filteredItems];
            if (sortProjectsCreationDateOrder === 'asc') {
                sortedProjects.sort((a, b) => new Date(a.creationDate) - new Date(b.creationDate));
            } else {
                sortedProjects.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
            }
            setFilteredItems(sortedProjects);
        }

    };
    useEffect(() => {
        sortProjectsByCreationDate();
    }, [sortProjectsCreationDateOrder]);

    const [sortProjectAbsorptionCapacityOrder, setSortProjectAbsoptionCapacityOrder] = useState('asc');
    const sortProjectsAbsorptionCapacity = () => {
        if (projects) {
            let sortedTowns = [...filteredItems];
            if (sortProjectAbsorptionCapacityOrder === 'asc') {
                sortedTowns.sort((a, b) => a.includedAbsorptionCapacity - b.includedAbsorptionCapacity);
            } else {
                sortedTowns.sort((a, b) => b.includedAbsorptionCapacity - a.includedAbsorptionCapacity);
            }
            setFilteredItems(sortedTowns);
        }

    };
    useEffect(() => {
        sortProjectsAbsorptionCapacity();
    }, [sortProjectAbsorptionCapacityOrder]);



    const [sortProjectsLastUpdateTimeOrder, setSortProjectsLastUpdateTimeOrder] = useState('asc');


    const sortProjectsByLastUpdateTime = () => {
        if(projects){
            let sortedProjects = [...filteredItems];
            if (sortProjectsLastUpdateTimeOrder === 'asc') {
                sortedProjects.sort((a, b) => new Date(a.lastUpdateDate) - new Date(b.lastUpdateDate));
            } else {
                sortedProjects.sort((a, b) => new Date(b.lastUpdateDate) - new Date(a.lastUpdateDate));
            }
            setFilteredItems(sortedProjects);
        }

    };
    useEffect(() => {
        sortProjectsByLastUpdateTime();
    }, [sortProjectsLastUpdateTimeOrder]);

    const fetchData = () => {
        fetch(process.env.REACT_APP_API_URL + "/projects"+request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {

                setFilteredItems(jsonData);
                setProjects(jsonData);



            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchData()
        updateSearchHeader()
    }, []);

    const projectsListLastUpdate = localStorage.getItem("projectsLastUpdate") ? new Date(localStorage.getItem("projectsLastUpdate")) : new Date()
    const [projectsListIsOld, setProjectsListIsOld] = useState( new Date() - projectsListLastUpdate > 3600000);

    function refreshProjectsList(){
        localStorage.removeItem("projectsLastUpdate");
        localStorage.removeItem("projects");
        setProjects(null)
        setFilteredItems(null)
        fetchData()
        setProjectsListIsOld(false)

    }

    function fetchTown(townId){
        let url = process.env.REACT_APP_API_URL+`/towns/${townId.length > 6 ? "collective/" : ""}`+townId
        return fetch(url , {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
                setTown(jsonData)
                return jsonData; // Add this line
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const [searchHeader, setSearchHeader] = useState(''); // Add this line at the beginning of your component

    const regionalCenterMapper = {
        0: "Všechna regionální centra",
        1: "Moravskoslezský kraj",
        2: "Středočeský kraj a Praha",
        3: "Ústecký a Karlovarský kraj",
        4: "Královehradecký a Liberecký kraj",
        5: "Pardubický kraj a kraj Vysočina",
        6: "Jihočeský kraj a Plzeňský kraj",
        7: "Zlínský a Jihomoravský kraj",
        8: "Olomoucký kraj"
    };

    function updateSearchHeader() {
        if (urlParams.get("townId")) {
            fetchTown(urlParams.get("townId"))
                .then(townData => {
                    setSearchHeader(townData.name);
                });
        }
        else if (urlParams.get("regionalCenterId") || (authority !== "HEADQUARTERS" && authority !== "HQ_WITH_REPORT")) {
            const regionalCenterToSet = urlParams.get("regionalCenterId") ? regionalCenterMapper[urlParams.get("regionalCenterId")] : regionalCentreId;
            setSearchHeader(regionalCenterMapper[regionalCenterToSet]);
        }
        else {
            setSearchHeader('');
        }
    }
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        const url = new URL(window.location.href);
        url.searchParams.set('regionalCenterId', selectedValue);
        window.location.href = url;
    };

    return (
        <div>
            <Navigation active={"projects"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Projekty</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">

                        <div className="gov-title--beta">Přehled projektů: {searchHeader}</div>
                        
                        <div className="gov-flexbox search-row-width">
                            <GovFormInput onGov-input={handleSearchChange}
                                          placeholder="Zadejte název nebo číslo projektu" size="m"
                                          variant="secondary">
                                <GovIcon type="basic" name="search" slot="right-icon"></GovIcon>
                            </GovFormInput>

                        </div>
                       <GovSpacer size="l"></GovSpacer>
                        {(authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") &&
                        <div className="gov-flexbox search-row-width">
                        <GovFormControl>
                            <GovFormSelect size="m" variant="secondary"
                                           value={urlParams.get("regionalCenterId") ? urlParams.get("regionalCenterId") : ""}
                                           onGov-change={handleSelectChange}>
                                <option disabled="disabled" value="">Regionální centrum</option>
                                <option value="0">Všechna regionální centra</option>
                                <option value="1">Moravskoslezský kraj</option>
                                <option value="2">Středočeský kraj a Praha</option>
                                <option value="3">Ústecký a Karlovarský kraj</option>
                                <option value="4">Královehradecký a Liberecký kraj</option>
                                <option value="5">Pardubický kraj a kraj Vysočina</option>
                                <option value="6">Jihočeský kraj a Plzeňský kraj</option>
                                <option value="7">Zlínský a Jihomoravský kraj</option>
                                <option value="8">Olomoucký kraj</option>
                            </GovFormSelect>
                        </GovFormControl>
                        </div>
                        }

                        {/*{projectsListIsOld && authority === "HEADQUARTERS" && request === "" &&*/}
                        {/*    <GovMessage variant="warning"><p>Aktuálně pracujete se seznamem projektů, který byl naposledy aktualizován před více než 1 hodinou. Pro načtení aktuálního seznamu projektů klikněte na tlačítko níže.</p>*/}
                        {/*        <GovIcon type="basic" name="info" slot="icon"></GovIcon>*/}
                        {/*    </GovMessage>*/}
                        {/*}*/}
                        {/*{authority === "HEADQUARTERS" && request === "" &&*/}
                        {/*<div>*/}
                        {/*    <GovButton class="mt-10" size="m" type="solid" wcag-label="Smazat" variant="primary"*/}
                        {/*               onGov-click={() => {refreshProjectsList()}}>Obnovit seznam projektů*/}
                        {/*    </GovButton>*/}

                        {/*    <p>Poslední aktualizace seznamu {authority === "HEADQUARTERS" ? "všech" : ""} projektů proběhla: {localStorage.getItem("projectsLastUpdate") && convertIsoToCustomFormat(localStorage.getItem("projectsLastUpdate"))}</p>*/}
                        {/*</div>*/}
                        {/*}*/}

                        <div className="gov-container gov-flexbox gov-alphabet">
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('A')}>A
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('B')}>B
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('C')}>C
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Č')}>Č
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('D')}>D
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('E')}>E
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('F')}>F
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('G')}>G
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('H')}>H
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('CH')}>CH
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('I')}>I
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('J')}>J
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('K')}>K
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('L')}>L
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('M')}>M
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('N')}>N
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('O')}>O
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('P')}>P
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Q')}>Q
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('R')}>R
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Ř')}>Ř
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('S')}>S
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Š')}>Š
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('T')}>T
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('U')}>U
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('V')}>V
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('W')}>W
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('X')}>X
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Y')}>Y
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Z')}>Z
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Ž')}>Ž
                            </GovButton>
                            {letterChosen && <GovButton onGov-click={() => {
                                handleLetterClick('')}} wcag-label="What exactly happens when you click?" variant="primary"
                                                        size="xs">Odstranit výběr
                            </GovButton>}
                        </div>
                        <GovSpacer size="xl"></GovSpacer>
                        {currentItems ?
                        <table cellSpacing="0" cellPadding="0">
                            <thead>
                            <tr>
                                <th>Název
                                    {sortTownsOrder === "asc" ?
                                        <GovButton onGov-click={() => setSortTownsOrder('desc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                        :
                                        <GovButton onGov-click={() => setSortTownsOrder('asc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                    }
                                </th>
                                <th>Číslo
                                    {sortProjectsIdOrder === "asc" ?
                                        <GovButton onGov-click={() => setSortProjectsIdOrder('desc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                        :
                                        <GovButton onGov-click={() => setSortProjectsIdOrder('asc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                    }
                                </th>
                                <th className="w-150px">Datum založení
                                    {sortProjectsCreationDateOrder === "asc" ?
                                        <GovButton onGov-click={() => setSortProjectsCreationDateOrder('desc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                        :
                                        <GovButton onGov-click={() => setSortProjectsCreationDateOrder('asc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                    }
                                </th>
                                <th>Datum a čas poslední aktualizace
                                    {sortProjectsLastUpdateTimeOrder === "asc" ?
                                        <GovButton onGov-click={() => setSortProjectsLastUpdateTimeOrder('desc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                        :
                                        <GovButton onGov-click={() => setSortProjectsLastUpdateTimeOrder('asc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                    }
                                </th>
                                <th className="w-70px">Absorpční kapacita
                                    {sortProjectAbsorptionCapacityOrder === "asc" ?
                                        <GovButton onGov-click={() => setSortProjectAbsoptionCapacityOrder('desc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                        :
                                        <GovButton onGov-click={() => setSortProjectAbsoptionCapacityOrder('asc')}
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="link" size="xs" loading="false">

                                            <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                     slot="left-icon"></GovIcon> </GovButton>
                                    }
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentItems.map((row, index) => (
                                    <tr key={index}>
                                        <td className="first"><a href={`/projects/detail/` + row.id}>{row.name}</a>
                                        </td>
                                        <td>{row.id}</td>
                                        <td>{convertIsoToDateFormat(row.creationDate)}</td>
                                        <td>{convertIsoToCustomFormat(row.lastUpdateDate)}</td>
                                        <td>{row.includedAbsorptionCapacity === true ? "Ano" : "Ne"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                            : (
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                            )}


                    </div>

                </div>
                <div className="pagination-container">
                    {projects ? (
                        <GovPagination onGov-page={handlePageChange} variant="primary" size="s" type="button" current={currentPage} total={filteredItems.length} page-size={itemsPerPage}
                                       max-pages="4" wcag-label="Main paging" wcag-page-label="Page"></GovPagination>
                    ) : (
                        <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                     animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                    )}


                </div>
            </div>
        </div>
    )
}
