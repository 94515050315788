import "../css/layouts.css";
import Navigation from "../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs,
    GovButton, GovFormCheckbox, GovFormControl, GovFormFile, GovFormGroup,
    GovFormInput, GovFormLabel, GovFormMessage, GovFormMultiSelect, GovFormRadio,
    GovIcon, GovLoading, GovModal, GovPrompt, GovSkeleton, GovSpacer
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
    convertIsoToDateFormat,
    convertIsoToCustomFormat, convertIsoToCustomFormatForDocuments,
    externalRoleToText, getIcon,
    getPersonRole,
    mapConsultationResult, mapRating, parseJwt,
    userRoleToText
} from "../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function ConsultationDetailContent() {
    const { consultationId } = useParams();
    const jwt = localStorage.getItem('jwt');

    const [townId, setTownId] = useState();
    const [consultation, setConsultation] = useState();
    const [rcPeople, setRcPeople] = useState([]);
    const [externalPersons, setExternalPersons] = useState([]);

    const [documents, setDocuments] = useState([]);
    const [documentIsLoading, setDocumentIsLoading] = useState(false);
    const [collective, setCollective] = useState("");

    const user = parseJwt(localStorage.getItem("jwt"))
    let role
    if(user !== null){
        role = user.role[0].authority
    }

    function uploadDocument(file, type) {
        setDocumentIsLoading(true)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('targetEntityId', consultationId);
        formData.append('type', parseInt(7));
        formData.append('target', "CONSULTATION");

        fetch(`${process.env.REACT_APP_API_URL}/document/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setDocumentIsLoading(false)
                console.log(jsonData);
                fetchData();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    function fetchDocument(documentID, documentName) {
        fetch(`${process.env.REACT_APP_API_URL}/document/${documentID}/download`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                // Check if the MIME type of the blob is a PDF or CSV
                if (blob.type === 'application/pdf') {
                    // Open the file in a new tab
                    window.open(url);
                } else {
                    // Download the file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = documentName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            })

    }

    function deleteDocument(documentID) {
        fetch(process.env.REACT_APP_API_URL+"/document/" + documentID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response;
            })
            .then(jsonData => {
                console.log(jsonData)
                fetchData()
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const fetchData = () => {
        fetch(process.env.REACT_APP_API_URL+"/preProjectConsultations/"+ consultationId, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setConsultation(jsonData)
                setRcPeople(jsonData.regionalCenterParticipants)
                setDocuments(jsonData.documents)
                console.log(jsonData)
                setExternalPersons(jsonData.externalParticipants)
                setTownId(jsonData.town.id)
                setCollective(jsonData.town.id.toString().length > 6)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {


        fetchData();
    }, []);


    const [personName, setPersonName] = useState('');
    const [personSurname, setPersonSurname] = useState('');
    const [personTelephone, setPersonTelephone] = useState('');
    const [personEmail, setPersonEmail] = useState('');
    const [personRole, setPersonRole] = useState('');
    const [personOrganization, setPersonOrganization] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [startOfConsultation, setStartOfConsultation] = useState('');
    const [endOfConsultation, setEndOfConsultation] = useState('');

    const [lengthOfConsultation, setLengthOfConsultation] = useState('');
    const [openPoints, setOpenPoints] = useState('');
    const [agreedPoints, setAgreedPoints] = useState('');
    let navigate = useNavigate();

    const [personArray, setPersonArray]=useState([
    ])


    const handlePersonNameChange = (event) => {
        setPersonName(event.target.value);
    };
    const handlePersonSurnameChange = (event) => {
        setPersonSurname(event.target.value);
    };
    const handlePersonTelephoneChange = (event) => {
        setPersonTelephone(event.target.value);
    };
    const handlePersonEmailChange = (event) => {
        setPersonEmail(event.target.value);
    };
    const handlePersonOrganizationChange = (event) => {
        setPersonOrganization(event.target.value);
    };
    const handlePersonRoleChange = (event) => {
        setPersonRole(event.target.value);
    };
    const handleContactPersonChange = (event) => {
        setContactPerson(event.target.value);
    };
    const handleStartOfConsultationChange = (event) => {
        setStartOfConsultation(event.target.value);
        console.log(event.target.value)
    }
    const handleEndOfConsultationChange = (event) => {
        setEndOfConsultation(event.target.value);
        console.log(event.target.value)
    }

    const handleOpenPointsChange = (event) => {
        setOpenPoints(event.target.value);
    }
    const handleAgreedPointsChange = (event) => {
        setAgreedPoints(event.target.value);
    }
    const [selectedExternalPersons, setSelectedExternalPersons] = useState([]);
    const handleSelectExternalPersonsChange = (event) => {
        console.log(event)
        console.log(selectedExternalPersons)
        setSelectedExternalPersons(Array.from(event.detail.value, option => parseInt(option.value)));
    };

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    function openDeleteModal(){
        setDeleteModalOpen(true)
    }
    function closeDeleteModal(){
        setDeleteModalOpen(false)
    }

    const [notAuthorizedModalOpen, setNotAuthorizedModalOpen] = useState(false);

    function openNotAuthorizedModal(){
        setNotAuthorizedModalOpen(true)
    }
    function closeNotAuthorizedModal(){
        setNotAuthorizedModalOpen(false)
        setDeleteModalOpen(false)
    }

    function deleteConsultation(){
        if (role === "HEADQUARTERS" || role === "HQ_WITH_REPORT") {
            fetch(process.env.REACT_APP_API_URL + `/preProjectConsultations/${consultationId}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                method: 'DELETE',
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    if (response.status === 204) {
                        navigate(`/towns/detail/${townId}`)
                    } else {
                        return response.json().then(data => {
                            throw new Error(`Unexpected response: ${data.message || 'No message provided'}`);
                        });
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            setNotAuthorizedModalOpen(true)
        }
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const generateConsultationPDF = () => {
        const documentDefinition = {
            content: [
                { text: `Záznam o konzultaci v obci - ${consultation.town.name}`, style: 'header' },
                { text: `Kód obce:`, style: 'h4' },
                { text: `${consultation.town.id}` },
                { text: `Začátek konzultace:`, style: 'h4' },
                { text: `${convertIsoToCustomFormat(consultation.start)}` },
                { text: `Konec konzultace:`, style: 'h4' },
                { text: `${convertIsoToCustomFormat(consultation.end)}` },
                { text: `Dohodnuté body:`, style: 'h4' },
                { text: `${consultation.agreedPoints}` },
                { text: `Otevřené body:`, style: 'h4' },
                { text: `${consultation.openPoints}` },
                { text: `Další kroky/akční plán:`, style: 'h4' },
                { text: `${consultation.actionPlan ? consultation.actionPlan : "Žádný akční plán není uveden"}` },
                { text: `Účastníci (Regionální centrum):`, style: 'h4' },
                { text: `${rcPeople.map((person) => person.firstname + " " + person.lastname + " - " + userRoleToText(person.role)).join(', ')}` },
                { text: `Další účastníci:`, style: 'h4' },
                { text: `${externalPersons.map((person) => person.firstname + " " + person.lastname + " - " + externalRoleToText(person.role) + (person.role === "OTHER" ? ` ("${person.roleDescription}")` : '')).join(', ')}` },
                { text: `Tento dokument byl vygenerován informačním systémem Státního fondu podpory investic dne ${convertIsoToCustomFormatForDocuments(new Date())}.`, style: 'footer' },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                text: {
                    margin: [0, 2, 0, 10]
                },
                h4: {
                    bold: true,
                    margin: [0, 7, 0, 2]
                },
                footer: {
                    fontSize: 10,
                    margin: [0, 10, 0, 0]
                }
            }
        }

        pdfMake.createPdf(documentDefinition).download(`SFPI - Konzultace v obci ${consultation.town.name} ze dne ${convertIsoToDateFormat(consultation.start)}.pdf`);
    };
    consultation && console.log(consultation.town.id.toString().length)
    return (
        <div>
            <Navigation active={"villages"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/towns/search">Obce</a></li>


                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            {consultation ?
                                <a href={`/towns/${consultation.town.id.toString().length > 6 ? "collective/" : ""}detail/${consultation.town.id}`}>
                                    {consultation.town.name} {consultation.town.id.toString().length > 6 && "(DSO)"}</a> :
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px"
                                             height="30px"></GovSkeleton>
                            }
                        </li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Detail konzultace ({consultationId})</strong></li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">

                        <div className="gov-flexbox">
                            <div className="gov-title--beta">Detail konzultace</div>
                            <div className="gov-flexbox--justify-end">
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           size="m" onGov-click={generateConsultationPDF}>Stáhnout v PDF
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           size="m" href={`/edit-consultation/${consultationId}`}>Upravit
                                </GovButton>
                                {(role === "HEADQUARTERS" || role === "HQ_WITH_REPORT") &&
                                    <GovButton size="m" type="solid" wcag-label="Smazat" variant="error" onGov-click={openDeleteModal}>Smazat
                                </GovButton>
                                }
                            </div>
                            <GovPrompt open={deleteModalOpen} onGov-close={closeDeleteModal} label="Smazat konzultaci" role="dialog" label-tag="h5"
                                       wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu chcete smazat tuto konzultaci?<br/>Tato operace je nevratná.
                                <GovButton onGov-click={closeDeleteModal} variant="primary" size="m" type="base" slot="actions"
                                           wcag-label="Cancel delete file my-photo.jpg">Zrušit
                                </GovButton>
                                <GovButton variant="error" size="m" type="solid" slot="actions"
                                           wcag-label="Potvrdit smazání" onGov-click={deleteConsultation}>Smazat
                                </GovButton>
                            </GovPrompt>
                            <GovModal open={notAuthorizedModalOpen} onGov-close={closeNotAuthorizedModal} label="Nedostatečné oprávnění"
                                      wcag-close-label="Close dialog box with more information">
                                <p>Na provedení akce nemáte dostatečné oprávnění</p>
                            </GovModal>
                        </div>

                        <div className="form-help-layout gov-tabs">
                            <div className="gov-tabs__content-holder ">
                                <div className="gov-tabs__content is-active" role="tabpanel">
                                    {consultation ?
                                        <div>
                                            <h4>{collective ? "Název dobrovolného sdružení obcí" : "Název obce"}</h4>
                                            <p>{consultation.town.name}</p>
                                            <h4>{collective ? "Kód dobrovolného sdružení obcí" : "Kód obce"}</h4>
                                            <p>{consultation.town.id}</p>
                                            <h4>Výsledek konzultace</h4>
                                            <p>{mapConsultationResult(consultation.result)}</p>
                                            <h4>Hodnocení konzultace</h4>
                                            <p>{mapRating(consultation.rating)}</p>
                                            <h4>Začátek konzultace</h4>
                                            <p>{convertIsoToCustomFormat(consultation.start)}</p>
                                            <h4>Konec konzultace</h4>
                                            <p>{convertIsoToCustomFormat(consultation.end)}</p>
                                            <h4>Dohodnuté body</h4>
                                            <p>{consultation.agreedPoints}</p>
                                            <h4>Otevřené body</h4>
                                            <p>{consultation.openPoints ? consultation.openPoints : "Žádné otevřené body nejsou uvedeny"}</p>
                                            <h4>Další kroky/akční plán</h4>
                                            <p>{consultation.actionPlan ? consultation.actionPlan : "Žádný akční plán není uveden"}</p>
                                            <h4>Účastníci (Regionální centrum)</h4>
                                            <ul>
                                                {rcPeople.map((person) => (
                                                    <li key={person.id}>{person.firstname + " " + person.lastname + " - " + userRoleToText(person.role)}</li>
                                                ))}
                                            </ul>
                                            <h4>Další účastníci</h4>
                                            <ul>
                                                {externalPersons.map((person) => (
                                                    <li key={person.id}>
                                                        {person.firstname + " " + person.lastname + " - " + externalRoleToText(person.role)}
                                                        {person.role === "OTHER" &&
                                                            <span> ("{person.roleDescription}")</span>}
                                                    </li>
                                                ))}
                                            </ul>


                                        </div>
                                        :
                                        <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                                     shape="text" animation="progress" count="1" width="300px"
                                                     height="30px"></GovSkeleton>}
                                    {/*{rcPeople.length > 0 &&*/}
                                    {/*    <div>*/}
                                    {/*        {rcPeople.map((person) => (*/}
                                    {/*            <div className="gov-layout gov-layout--ratio-1-2">*/}
                                    {/*                <div className="gov-layout__col gov-layout__col--pos-left">*/}
                                    {/*                    <p><b>Jméno a*/}
                                        {/*                        příjmení:</b> {person.name + " " + person.surname}*/}
                                        {/*                    </p>*/}
                                        {/*                    <p><b>Telefon:</b> {person.telephone}</p>*/}
                                        {/*                    <p><b>E-mail:</b> {person.email}</p>  <p>*/}
                                        {/*                    <b>Organizace:</b> {person.organization}</p>*/}
                                        {/*                    <p><b>Role:</b> {roleToText(person.role)}</p></div>*/}
                                        {/*                <div*/}
                                        {/*                    className="gov-layout__col gov-layout__col--pos-right gov-layout__col--spread-1">*/}
                                        {/*                    <GovButton variant="primary" size="m" type="solid"*/}
                                        {/*                               wcag-label="Open dialog box for more information"*/}
                                        {/*                               onGov-click={() => deletePerson(person.id)}>Odebrat*/}
                                        {/*                    </GovButton>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        ))}*/}
                                        {/*        <hr/>*/}
                                        {/*    </div>*/}
                                        {/*}*/}

                                    <GovSpacer size="m"></GovSpacer>
                                    <div className="gov-flexbox">
                                        <div className="gov-title--beta">Dokumenty</div>
                                    </div>
                                    <div>
                                        {documentIsLoading &&
                                            <div>
                                                <GovLoading>Prosím vyčkejte soubor se nahrává</GovLoading>
                                            </div>
                                        }

                                        {documents.map((document, index) => {
                                            return (
                                                <div className="mt-10 mb-10">
                                                    <h3>{document.typeValue}</h3>
                                                    <FontAwesomeIcon icon={getIcon(document.name)}/>
                                                    <span> </span>
                                                    <a key={index} href={document.link} onClick={(event) => {
                                                        event.preventDefault();
                                                        fetchDocument(document.id, document.name);
                                                    }}>
                                                        {document.name}
                                                    </a>
                                                    <GovButton className="ml-5"
                                                               wcag-label="What exactly happens when you click?"
                                                               variant="primary"
                                                               type="solid" size="xs"
                                                               onGov-click={() => deleteDocument(document.id)}>
                                                        Smazat soubor
                                                    </GovButton>
                                                </div>
                                            );
                                        })}
                                        <GovAccordionItem variant="primary" size="xs">
                                            <div className="mb-10">
                                                <h3>Obecný dokument</h3>
                                                <div className="sfpi-document-layout">
                                                    <div><p>{`Přiložte - dokument`} </p>
                                                    </div>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => uploadDocument(event.detail.file.file, 7)}
                                                        max-file-size="616448"
                                                        accept=".pdf,.xls,.xlsx,.doc,.docx"
                                                        expanded="true"
                                                        displayAttachments="false">
                                                        <p>
                                                            <GovButton variant="primary" size="m"
                                                                       type="outlined">Nahrát soubor ze
                                                                zařízení
                                                            </GovButton>
                                                        </p>
                                                        <GovSpacer size="m"></GovSpacer>
                                                        <p className="gov-text--s">Podporované formáty
                                                            PDF,
                                                            DOC, XLS</p></GovFormFile>
                                                </div>
                                            </div>
                                            <p slot="label">Nahrání dokumentu</p>
                                        </GovAccordionItem>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
