import {faFileAlt, faFileExcel, faFilePdf, faFileWord} from "@fortawesome/free-solid-svg-icons";

export function parseJwt (token) {
    if(token === null)  return null
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function mapConsultationResult(result) {
    const resultMap = {
        'AGREEMENT': 'Dohoda o spolupráci obce s RC',
        'NEXT_CONSULTATION_NEEDED': 'Je nutná další konzultace',
        'NOT_INTERESTED': 'Obec nemá zájem o spolupráci',
        'OTHER': 'Jiný výsledek'
    };

    return resultMap[result] || 'Neznámý výsledek';
}

export function mapRating(result) {
    const resultMap = {
        '1': '1 - vynikající',
        '2': '2 - chvalitebná',
        '3': '3 - dobrá',
        '4': '4 - dostatečná',
        '5': '5 - nedostatečná'
    };

    return resultMap[result] || 'Hodnocení není uvedeno';
}

export function mapProjectType(result) {
    const resultMap = {
        '0': 'Není vybráno',
        '1': 'Nákup',
        '2': 'Stavba',
        '3': 'Rekonstrukce',
        '4': 'Mix'
    };

    return resultMap[result] || 'Neznámý typ';
}

export function mapFinancingType(result) {
    const resultMap = {
        '1': '4.1.3 – Podpora přípravy projektů',
        '2': '2.10 – Dostupné bydlení',
        '3': 'IROP – Sociální bydlení',
        '4': 'PPP – Spolu-investice žadatele s bankou, developerem, investorem, apod.',
        '5': 'Vlastní zdroje investora',
        '6': 'Komerční úvěr',
        '7': 'Jiné',
        '8': 'SFPI - Bytové domy bez bariér',
        '9': 'SFPI - Technická infrastruktura'
    };

    return resultMap[result] || 'Neznámý typ financování';
}

export function mapBuildingPurpose(result) {
    const resultMap = {
        '1': 'Prodej bytů',
        '2': 'Pronájem tržní',
        '3': 'Pronájem zvýhodněný',
        '4': 'Mix'
    };

    return resultMap[result] || 'Neznámý účel bytové výstavby';
}

export function mapProjectPhase(result) {
    const resultMap = {
        '1': 'Vize',
        '2': 'Předprojektová příprava',
        '3': 'Projektová fáze',
        '4': 'Stavební povolení - příprava na realizaci',
        '5': 'Realizace a provoz budovy'
    };

    return resultMap[result] || 'Neznámá fáze';
}

export function mapTimeSheetType(result) {
    const resultMap = {
        '-1': 'Dovolená',
        '1': 'Administrativa',
        '2': 'Obec',
        '3': 'Projekt',
        '4': 'Konzultace MMR',
        '5': 'Školení',
        '6': 'Cesta',
        '7': 'Konzultace'
    };

    return resultMap[result] || 'Neznámý typ';
}

export function mapWorkAgreementType(result) {
    const resultMap = {
        '1': 'HPP',
        '2': 'DPČ'
    };

    return resultMap[result] || 'Neznámý typ pracovního poměru';
}

export function mapTransportType(value) {
    const transportTypeMap = {
        '1': 'Veřejná doprava',
        '2': 'Soukromé vozidlo',
        '3': 'Vozidlo SFPI',
        '4': 'Spolujízda'
    };

    return transportTypeMap[value] || 'Neznámý dopravní prostředek';
}

export function convertUtilizationToPercentage(value) {
    return (value * 100).toFixed(2);
}

export function convertIsoToCustomFormat(isoString) {
    let date = new Date(isoString);

    let day = String(date.getUTCDate()).padStart(2, '0');
    let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    let year = date.getUTCFullYear();


    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

export function convertIsoToCustomFormatForDocuments(isoString) {
    let date = new Date(isoString);

    let day = String(date.getUTCDate()).padStart(2, '0');
    let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    let year = date.getUTCFullYear();


    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} v ${hours}:${minutes}`;
}

export function convertIsoToDateFormat(inputDate) {
    let date = new Date(inputDate);
    let month = date.getMonth() + 1; // Months are zero based
    let day = date.getDate();
    let year = date.getFullYear();

    // Ensure day and month are two digits
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return `${day}.${month}.${year}`;
    }

export function convertIsoToTime(isoString) {
    let date = new Date(isoString);
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
}

export function convertIsoToLocalDate(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export function convertDateToDDMMYYYY(dateString) {
    if (!dateString || !dateString.includes('-')) {
        return 'Datum není vyplněno';
    }

    const [year, month, day] = dateString.split('-');

    if (!year || !month || !day) {
        return 'Datum není vyplněno';
    }

    return `${day}.${month}.${year}`;
}

export function parseIsoToYearMonth(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +2 because we want the next month after the given date
    return `${year}-${month}`;
}

export function mapDateFormat(dateString) {
    const [year, month] = dateString.split('-');
    return `${month}.${year}`;
}

export function externalRoleToText(role){
    const roleMap = {
        'STATUTORY_DEPUTY': 'Statutární zástupce',
        'DELEGATE': 'Zmocněnec',
        'OBSERVER': 'Nahlížející osoba',
        'CONTACT_PERSON': 'Kontaktní osoba',
        'OTHER': 'Jiná role'
    };

    return roleMap[role] || 'Role nenalezena';
}

export function userRoleToText(role){
    const roleMap = {
        'NON_ACTIVE': 'Neaktivní uživatel',
        'CONTACT_PERSON': 'Kontaktní osoba',
        'RC_EMPLOYEE': 'Specialista regionálního centra',
        'HEADQUARTERS': 'Zaměstnanec centrály',
        'HQ_WITH_REPORT': 'Zaměstnanec centrály+',
        'RC_LEADER': 'Manažer regionálního centra'
    };

    return roleMap[role] || 'Role nenalezena';
}

export function approvalStateToText(role){
    const roleMap = {
        'CREATED': 'Ke schválení vedoucím',
        'APPROVED_BY_SUPERVISOR': 'Schváleno vedoucím',
        'REJECTED_BY_SUPERVISOR': 'Zamítnuto vedoucím',
        'APPROVED_BY_HQ': 'Schváleno centrálou',
        'REJECTED_BY_HQ': 'Zamítnuto centrálou',


    };

    return roleMap[role] || 'Neznámý stav';
}

export function auditReportToText(auditType){
    const roleMap = {
        'SFPIREPORT': 'Report SFPI',
        'MMRREPORT': 'Report MMR',
        };

    return roleMap[auditType] || 'Neznámý typ';
}


export function typeToText(subjectType){
    const typeMap = {
        'TOWN': 'Obec/město/městská část',
        'FUNDED_ORGANIZATION': 'Příspěvková organizace',
        'COUNTRY': 'Stát',
        'CHURCH': 'Církev',
        'PRIVATE': 'Privátní subjekt',
        'ASSOCIATION': 'Spolek'
    };

    return typeMap[subjectType] || 'Typ nenalezen';
}

export function fuelTypeToText(type){
    const typeMap = {
        '1': 'Natural 95',
        '2': 'Natural 98',
        '3': 'Diesel',
        '4': 'Elektrický pohon',
    };

    return typeMap[type] || 'Typ paliva nenalezen';
}

export function getPersonRole(person) {
    if (person.role !== undefined) {
        return person.role
    } else if (person.roleValue !== undefined) {
        return person.roleValue
    } else {
        return person.role[0].authority
    }
}

export function getIcon(documentName) {
    const extension = documentName.split('.').pop().toLowerCase();

    switch (extension) {
        case 'pdf':
            return faFilePdf;
        case 'xlsx':
        case 'xls':
            return faFileExcel;
        case 'docx':
        case 'doc':
            return faFileWord;
        default:
            return faFileAlt; // or some default icon
    }
}
