import {GovButton, GovDropdown, GovIcon, GovMessage} from "@gov-design-system-ce/react";
import {HomeHeader} from "./headerComponents/HomeHeader";
import {useLocation, useNavigate} from "react-router-dom";
import {parseJwt} from "./utils/utils";
import {useEffect, useRef, useState} from "react";

export function Header({onLogout, active}) {
    let headerComponent;
    const location = useLocation()
    const roleRef = useRef(null);
    switch (location.pathname) {
        case '/':
            headerComponent = <HomeHeader />;
            break;
        default:
            headerComponent = "";
            break;
    }
    const name = localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")
    const navigate = useNavigate();

    function logout(){
        localStorage.removeItem("projects")
        localStorage.removeItem("towns")
        localStorage.removeItem("jwt")
        localStorage.removeItem("firstname")
        localStorage.removeItem("lastname")
        localStorage.removeItem('workAgreementTypeId')
        localStorage.removeItem('workAllocation')
        navigate("/")
    }

    const user = parseJwt(localStorage.getItem("jwt"))
    const authority = user && user.role[0].authority

    const [showAlertExpiration, setShowAlertExpiration] = useState(false);
    useEffect(() => {
        const user = parseJwt(localStorage.getItem("jwt"))

        if(user !== null){
            roleRef.current = user.role[0].authority
        }
        if (user) {
            const timeOfExpiration = user.exp;

            const currentTime = Math.floor(Date.now() / 1000); // Get current Unix timestamp
            if (timeOfExpiration < currentTime) {
                localStorage.removeItem("jwt");
                // alert("Vaše přihlášení vypršelo, přihlaste se prosím znovu.");
                navigate("/login");
            } else {
                setShowAlertExpiration(timeOfExpiration - currentTime < 600);
            }
        }
    }, [navigate]);


    return(
        <header className=" gov-container--no-y-offset gov-header">

            <div className="gov-container__content">
                {showAlertExpiration &&
                <GovMessage variant="warning"><p>Vaše přihlášení již brzy vyprší, pro obnovení relace se prosím
                    přihlaste znovu.</p>
                    <GovIcon type="basic" name="info" slot="icon"></GovIcon>
                </GovMessage>
                }
                <div className="gov-header__holder">
                    <div className="gov-header__left">
                        <div className="gov-link--inversed gov-header__logo">
                            <a className="gov-logo gov-logo--inversed" href={authority === "MMR" ? "/mmr-export" : "/"}>
                                <div className="gov-pictogram gov-pictogram--inversed">
                                    <i className="sfpi-icon sfpi-icon--large--no_margin sfpi-icon--logo"></i>
                                </div>
                                <span className="gov-logo__sitename">
                                Poradenský portál
                            </span>
                            </a>
                        </div>
                    </div>
                    <div className="gov-header__right">
                        {localStorage.getItem("jwt") === null &&
                            <div className="gov-header__item gov-header__item--login">
                                <GovButton href="/login" wcag-label="What exactly happens when you click?"
                                           variant="primary"
                                           type="outlined" size="m" inverse="true">Přihlásit se
                                    <GovIcon type="basic" name="person-fill" slot="left-icon"></GovIcon>
                                </GovButton>
                            </div>
                        }
                        {localStorage.getItem("jwt") !== null &&
                            <div className="gov-header__item gov-header__item--login">
                                {authority !== "MMR" &&
                                <GovButton wcag-label="What exactly happens when you click?"
                                           variant="primary" href="/dashboard"
                                           className={active === "dashboard-active" ? "u-bg-color--blue-light" : "" }
                                           type="outlined" size="m" inverse="true">
                                    <GovIcon type="complex" name="all" slot="left-icon"></GovIcon>
                                </GovButton>
                                }
                                {authority !== "MMR" &&
                                <GovButton style={{marginLeft: '5px'}} wcag-label="What exactly happens when you click?"
                                           variant="primary" href="/settings"
                                           className={active === "settings-active" ? "u-bg-color--blue-light" : "" }
                                           type="outlined" size="m" inverse="true">
                                    <GovIcon type="basic" name="gear" slot="left-icon"></GovIcon>
                                </GovButton>
                                }
                                <GovButton style={{marginLeft: '5px'}} wcag-label="What exactly happens when you click?"
                                           variant="primary" href={authority === "MMR" ? "/mmr-export" : "/profile/edit"}
                                           className={active === "profile-active" ? "u-bg-color--blue-light" : "" }
                                           type="outlined" size="m" inverse="true">{name}
                                    <GovIcon type="basic" name="person-fill" slot="left-icon"></GovIcon>
                                </GovButton>
                                <GovButton style={{marginLeft: '5px'}}
                                           wcag-label="What exactly happens when you click?"
                                           variant="primary" onGov-click={() => {
                                    if (onLogout) {
                                        onLogout()
                                    }
                                    logout()
                                }}
                                           type="outlined" size="m" inverse="true">Odhlásit se
                                    <gov-icon type="basic" name="box-arrow-right" slot="right-icon"></gov-icon>
                                </GovButton>
                            </div>
                        }


                        <div className="gov-header__fixed-items">
                            <div className="gov-header__item gov-header__item--mobile">
                                {localStorage.getItem("jwt") === null ?
                                    <div>
                                        <GovButton href="/login" wcag-label="What exactly happens when you click?"
                                                   variant="primary"
                                                   type="outlined" size="m" inverse="true">Přihlásit se
                                            <gov-icon type="basic" name="person-fill" slot="left-icon"></gov-icon>
                                        </GovButton>
                                    </div>
                                    :
                                    <div>
                                        <GovButton wcag-label="What exactly happens when you click?"
                                                   variant="primary"
                                                   type="outlined" size="m" inverse="true">{name}
                                            <gov-icon type="basic" name="person-fill" slot="left-icon"></gov-icon>
                                        </GovButton>
                                        <GovDropdown position="right">
                                            <GovButton variant="primary" size="m" type="solid"
                                                       wcag-label="What exactly happens when you click?"
                                                       right-icon="basic/chevron-down">MENU
                                                <gov-icon type="basic" name="chevron-down" slot="right-icon"></gov-icon>
                                            </GovButton>
                                            <ul slot="list">
                                                <li>
                                                    <GovButton variant="secondary" size="m" type="base"
                                                               href="/" wcag-label="Úvod">Úvod
                                                        <gov-icon type="basic" name="chevron-right"
                                                                  slot="right-icon"></gov-icon>
                                                    </GovButton>
                                                </li>
                                                <li>
                                                    <GovButton variant="secondary" size="m" type="base"
                                                               href="/projects" wcag-label="Projekty">Projekty
                                                        <gov-icon type="basic" name="chevron-right"
                                                                  slot="right-icon"></gov-icon>
                                                    </GovButton>
                                                </li>
                                                <li>
                                                    <GovButton variant="secondary" size="m" type="base"
                                                               href="/towns/search" wcag-label="Obce">Obce
                                                        <gov-icon type="basic" name="chevron-right"
                                                                  slot="right-icon"></gov-icon>
                                                    </GovButton>
                                                </li>
                                                <li>
                                                    <GovButton variant="secondary" size="m" type="base"
                                                               href="/road-compensations" wcag-label="Cestovní náhrady">Cestovní náhrady
                                                        <gov-icon type="basic" name="chevron-right"
                                                                  slot="right-icon"></gov-icon>
                                                    </GovButton>
                                                </li>
                                                <li>
                                                    <GovButton variant="secondary" size="m" type="base"
                                                               href="/timesheet" wcag-label="Timesheet">Timesheet
                                                        <gov-icon type="basic" name="chevron-right"
                                                                  slot="right-icon"></gov-icon>
                                                    </GovButton>
                                                </li>
                                                {authority !== "RC_EMPLOYEE" &&
                                                    <li>
                                                        <GovButton variant="secondary" size="m" type="base"
                                                                   href="/user-management"
                                                                   wcag-label="Správa uživatelů">Správa uživatelů
                                                            <gov-icon type="basic" name="chevron-right"
                                                                      slot="right-icon"></gov-icon>
                                                        </GovButton>
                                                    </li>
                                                }
                                                <li>
                                                    <GovButton variant="secondary" size="m" type="base"
                                                               href="/report-management" wcag-label="Správa výkaznictví">Správa výkaznictví
                                                        <gov-icon type="basic" name="chevron-right"
                                                                  slot="right-icon"></gov-icon>
                                                    </GovButton>
                                                </li>
                                                <li>
                                                    <GovButton wcag-label="What exactly happens when you click?"
                                                               onGov-click={() => {
                                                                   if (onLogout) {
                                                                       onLogout()
                                                                   }
                                                                   logout()
                                                               }}
                                                               variant="secondary" size="m" type="base">Odhlásit se
                                                        <gov-icon type="basic" name="box-arrow-right"
                                                                  slot="right-icon"></gov-icon>
                                                    </GovButton>
                                                </li>
                                            </ul>
                                        </GovDropdown>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="gov-header__separator"/>
                <div className="gov-header__overlay"></div>
                <nav className="gov-header__nav">
                    <div className="gov-header__nav-logo">
                        <div className="gov-pictogram">
                            <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="M30,4.9c0.7,0,0.9,0.4,1,0.7l0,0.1c0,0,0,0.1,0,0.1C31,6,31,6.3,30.9,6.4l-0.1,0.2c0,0,0,0.1,0,0.1l0,0l0,0
                                l0.1,0.1l0,0l0.3,0.5l0,0l0.2,0.3c0.4,0.7,0.9,1.6,0.9,2.5c0,1.5,0.3,3.3,1.7,4.8l-0.1,0l0,0l-0.1,0c0,0-0.1,0-0.1,0l-0.2-0.1
                                c-0.4-0.1-0.8-0.4-1.3-0.8c-0.1,0-0.1,0.2-0.1,0.5l0,0.3l0,0l0,0.3l0,0l0,0.4c0,0.1,0,0.3,0,0.4l0,0.4c0,0.1,0,0.1,0,0.2l0,0.4
                                c0.1,0.6,0.2,1.1,0.4,1.4c0.2,0.2-0.2,0.1-0.6-0.3l-0.2-0.2l0,0l-0.3-0.3c-0.1-0.2-0.2-0.3-0.3-0.2L31,17.3l0,0l-0.1,0.1l0,0
                                l-0.1,0.2l0,0l-0.1,0.2l0,0l-0.1,0.2c-0.2,0.8-0.4,2,0.3,3.2c0.1,0.2,0,0.2-0.2,0.1l-0.1-0.1l0,0l-0.2-0.1c-0.3-0.2-0.8-0.6-1-1
                                c0-0.1-0.1,0-0.2,0.2l-0.1,0.2l0,0l-0.1,0.2l0,0l0,0.3c-0.2,0.9-0.2,2.4,0.5,3.7c0.1,0.2-0.1,0.1-0.3,0l-0.2-0.1l0,0l-0.2-0.1l0,0
                                L29,24.4c0,0-0.1,0-0.1-0.1l-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2l0-0.1c-0.2-0.3-0.5,0.4,0,1.5l0.6,1.1l0,0l0.2,0.4l0,0l0.2,0.4
                                c0.3,0.5,0.5,0.9,0.7,1.1c0.2,0.2,0.6,0.5,1.1,0.7l0.8,0.4c0.1,0.1,0.2,0.1,0.3,0.2l0.1,0l0,0l0.6,0.4l0,0l0.3,0.2
                                c0.8,0.5,2,1.1,3.1,1.1c1.7,0,2.6-1.2,2.6-1.8c0,0,0,0,0,0l-0.1,0l0,0l-0.6-0.1l0,0l-0.2,0l0,0l-0.2-0.1l0,0l-0.3-0.1l0,0l-0.3-0.1
                                c0,0-0.1,0-0.1,0l-0.3-0.1c-1.5-0.6-3.4-1.9-3.4-5.1c0-2.5,1.6-4.3,2.9-5.4l0.3-0.2l0,0l0.3-0.2l0,0l0.2-0.2l0,0l0.3-0.2l0,0
                                l0.3-0.2l0.9,0.9l-0.3,0.2l0,0L38.2,19l0,0l-0.3,0.2c0,0-0.1,0.1-0.1,0.1l-0.3,0.3c-1.1,1-2.5,2.6-2.5,4.4c0,3,2.7,4.4,4.3,4.4
                                l0-0.1l0,0l0-0.1l0,0l0-0.1l0,0l0-0.1l0,0l0-0.1c0,0,0,0,0-0.1l0-0.1l0,0l0-0.2c0-0.1,0-0.1,0-0.2l-0.1-0.2c-0.3-0.7-1-1.5-2.8-2.3
                                l0.2,0l0,0l0.2,0l0,0l0.2,0c0.6,0,1.6,0.1,2.3,1.1c0.3,0.4,0.4,0.1,0.5,0c0.1-0.1,0.6-0.7,1.4-0.4l0,0l0,0L41,25.6c0,0,0,0,0,0.1
                                l-0.1,0.1c-0.3,0.4-0.7,1.3-0.3,2.5l0.2,0l0,0l0.1,0l0,0l0.2-0.1c0,0,0.1,0,0.1,0l0.2-0.1c0.9-0.5,2.3-1.5,2.3-3.7
                                c0-2-1.4-3.5-2.8-4.8l-0.4-0.4l0,0l-0.8-0.7c-0.1-0.1-0.1-0.1-0.2-0.2l-0.3-0.3c-0.2-0.1-0.3-0.3-0.4-0.4l-0.1-0.1
                                c-0.2-0.2-0.4-0.5-0.6-0.7l-0.9-0.9c-1-1-1.9-2.1-2.1-3.7L35,12l0,0l-0.1,0c-0.3-0.2-1.1-0.6-1.1-1.9c0-0.8,0.5-1.6,1-2.2L35,7.6
                                l0,0l0.2-0.2c0,0,0.1-0.1,0.1-0.1l0.2-0.2l0,0L35.7,7l0,0l0.1-0.1l0,0l0.1,0l0,0c0,0,0,0,0,0.1l-0.1,0.5l0,0l-0.1,0.2
                                c-0.1,0.4-0.1,0.9,0.3,0.9c0.6,0,1.9-1.5,1.9-1.8c0-0.1,0.1,0,0.1,0.1l0,0.1l0,0l0,0.2l0,0l0,0.2l0,0l-0.1,0.2
                                c-0.1,0.3-0.2,0.6-0.4,0.8l-0.1,0.1c-0.1,0.2-0.3,0.3-0.4,0.5l-0.6,0.7c-0.3,0.4-0.6,0.7-0.3,1c0.3,0.4,1-0.1,1.4-0.5l0.1-0.2
                                c0,0,0.1-0.1,0.1-0.1l0-0.1c0-0.1,0.1-0.1,0,0l0,0.1l0,0l0,0.2l0,0l-0.1,0.2c0,0,0,0.1,0,0.1l-0.1,0.2c-0.2,0.5-0.6,1.1-1.2,1.4l0,0
                                l0,0l0,0.1l0,0l0,0.1l0,0l0,0.1l0,0l0,0.1l0,0l0,0.1l0,0l0,0.1l0,0l0,0.1l0,0l0.1,0.1l0,0l0.1,0.2l0,0l0.1,0.2c0,0,0,0.1,0.1,0.1
                                l0.1,0.2l0,0l0.2,0.2l0,0l0.2,0.2c0,0,0.1,0.1,0.1,0.1l0.2,0.3l0,0l0.3,0.3l0,0l0.3,0.3C37.9,15,38,15,38,15.1l0.3,0.3l0,0l0.4,0.4
                                l0,0l0.4,0.4l0,0l0.5,0.4l0,0l0.5,0.4l0,0l0.4,0.4l0,0l0.6,0.5l0,0l0.5,0.4l0,0l0.5,0.4l0,0l0.5,0.5l0,0l0.1,0.1l0,0l0,0l0.1,0
                                c0.1-0.1,0.2-0.2,0.2-0.5l0-0.1c0-0.6,0.2-1.2,0.5-1.4l0,0.4l0,0l0,0.3l0,0l0,0.5l0,0l0,0.4c0,0.8,0.1,1.7,0.4,1.9
                                c0.3,0.1,0.4-0.2,0.4-0.6c0.1-0.4,0.4-0.8,0.5-0.7l-0.1,0.3l0,0l-0.1,0.4l0,0l0,0.3l0,0l0,0.3c-0.1,0.8-0.1,1.6,0.4,1.9
                                c0.1,0,0.1,0,0.2-0.1l0.1-0.1c0.1-0.2,0.4-0.3,0.9-0.1L46.1,22l0,0l-0.1,0.1C45.6,22.3,45,23,45.1,24c0.1,1.4-1.2,5-4.5,5.5l0,0.1
                                l0,0l0,0.1l0,0l-0.1,0.2l0,0L40.4,30l0,0l-0.1,0.2l0,0l-0.1,0.2c-0.5,0.9-1.5,2.2-4,1.9l0.2,0.2l0,0l0.2,0.2l0,0l0.3,0.3l0,0
                                l0.2,0.3l0,0l0.2,0.3c0,0.1,0.1,0.1,0.1,0.2l0.2,0.3c0.9,1.3,1.8,3.2,1.7,5.3l-0.1-0.2l0,0l-0.1-0.4l0,0l-0.2-0.5l0,0l-0.2-0.5l0,0
                                l-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2L38.2,37l0,0L38,36.6l0,0l-0.2-0.4l0,0l-0.3-0.4l0,0l-0.3-0.4c-0.2-0.4-0.5-0.7-0.8-1.1l-0.4-0.4
                                c-1-1.2-2.2-2.3-3.6-3.1l-0.3-0.2l0,0l0,0c-0.1,0-0.2,0.1-0.1,0.5c0.1,0.5-0.3,1.6-0.6,2.1c0,0.1-0.1,0.1-0.1,0.3l0,0.2
                                c0,0,0,0.1,0,0.1l0,0.2l0,0l0,0.3c0,0,0,0.1,0,0.1l0,0.3l0,0l0,0.3c0.2,1.7,1.1,4,3.9,4.8c0.5,0.1,0.9,0.1,1.3,0.1l0.2,0l0,0l0.2,0
                                l0,0l0.2-0.1l0,0l0,0.1l0,0l-0.1,0.1l0,0l-0.1,0.1l0,0L37,39.9c-0.4,0.3-1.1,0.7-2.5,0.7c-0.6,0-0.6,0.9,0.3,0.9c0,0,0,0,0,0.1
                                l0,0.2l0,0l0,0.2c0,0,0,0.1,0,0.1l-0.1,0.3l0,0L34.5,43l0,0l-0.1,0.4l0,0l-0.1,0.4l0,0l-0.1,0.4l0,0l-0.2,0.4l0,0L33.8,45l0,0
                                l-0.2,0.4c0,0.1-0.1,0.1-0.1,0.2l-0.2,0.4c-0.4,0.9-0.9,1.7-1.4,2.2c-0.3,0.2-0.1,0.3,0,0.4l0.1,0l0,0l0.1,0c0,0,0,0,0,0
                                c0.1,0,0.2,0.1,0.4,0.2l0.2,0.1c0,0,0.1,0,0.1,0.1l0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2l-0.1,0c-0.2,0-0.4,0-0.4,0.1l0,0l0,0L32.8,50
                                c-0.1,0.3-0.2,0.7-0.8,0.7c-0.8,0-1-0.8-1-1.2c0-0.4-0.4-0.4-0.6-0.4c-0.1,0-0.7,0.2-0.9,0.9l-0.1,0.3c-0.1,0.7-0.4,1.3-1,1.3
                                c-0.7,0-0.9-0.5-0.9-0.9c0-0.1,0-0.1-0.1-0.1l-0.1,0c-0.1,0-0.3,0-0.5-0.1l0.3-0.2l0,0l0.2-0.1l0,0l0.3-0.2l0,0l0.3-0.2
                                c0.5-0.4,0.9-0.8,0.8-1c-0.2-0.5-0.5-0.4-0.7-0.2L28,48.6l0,0l-0.1,0.1l0,0l-0.2,0.1c-0.3,0.2-0.8,0.5-1.2,0.6
                                c-0.6,0.1-0.9-0.7-0.6-1.3l0.1-0.1c0.1-0.1,0-0.1-0.1-0.2l-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2l0.4,0l0,0l0.4,0l0,0l0.3,0
                                c0.8,0,1.7,0,1.7-0.4c-0.1-0.6-0.6-0.6-0.9-0.5c-0.3,0.1-1.2,0-1.2-0.6c0-0.1,0.1-0.2,0.1-0.3l0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.3
                                c0-0.1-0.1-0.2-0.2-0.3l-0.1-0.1c0,0,0,0,0,0l0.1,0c0,0,0,0,0.1,0l0.2,0c0.2,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.5l0.4,0.4
                                c0.4,0.4,0.9,0.9,1.3,0.9c0.8,0,1.5-1.2,1.5-3.6l0-0.9l0,0l0-0.4l0,0l0-0.3l0,0l0-0.3l0,0l0-0.2c-0.1-0.4-0.1-0.6-0.3-0.7l-0.1-0.1
                                l0,0l-0.2-0.1l0,0l-0.1-0.1l0,0c0,0-0.1-0.1-0.1-0.2l0-0.2c0-0.1,0-0.3-0.2-0.4c-0.3-0.1-0.7,0.3-0.8,1.9l0,0.4c0,0.1,0,0.1,0,0.2
                                l0,0.5l0,0l0,0.5l0,0L29.2,43l0,0l-0.1-0.2l0,0L29,42.6c0,0,0-0.1,0-0.1l-0.1-0.3l0,0l-0.1-0.3c-0.1-0.4-0.2-0.8-0.2-1.3
                                c0-0.1,0-0.2,0-0.3l0-0.5c0-0.2,0-0.3-0.1-0.4c0,0-0.1,0-0.2,0.1l-0.1,0.1l0,0l-0.3,0.3c-0.3,0.3-0.7,0.7-1.2,0.9l0.1-0.2l0,0
                                l0.1-0.2l0,0l0.1-0.2c0.2-0.4,0.4-1,0.4-1.5c0-0.1,0-0.2-0.1-0.3l0-0.1c0,0,0,0,0,0L27.4,38l0,0l-0.2-0.2l0,0l-0.1-0.1
                                c-0.3-0.4-0.7-1.2-0.7-3.3c0-0.7,0.1-1.2,0.2-1.6l0.1-0.3c0.1-0.4,0.2-0.8,0.2-1.4c0-0.7-0.8-1.5-1.7-2.3l-0.4-0.3l0,0l-0.4-0.3l0,0
                                L24.2,28l0,0l-1.3-0.9l0,0l-0.4-0.3l0,0l-0.4-0.3c-0.1-0.1-0.1-0.1-0.2-0.2l-0.5-0.4l0,0l-0.3-0.3l0,0l-0.3-0.3l0,0L20.5,25l0,0
                                l-0.2-0.2l0,0l-0.1-0.1c0,0,0,0,0-0.1c-0.1-0.1,0.1-0.3,0.2-0.5l0.6-0.7l0,0l0.2-0.3c0.3-0.4,0.5-0.8,0.2-1
                                c-0.4-0.3-0.6-0.1-0.9,0.3l-0.1,0.2l0,0l-0.2,0.4c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.6-1.1,1.2-1.4,1.4c-0.1,0-0.1-0.1-0.1-0.3l0-0.8l0,0
                                l0-0.3c0-0.5-0.1-1-0.4-1c-0.7,0-0.8,0.7-0.7,1l0,0.1c0,0.1,0,0.1-0.2,0.1l-0.2,0c-0.3,0-0.6,0.1-0.9,0.4L16.1,24c0,0,0,0.1-0.1,0.1
                                l-0.1,0.2c-0.2,0.4-0.5,0.9-0.6,1.7l0-0.1l0,0l0-0.1c-0.1-0.3-0.2-1,0-1.6l0.1-0.2c0-0.1,0-0.1-0.2-0.1l-0.2,0c0,0-0.1,0-0.1,0
                                l-0.2,0l0,0l-0.3,0l0,0L14,23.9l0,0L13.6,24l0,0l-0.4,0.1l0,0l-0.7,0.1l0,0l-1,0.2c-0.1,0-0.2,0-0.3,0.1c-0.3,0.1-0.4,0.2-0.4,0.7
                                l0,0.4l0,0l0,0.4l0,0l0,0.4c0,0.4,0,0.6,0,0.9c0,0.1-0.1,0-0.1-0.1l0-0.1l0,0l0-0.1l0,0l0-0.1l0,0c0,0,0,0-0.1,0l-0.1,0l0,0l-0.2,0
                                c-0.4,0.1-1,0.1-1-0.5c0-0.5,0.1-0.6,0.3-0.7l0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0-0.6-0.4-0.9-0.9-0.9c-0.3,0-0.5,0.2-0.7,0.4
                                l-0.2,0.2c-0.3,0.3-0.6,0.6-1,0.6c-0.9,0-1-0.6-1-0.9s0.1-0.4,0.1-0.6c0-0.2-0.2-0.4-0.4-0.4c-0.1,0,0.1-0.1,0.4-0.1l0.1,0l0,0
                                l0.6,0.1c0.7,0.1,1.6,0,1.6-0.6c0-0.2-0.2-0.3-0.4-0.2l-0.3,0c-0.1,0-0.1,0-0.2,0l-0.4-0.1c-0.5-0.1-1.2-0.3-1.2-1.1
                                c0-0.4,0.4-0.6,0.6-0.7l0.1,0l0,0l0,0c0-0.1,0.1-0.3,0.1-0.5c0-0.1,0-0.1,0.2,0L7,20.3l0,0l0.2,0.1l0,0l0.3,0.2l0,0L7.9,21l0,0
                                l0.3,0.3l0,0l0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.2,0.3,0.5,0.2,0.6,0.1l0.1,0c0,0,0,0,0,0c0.1-0.1,0.3-0.4-0.2-0.7
                                c-0.6-0.3-0.6-0.6-0.6-1.1c0-0.4,0.3-0.5,0.5-0.6l0.1,0c0,0,0,0,0,0c0.1,0,0.2-0.4,0.1-0.6l0-0.1c-0.1-0.3,0.7,0.4,1.1,1.2
                                c0,0.1,0.1,0.2,0.1,0.3l0,0.5c0,0.5,0.1,0.9,0.7,1.2l0.2,0l0,0l0.1,0l0,0l0.1,0c0,0,0,0,0.1,0l0.1,0c0,0,0.1,0,0.1,0l0.2-0.1l0,0
                                l0.3-0.1c0,0,0.1,0,0.2-0.1l0.2-0.1l0,0l0.6-0.2l0,0l1.8-0.7l0,0l2.8-1c0.2-0.1,0.6-0.3,0.4-0.6c-0.2-0.4-0.9-0.5-1.9,0.2l-0.1,0.1
                                c-0.2,0.2-0.3,0.1-0.2-0.1l0.1-0.1l0,0l0.1-0.2c0,0,0-0.1,0.1-0.1l0.1-0.2c0,0,0-0.1,0.1-0.1l0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2
                                l0.2-0.2c0.7-0.9,1.8-1.8,2.8-1.8c0.4,0,0.5-0.2,0.5-0.4l0-0.1c0-0.2-0.2-0.4-0.9-0.4c-0.9,0-2.1,0.9-2.6,1.8c0,0-0.1,0-0.2,0
                                l-0.3-0.2c-0.2-0.2-0.6-0.3-1.1-0.3c-0.6,0-1,0.1-1.3,0.1l-0.2,0.1l0,0l-0.2,0c0,0-0.1,0-0.1,0c-0.2,0,0.5-1,1.7-1
                                c0.2,0,0.2-0.3-0.2-0.6l-0.1-0.1c-0.2-0.1-0.5-0.5-0.8-0.8l-0.3-0.4l0,0l-0.6-0.8c-0.1-0.1-0.1-0.1-0.2-0.2
                                c-0.2-0.2-0.4,0.1-0.7,0.6l-0.1,0.2l0,0l-0.1,0.3l0,0l-0.1,0.3l0,0l-0.1,0.3c0,0.1-0.1,0-0.1-0.1l0-0.1l0,0l0-0.1c0,0,0-0.1,0-0.1
                                c0-0.1-0.1-0.1-0.1-0.1l-0.2-0.1c-0.3-0.1-0.6-0.3-0.6-0.9c0-0.7,0.4-0.8,0.8-0.7l0.1,0l0,0l0.2,0.1c0,0,0,0,0.1,0
                                c0.4,0.1,0.8-0.4,0.7-0.8C12,11,11.6,10.5,11,10.5l-0.1,0c-0.7,0.1-1.7-0.4-1.7-1.2c0-0.4,0.2-0.6,0.4-0.7l0.2-0.1c0,0,0.1,0,0.1,0
                                C10,8.4,10,8,10,7.9c0,0,0,0,0.1,0L10.2,8l0,0l0.2,0.1l0,0l0.2,0.2l0,0l0.2,0.2c0,0,0.1,0.1,0.1,0.1l0.2,0.2
                                c0.1,0.1,0.1,0.1,0.2,0.2l0.3,0.3c0.3,0.3,0.4,0.4,0.6,0.3c0.2-0.1,0.3-0.6-0.4-1.2c-0.7-0.6-0.6-1.3-0.3-1.7c0,0,0.1,0,0.2,0
                                L12,6.7c0.3,0,0.6,0,0.7-0.2c0-0.2,0.1-0.1,0.1,0l0,0.1l0,0l0,0.2l0,0L12.9,7l0,0l0,0.4l0,0l0,0.4l0,0L13,8c0,0,0,0.1,0,0.1l0,0.2
                                C13,8.9,13.2,9,13.4,9c0.2,0,0.4-0.1,0.3-1c-0.1-0.8,0.1-1,0.3-1.1l0.1-0.1l0,0c0.2-0.1,0.5-0.1,0.7,0.1c0.2,0.2,0.6-0.1,0.6-0.4
                                l0,0.1l0,0l0,0.1l0,0l0,0.2c0.1,0.3,0.1,0.7-0.2,1.1c-0.1,0.1-0.1,0.2-0.2,0.2l-0.4,0.4c-0.4,0.4-0.7,0.9-0.3,1.7l0.1,0.2l4,4.1l0,0
                                c0.1,0,0.2-0.1,0-0.3c-0.2-0.3-0.2-0.6,0-0.8l0-0.1l0.1,0.1l0,0l0.2,0.1l0,0l0.2,0.1c0,0,0.1,0,0.1,0.1l0.2,0.1
                                c0.7,0.3,1.7,0.6,3.2,0.4c2.7-0.3,3.8-2.2,3.4-2.6L26,11.8l0,0L25.8,12c0,0-0.1,0.1-0.1,0.1l-0.2,0.2C25,12.6,24.4,13,23.8,13
                                c-1.1,0-1.7-1.1-1.5-1.8l0-0.1l0.1,0.1l0,0l0.1,0.1c0.2,0.2,0.7,0.5,1.3,0.5c0.9,0,1.9-1.1,1.7-1.7c0-0.1-0.1-0.1-0.3,0L25,10l0,0
                                l-1,0.3l0,0l-0.4,0.1c-0.1,0-0.1,0-0.2,0.1l-0.4,0.1c-0.7,0.2-1.7,0.3-2.7,0.3c-3.2,0-3.7-3.2-1.4-4.5l-0.1,0.1l0,0l-0.1,0.2l0,0
                                l0,0.1l0,0l0,0.2l0,0l0,0.2l0,0l0,0.2l0,0l0,0.2c-0.2,1.1,0.1,2.5,2.7,2.5c0.5,0,1,0,1.4-0.1l0.4-0.1c0.1,0,0.3,0,0.4-0.1l0.4-0.1
                                l0,0l0.3-0.1l0,0l0.3-0.1l0,0l0.2-0.1l0,0l0.2-0.1l0,0l0.3-0.1l0,0l0-0.1l0,0l0-0.1c-0.1-0.3-0.5-1-1.4-1c-0.9,0-1.3,0.7-1.5,1
                                l-0.1,0.1l0,0l0,0.1l0,0l-0.1-0.1l0,0L22,9.3c-0.3-0.2-0.8-0.8-0.6-1.5c0-0.1,0.1-0.1,0.2-0.1l0.2,0l0,0l0.2,0
                                c0.3-0.1,0.6-0.2,0.6-0.6c0-0.2-0.1-0.3-0.2-0.3l-0.1,0l0,0c-0.1,0-0.2,0.1-0.3,0.2l-0.1,0.2c-0.1,0.1-0.1,0.2-0.3,0.2
                                c-0.4,0-0.4-1.2,0.4-1.2l0.1,0c0.2,0,0.5,0,0.6-0.2l0-0.1c0-0.3,0.7-0.6,2.2-0.7l0.5,0c0.2,0,0.5,0,0.8,0c1.1,0,1.8,0.2,2.2,0.3
                                l0.2,0.1l0,0l0.1,0.1l0,0l0.1,0.1l0,0l0.1,0.1l0,0l0,0l0,0l0-0.1C29,5.4,29.3,4.9,30,4.9z M19.4,27.9l1.3,0l0,0l0.9,0l0,0l0.6,0l0,0
                                l0.4,0l0,0l0.3,0l0,0l0.2,0l0,0l0.1,0l0,0l0.1,0l0,0l0,0l0,0c0.1,0,0.1,0.1,0.1,0.2l0,0.1c0,0.1,0,0.3,0.3,0.4
                                c0.4,0.1,1.6,1.1,1.6,1.6c0,0.1-0.1,0.3-0.2,0.4L24.8,31c-0.1,0.2-0.2,0.4,0,0.8c0.5,0.7,0.9,0.7,1.2,0.7l0,0c0,0,0,0,0,0l-0.1,0
                                c0,0,0,0-0.1,0l-0.1,0.1c-0.3,0.1-0.9,0.2-1.8,0.2l-0.2,0c-0.4,0-0.8-0.1-1.2-0.2l-1.1-0.2c-0.6-0.1-1.1-0.2-1.7-0.1l-0.1,0l0,0
                                l-0.2,0c-1.1,0.2-1.8,1.2-1.3,4.4l0,0.3l0,0l0,0c0,0.1,0.2,0.2,0.6,0.2c0.6,0,2.1,0.7,3,2.2l-0.4-0.2l0,0L21.3,39l0,0L21,38.9l0,0
                                l-0.3-0.1c-0.8-0.4-1.6-0.7-1.9-0.2c-0.2,0.4-0.1,0.7,0.2,0.9c0.1,0-0.1,0.1-0.4,0.2l-0.3,0.1l0,0l-0.5,0.2l0,0L17.3,40l0,0l-1,0.3
                                l0,0l-1.8,0.5c-0.3,0.1-0.4,0.3,0.2,0.7l0.1,0.1l0,0l0.3,0.2l0,0l0.3,0.2l0,0l0.1,0.1c0.1,0.1,0.2,0.2,0.1,0.2l-0.3,0
                                c-0.3,0-0.6,0-0.6,0.2c0,0.2-0.1,0.7-0.6,0.7s-0.8-0.3-0.8-0.7l0-0.3l0,0l0-0.2c0-0.4-0.2-0.7-0.6-0.7c-0.6,0-1,0.6-1,1.7
                                c0,0.8-0.8,1-1.1,1S10,43.9,10,43.4c0-0.1-0.2-0.1-0.4-0.1l-0.2,0l0,0c-0.1,0,0-0.1,0.2-0.3l0.2-0.1l0,0l0.3-0.2l0,0l0.3-0.2l0,0
                                L11,42l0,0c0.2-0.1,0.2-0.5,0.1-0.6L11,41.3c-0.2-0.1-0.4-0.2-0.8,0.1c-0.4,0.4-0.9,0.7-1.3,0.7c-0.4,0-0.8-0.4-0.8-0.8l0-0.1l0,0
                                l0-0.4c0-0.2,0-0.5-0.3-0.6l-0.1-0.1c-0.1-0.1-0.1-0.1,0.1-0.1l0.2,0l0,0l0.7,0l0,0l0.8,0l0,0l0.3,0l0,0l0.3,0
                                c0.4,0,0.8-0.1,0.8-0.2c0-0.5-0.5-0.6-0.7-0.6l-0.3,0c-0.5,0-1.2,0-1.2-0.7c0-0.2,0.1-0.3,0.2-0.4L9,38.1C9,38,9.1,38,9.1,37.9
                                c0-0.2-0.3-0.2-0.4-0.2l0,0l0,0l0.1-0.1l0,0l0.1-0.1c0.3-0.1,0.9-0.3,1.5,0.2c0.1,0.1,0.3,0.3,0.4,0.4l0.2,0.3l0,0l0.2,0.3
                                c0.3,0.4,0.6,0.7,1.1,0.7l0.2,0c1-0.1,3.4-1,3.4-3.7c0-0.8-0.2-1.5-0.4-2.1l-0.1-0.3l0,0l-0.1-0.3l0,0l-0.3-0.6c0-0.1,0-0.1,0-0.1
                                c0-0.1,0-0.1,0.1-0.1l0.2,0c0.2,0,0.5-0.1,0.5-0.5c0-0.8-4.8-0.9-6.3,0.6l0-0.1l0,0l0-0.1l0,0l0-0.1c0,0,0-0.1,0-0.1l0.1-0.2
                                c0.3-0.5,1-1.2,2.6-1.2c0.4,0,0.7,0.1,0.9,0.1l0.3,0.1c0.3,0.1,0.5,0.1,0.8,0c0.1,0,0.1-0.1,0-0.2L13.8,30c-0.3-0.3-0.6-0.6-0.5-1.2
                                l0.1,0.1c0.3,0.3,1,0.9,1.8,0.9c0.1,0,0.1,0,0.2-0.1l0.1-0.1l0,0l0.2-0.3l0,0l0.2-0.2l0,0l0.2-0.2C16.7,28.5,17.7,27.9,19.4,27.9z
                                M24.3,21.9c-0.2,0-0.4,0.1-0.4,0.3l0,0.1l0,0l0,0.1l0,0l0,0.1c0,0,0,0.1-0.1,0.1l-0.1,0.2l0,0l-0.1,0.2c-0.3,0.4-0.7,0.9-1.6,1.3
                                c-0.1,0.1-0.2,0.3-0.1,0.5c0.1,0.1,0.2,0.2,0.4,0.2l0,0c0,0,0.1,0,0.1,0c1.3-0.6,1.9-1.4,2.1-2l0.1-0.2c0,0,0-0.1,0-0.1l0-0.1l0,0
                                l0-0.2l0,0l0-0.1l0,0C24.6,22.1,24.5,21.9,24.3,21.9z M27.1,21.7L27.1,21.7c-0.2,0-0.3,0.1-0.4,0.3l0,0.2l0,0l0,0.1l0,0l0,0.1
                                c-0.1,0.5-0.4,1.4-1.5,2c-0.1,0.1-0.2,0.3-0.1,0.5c0.1,0.1,0.2,0.2,0.4,0.2l0,0c0,0,0.1,0,0.1,0c1.2-0.6,1.7-1.6,1.9-2.3l0-0.2
                                c0,0,0-0.1,0-0.1l0-0.2l0,0l0-0.1l0,0l0-0.1c0,0,0,0,0,0C27.4,21.8,27.3,21.7,27.1,21.7z M22.6,17.1c-0.1,0.2,0,0.4,0.1,0.6l0.1,0.1
                                c0.1,0.1,0.4,0.4,0.5,0.8c0.2,0.7,0,1.7-0.6,2.7c-0.1,0.1-0.1,0.4,0.1,0.5c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.3-0.1,0.4-0.2
                                c0.7-1.2,1-2.3,0.8-3.2l0-0.2c-0.1-0.6-0.5-0.9-0.7-1.1l-0.1-0.1l0,0L23.1,17l0,0C22.9,16.9,22.6,16.9,22.6,17.1z M20.7,17.4
                                l-0.2,0.1l0,0l-0.1,0.1l0,0l-0.1,0.1l0,0l-0.2,0.2c-0.5,0.5-1,1.4-1.2,2.9c0,0.2,0.1,0.4,0.3,0.4l0.1,0h0.1c0.1,0,0.3-0.1,0.4-0.3
                                l0-0.1c0.1-1.4,0.7-2.2,1.1-2.6l0.1-0.1c0,0,0,0,0,0l0.1-0.1l0,0l0,0c0,0,0,0,0,0c0.2-0.1,0.2-0.4,0.1-0.5
                                C21.1,17.4,20.9,17.4,20.7,17.4z M25.4,14.8c-0.1,0.2-0.1,0.4,0.1,0.5l0.1,0.1l0,0l0.1,0.1l0,0l0.1,0.1c0,0,0,0,0,0.1l0.1,0.1
                                c0.3,0.5,0.6,1.3,0.3,2.8l-0.1,0.3c-0.1,0.2,0,0.3,0.2,0.4l0.1,0h0.1c0.1,0,0.3-0.1,0.4-0.3c0.5-2.2-0.2-3.4-0.6-3.9l-0.1-0.1l0,0
                                l-0.1-0.1l0,0L26,14.7l0,0l-0.1-0.1l0,0C25.7,14.6,25.5,14.6,25.4,14.8z M41.3,6.6L41.3,6.6L41.3,6.6L41.3,6.6l0.2,0.4l0,0l0.1,0.2
                                c0.3,0.6,0.9,1.3,1.5,1.2c0.2,0,0.2-0.3,0.1-0.6l-0.1-0.4l0,0l-0.1-0.2l0,0l0-0.2c0-0.1,0-0.1,0-0.2l0.2,0.2l0,0l0.2,0.2l0,0
                                l0.2,0.2c0.3,0.3,0.6,0.6,0.9,0.8c0.6,0.4,0.7,1.6,0.4,2.3C44.6,11,44.1,11.5,44,12l0,0.1c-0.1,0.3-0.3,0.7-0.6,1.3l-0.2,0.4
                                c-0.2,0.3-0.3,0.5-0.6,0.8L42.2,15c-0.3,0.4-0.7,0.8-1.1,1.2l-0.2,0.2l-1-0.9l0.3-0.3l0,0l0.3-0.3l0,0l0.3-0.3c0,0,0.1-0.1,0.1-0.1
                                l0.3-0.3l0,0l0.3-0.3c0.7-0.8,1.3-1.6,1.2-2.2l-0.1,0l0,0l-0.1-0.1c0,0,0,0-0.1,0l-0.2-0.1c-0.4-0.3-1-0.9-0.9-1.8l0.1,0.2
                                c0,0,0,0,0.1,0.1l0.1,0.2c0.3,0.4,0.8,0.8,1.1,0.5c0.5-0.3,0.1-1.1-0.5-1.7l-0.2-0.2l0,0L42,8.5c0,0-0.1-0.1-0.1-0.1
                                c-0.4-0.4-0.6-1-0.6-1.4l0-0.2l0,0L41.3,6.6L41.3,6.6z M27.1,9.9c-0.1,0.2,0,0.4,0.2,0.5c1.1,0.4,2.1,1,2.2,1.3l-0.1,0
                                c0,0,0,0-0.1,0l-0.1,0c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0l-0.3,0l0,0l-0.5,0c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1,0,0.2,0,0.4
                                l0.1,0.2l0,0l0.1,0.1l0,0l0,0.1c0,0,0,0,0,0.1l0,0.2c0,0,0,0.1,0,0.1l0,0.2c0,0,0,0.1,0,0.1l0,0.3c0,0.4,0,0.9-0.1,1.4
                                c0,0.2,0.1,0.3,0.4,0.4l0.1,0l0.1,0c0.1,0,0.3-0.2,0.3-0.4c0-0.2,0-0.4,0-0.5l0-0.5c0-0.1,0-0.1,0-0.2l0-0.4c0-0.5-0.1-0.8-0.2-1.1
                                c0.6,0,1.1-0.1,1.4-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0-0.7-1-1.3-1.8-1.7l-0.3-0.1l0,0l-0.3-0.1l0,0l-0.2-0.1c0,0-0.1,0-0.1,0
                                C27.4,9.6,27.2,9.7,27.1,9.9z M26.2,6l-1.8,0c-0.3,0-0.3,0.1-0.4,0.2l0,0l0,0c0,0.2,0.1,0.3,0.1,0.4l0.1,0.1h1l0.1,0
                                c0.1,0,0.3,0,0.3,0.1c0,0.1-0.3,0.2-0.6,0.3l-0.2,0l0,0l-0.3,0l0,0l0.1,0.1c0.1,0.2,0.3,0.3,0.7,0.3c0.6,0,0.8-0.5,0.8-0.9l0-0.1
                                l0,0l0-0.1l0,0l0,0c0.1,0,0.4,0,0.4-0.2C26.6,6.1,26.4,6,26.2,6L26.2,6z M25.8,0.5L25.8,0.5l0.3,0.1l0,0l0.2,0.2
                                c0,0,0.1,0.1,0.1,0.1L26.7,1c0.4,0.3,0.8,0.7,0.8,1.1c0,0.5-0.5,0.7-0.8,0.8l-0.1,0l0,0l0,0l0,0c0.2,0.5,0.6,0.7,0.8,0.8l0.1,0h0.8
                                c0.6,0,0.6-0.5,0.6-0.7l0-0.2c0,0,0,0,0,0l-0.1,0c-0.2,0-0.6-0.2-0.6-0.6c0-0.5,0.7-0.9,1.4-1.1L29.8,1l0,0L30,0.9l0,0l0.3-0.1
                                l-0.2,0.8l-0.1,0c-0.2,0-0.4,0.2-0.4,0.5c0,0.1,0.1,0.2,0.2,0.3l0.1,0l-0.6,2h-6.6l-0.5-1.9c0.1-0.1,0.3-0.2,0.3-0.4
                                c0-0.3-0.2-0.5-0.4-0.5l-0.1,0l-0.3-0.9c0.6,0.1,2.1,0.7,2.1,1.4c0,0.5-0.4,0.7-0.6,0.7l-0.1,0l0,0c0,0.6,0.4,0.8,0.6,0.8l0.1,0l0,0
                                l0.1,0h0.9c0.5,0,0.6-0.6,0.6-0.8l0-0.1l0,0l-0.1,0l0,0l-0.2,0c-0.3-0.1-0.6-0.2-0.6-0.5c0-0.5,0.4-0.9,0.8-1.3l0.2-0.2l0,0l0.2-0.2
                                l0,0L25.8,0.5L25.8,0.5z M26,1.7c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5S26.3,1.7,26,1.7z"/>
                            </svg>
                        </div>
                    </div>
                </nav>
            </div>

            {headerComponent}
        </header>
    )
}
